/* ---------- HERO with form ---------- */

section.hero {
    --text-bg: linear-gradient(to right, var(--site-bg-50) 0%, transparent 100%), radial-gradient(circle at 25% 50%, var(--site-bg-50) 15%, transparent 50%),
        radial-gradient(circle at 20% 60%, var(--site-bg-30) 15%, transparent 50%), radial-gradient(circle at 30% 70%, var(--site-bg-10) 15%, transparent 40%),
        radial-gradient(circle at 90% 0%, var(--yellow-30), transparent 50%), radial-gradient(circle at 10% 90%, var(--purple-30), transparent 30%),
        radial-gradient(circle at 100% 70%, var(--purple-50), transparent 30%);
    /*padding: 0;*/
    min-height: 90vh;
    /* background-image: var(--sbu-img); */
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    position: relative;
    overflow-y: hidden;
}

.hero > .section-content-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -moz-column-gap: var(--spc-xl-w);
    column-gap: var(--spc-xl-w);
    margin-bottom: 0;
    margin-top: calc(var(--header-ht) * 0.35);
}

.hero.no-form {
    --text-bg: var(--bg-dk-overlay);
}

.no-form > .section-content-wrapper {
    grid-template-columns: 3fr 2fr;
    max-width: 1600px;
}

.hero.no-form h1,
.hero.no-form h3 {
    color: white;
}

.no-glass {
    text-align: left;
    width: 100%;
    position: static;
    align-self: center;
    border: none;
    border-radius: none;
    padding: 0;
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
    background: transparent;
    box-shadow: none;
}

.no-glass > .panel-content {
    padding: 0;
}

#enter-location-manually {
    color: var(--accent);
    cursor: pointer;
    text-align: center;
    margin: 0;
}

.rebates-link {
    color: var(--accent);
    cursor: pointer;
}

.rebates-link::after {
    content: ' >';
}

.centered {
    transform: translateX(0%);
}

#down-arrow-wrapper {
    --bottom: var(--spc-sm-h);
    --opacity: 0.5;
    grid-area: unset;
    cursor: pointer;
    position: absolute;
    left: 50%;
    right: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    bottom: var(--bottom);
    -webkit-animation: 3s ease 0s infinite alternate bounce;
    animation: 3s ease 0s infinite alternate bounce;
    z-index: 9;
}

#down-arrow-wrapper img {
    padding-top: var(--xs4);
    grid-area: unset;
    width: 50px;
}

#down-arrow-wrapper .down-arrow-title {
    margin-bottom: var(--spc-xs-h);
    color: var(--accent-col);
}

.down-arrow-title::after {
    content: 'Scroll for more';
}

@-webkit-keyframes bounce {
    0% {
        bottom: var(--bottom);
        opacity: var(--opacity);
    }
    70% {
        bottom: var(--bottom);
        opacity: var(--opacity);
    }
    100% {
        bottom: -30px;
        opacity: 1;
    }
}

@keyframes bounce {
    0% {
        bottom: var(--bottom);
        opacity: var(--opacity);
    }
    70% {
        bottom: var(--bottom);
        opacity: var(--opacity);
    }
    100% {
        bottom: -30px;
        opacity: 1;
    }
}

.footnote-bar {
    width: 100%;
    background-color: var(--site-bg-50);
    backdrop-filter: var(--blur);
    -webkit-backdrop-filter: var(--blur);
    position: absolute;
    bottom: 0;
    left: 0;
    padding: var(--sm);
}

.footnote {
    font-size: calc(var(--sm) * 1.3);
    font-family: var(--condensed-font);
    line-height: var(--std);
    margin: auto;
    text-align: center;
}

.footnote-bar .footnote {
    max-width: 1000px;
    /* text-align: left; */
}

/* ------------ PRODUCT HERO ------------ */

.product section.hero-product {
    display: grid;
    gap: var(--grid-gap);
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'left right';
    padding-top: var(--lg);
}

.hero-product > div {
    display: grid;
    align-content: center;
}

.hero-product .left-panel {
    grid-area: left;
    align-self: center;
}

.hero-product .right-panel {
    grid-area: right;
}

.hero-product .right-panel a {
    margin: 0;
}

#hero-product-headline {
    display: flex;
    align-self: flex-start;
    color: var(--text-col);
    margin: 0;
}

.hero-product > .left-panel h1 {
    margin-bottom: 0;
    text-align: left;
}

.hero-product > .left-panel h2 {
    margin-bottom: 0;
    text-align: left;
}

.hero-product img {
    width: 100%;
    margin: auto;
}

.hero-product p {
    width: 95%;
    margin: auto;
    margin-top: 20px;
}

.hero-product p.full-width {
    margin: var(--std) 0;
}

.hero-product a.btn {
    margin-left: 0;
    margin-top: 20px;
    text-decoration: none;
}

/* ------------ CTA HERO ------------ */

.bu-section.cta-hero {
    padding: 0;
    min-height: 100vh;
    background-image: var(--sbu-img);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    position: relative;
}

.cta-hero > .section-content-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 0;
    margin-top: 0;
    max-width: 1400px;
}

.shard-panel {
    position: relative;
}

.panel-content {
    width: clamp(200px, 80vw, 600px);
    padding: 0 var(--xl2);
    margin: auto;
    z-index: 1;
    position: relative;
}

.panel-content > h2.h3 {
    /* color: var(--accent); */
    margin-bottom: var(--spc-lg-h);
}

.shard-panel .shard {
    width: 100%;
    height: 100vh;
    top: 0;
    position: absolute;
    border: none;
    border-bottom: 4px solid var(--white-30);
    border-radius: 0;
    box-shadow: none;
    transform: translateY(-40vh) skewY(-10deg);
    z-index: 0;
}

/* ------------ HERO SECTION WITH NO HEADER (NAV BELOW) ------------ */

.nav-below {
    height: 100vh;
    padding: 0;
}

.nav-below .section-content-wrapper {
    gap: calc(var(--grid-gap) * 2);
}

.nav-below h1,
.nav-below h2,
.nav-below h3,
.nav-below img,
.nav-below a {
    grid-area: unset;
    background-repeat: no-repeat;
    background-size: contain;
}

.nav-below h1,
.nav-below h2,
.nav-below h3 {
    color: var(--white-50);
    margin: 0;
    line-height: 1.1em;
    text-align: center;
}

.nav-below h1 {
    font-size: clamp(40px, 7vw, 100px);
}
.nav-below h2 {
    font-size: clamp(30.1px, 5.26vw, 75.3px);
}
.nav-below h3 {
    font-size: clamp(15.2px, 2.66vw, 38px);
    padding-top: 10px;
    font-family: var(--headline-font);
}

nav.nav-bar {
    --height: 70px;
    height: var(--height);
    color: white;
    display: flex;
    place-content: center;
    justify-content: space-evenly;
    width: 100%;
    background-color: var(--black-60);
    backdrop-filter: var(--blur);
    -webkit-backdrop-filter: var(--blur);
    margin-top: 100px;
    position: -webkit-sticky;
    position: sticky;
    top: var(--header-mini-ht);
}

nav.below {
    margin-top: 0;
    position: sticky;
    position: -webkit-sticky;
    top: var(--height);
    transform: translateY(calc(-1 * var(--height)));
}

nav.nav-bar a {
    font-family: var(--headline-font);
    text-transform: uppercase;
    text-align: center;
    padding: var(--base);
    margin: var(--sm) 0;
    /* width: 20vw; */
    color: white;
    cursor: pointer;
    background-color: transparent;
    transition: var(--fade);
    border-radius: var(--corner-radius-sm);
}

nav.nav-bar a:hover {
    color: var(--accent);
    background-color: var(--black-80);
}

@media screen and (max-width: 1200px) {
    section.hero {
        --text-bg: radial-gradient(circle at 50% 30%, var(--site-bg-80) 25%, transparent 50%);
        /* padding-bottom: var(--spc-lg-h); */
    }

    .hero > .section-content-wrapper {
        grid-template-columns: 1fr;
        margin-left: 0;
        margin-right: 0;
        padding: 0;
        width: unset;
    }

    .hero > .section-content-wrapper h1 {
        padding-top: var(--spc-lg-h);
    }

    .hero .no-glass {
        /* width: 70vw; */
        text-align: center;
    }

    .section-content-wrapper {
        padding: 0 var(--lg);
    }

    .two-column-img-left.equal-columns > .section-content-wrapper,
    .two-column-img-right.equal-columns > .section-content-wrapper,
    .two-column > .section-content-wrapper {
        grid-template-columns: 1fr;
    }
    .hero.two-column-img-right {
        padding-top: 0;
    }
    #down-arrow-wrapper {
        -webkit-animation: unset;
        animation: unset;
        position: unset;
        margin: auto;
        transform: unset;
    }
    #down-arrow-wrapper .down-arrow-title {
        margin-bottom: var(--spc-xs-h);
    }

    .down-arrow-title::after {
        content: 'Learn more';
    }
}

@media screen and (max-width: 800px) {
    #hero-panel {
        width: 95vw;
        text-align: left;
        top: 40%;
        left: 50%;
        right: auto;
        bottom: auto;
        margin-right: -50%;
        max-width: 60%;
        transform: translate(-50%, -50%);
        padding: var(--spc-lg);
        border: none;
    }
}

@media screen and (max-width: 400px) {
    #hero > .section-content-wrapper {
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
    }
}
