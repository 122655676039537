.section {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: var(--spc-xl3-h) 0;
    width: 100%;
    min-height: 100px;
    place-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
}

.section h2 {
    grid-area: title;
}

.section div.details {
    grid-area: details;
}

.section a {
    grid-area: cta;
}

.section p {
    max-width: 900px;
}

.section > img {
    max-height: 700px;
    max-width: 100%;
}

span > img {
    grid-area: unset;
}

.section-content-wrapper {
    display: grid;
    gap: var(--spc-xl-w);
    padding: 0 clamp(var(--sm), 5vw, var(--xl6));
    width: 100%;
    max-width: var(--content-width);
    margin: auto;
    place-self: center;
    place-content: center;
    place-items: center;
}

.section-content-wrapper > img {
    border-radius: var(--corner-radius-lg);
    display: flex;
    place-items: center;
    grid-area: img;
    max-height: 800px;
}

.details h2,
.details h3 {
    text-align: center;
}

.details > img {
    margin: var(--spc-lg-h) auto var(--spc-sm-h) auto;
    max-width: 400px;
}

.details .section-btn-wrapper {
    display: flex;
    place-content: flex-start;
    margin: var(--spc-xl-h) 0;
}

.glass-panel {
    grid-template-columns: 1fr;
    background-color: var(--site-bg-70);
    -webkit-backdrop-filter: var(--blur);
    backdrop-filter: var(--blur);
    border-radius: var(--corner-radius-md);
    width: 100%;
    /* min-width: 300px; */
    min-height: 100px;
    box-shadow: var(--shadow-far);
    padding: var(--spc-md);
    place-self: center;
}

.glass-panel > h1 {
    color: var(--text-col);
    margin: 0;
}

.glass-panel > h2 {
    margin: var(--spc-xs-h) 0;
}

.bg-accent {
    background: var(--bg-gradient-accent);
}

/* ---------- FORM SECTION ---------- */
.form-section {
    place-content: center;
    background: var(--bg-gradient-gray);
}

.form-section .glass-panel {
    background-color: transparent;
    box-shadow: none;
}

/* ---------- MAP SECTION ---------- */

.map > .section-content-wrapper,
.heatmap > .section-content-wrapper {
    display: block;
    width: 100%;
}
.map button {
    box-shadow: none;
    border-radius: 0;
}

.map button:hover {
    box-shadow: none;
}

/* ---------- 1-COLUMN INFO SECTION ---------- */

.one-column-info > .section-content-wrapper {
    display: block;
    min-width: 100%;
    max-width: 100%;
}

/* ---------- 1-COLUMN IMAGE SECTION ---------- */

.one-column-image > .section-content-wrapper {
    max-width: unset;
    display: block;
}

.one-column-image * {
    margin-left: auto;
    margin-right: auto;
}

.one-column-image img {
    border-radius: var(--corner-radius-lg);
    padding: 0;
    margin: var(--spc-lg-h) auto;
    max-width: 1500px;
    width: 100%;
}

/* ---------- 1-COLUMN + CTA SECTION ---------- */

.one-column-cta > .section-content-wrapper {
    grid-template-rows: repeat(5, auto) 1px;
    grid-template-areas: 'details' 'details' 'img' 'img' 'img' 'cta';
    place-items: center;
}

.one-column-cta img {
    height: auto;
    max-height: 300px;
    padding: 0;
    width: 100%;
}

.one-column-cta button {
    position: relative;
    bottom: 200px;
}

.one-column-cta .details {
    padding: 0 var(--md);
}

/* ---------- 1-COLUMN LIST ---------- */
.one-column-list > .section-content-wrapper {
    grid-template-columns: 1fr;
    grid-template-areas: unset;
    max-width: var(--content-width);
}

.one-column-list > .section-content-wrapper .details {
    grid-area: unset;
}

.list-wrapper {
    display: grid;
    gap: calc(var(--grid-gap) * 2);
}

.one-column-list .list-item {
    display: grid;
    grid-template-columns: minmax(50px, 0.8fr) 10fr;
    gap: var(--grid-gap);
    width: 100%;
    align-content: start;
    /*height: 160px;*/
}

.list-item img.icon {
    grid-area: unset;
    display: grid;
    align-self: start;
    margin: unset;
    height: unset;
}

.list-item h3 {
    margin-top: 0;
    margin-bottom: var(--spc-xs-w);
}

.list-item h4 {
    margin: 0;
}

.no-padding-top {
    padding-top: 0;
}

.no-padding-bottom {
    padding-bottom: 0;
}

.img-zoom {
    transform: scale(1);
    transition: var(--fade-slow);
}

.img-zoom:hover {
    transform: scale(1.05);
}

/* ---------- 2-COLUMN ---------- */

.two-column > .section-content-wrapper {
    grid-template-columns: repeat(2, 1fr);
}

.two-column-img-left .details h3,
.two-column-img-right .details h3 {
    color: var(--accent);
}

/* ---------- 2-COLUMN IMG LEFT ---------- */

.two-column-img-left > .section-content-wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'img details';
    justify-items: start;
}

/* ---------- 2-COLUMN IMG RIGHT ---------- */

.two-column-img-right > .section-content-wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'details img';
    place-items: center;
}

.two-column-img-right.smaller-img > .section-content-wrapper {
    grid-template-columns: 4fr 3fr;
}

.two-column-img-left .details h2,
.two-column-img-left .details h3,
.two-column-img-right .details h2,
.two-column-img-right .details h3 {
    text-align: left;
}

.two-column-img-left.equal-columns > .section-content-wrapper,
.two-column-img-right.equal-columns > .section-content-wrapper {
    grid-template-columns: 1fr 1fr;
}

.two-column-img-left.lg-img > .section-content-wrapper {
    grid-template-columns: 4fr 3fr;
}

.two-column-img-right.lg-img > .section-content-wrapper {
    grid-template-columns: 3fr 4fr;
}

/* ---------- 3-COLUMN + INFO BOXES ---------- */

.three-column-cards-w-list > .section-content-wrapper,
.three-column-cards-w-image > .section-content-wrapper {
    grid-template-rows: auto 4fr;
    grid-template-areas: 'details details details' 'boxes boxes boxes';
    padding: 0 var(--lg);
}

.three-column-cards-w-list > h1,
.three-column-cards-w-image > h1 {
    font-size: 2rem;
    text-transform: none;
    place-self: center;
}

.three-column-cards-w-list h3,
.three-column-cards-w-image h3 {
    text-align: center;
}

.content-box-container {
    grid-area: boxes;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--xl2);
    place-items: center;
}

.content-box-container > .glass-panel {
    height: 100%;
    width: 100%;
    background-color: var(--site-bg-50);
}

.content-box-container .icon {
    max-height: 100px;
}

.content-box-container .img {
    /*object-position: top;*/
    -o-object-fit: cover;
    object-fit: cover;
    height: clamp(150px, 20vh, 225px);
    border-radius: var(--corner-radius-sm);
}

.content-box-container h3 {
    text-align: center;
    padding-top: var(--spc-sm-h);
    /* line-height: calc(var(--xl2)*1.2); */
}

.content-box-container h4 {
    text-align: center;
    color: var(--text-col);
}

.content-box-container p {
    text-align: center;
    margin-bottom: 0;
}

.content-box-container button {
    width: 100%;
}

.simple-link {
    font-family: var(--main-font-bold);
    /* font-size: large; */
    color: var(--accent);
    display: grid;
    place-content: center;
    cursor: pointer;
    background-color: transparent;
    box-shadow: unset;
    padding: 0;
}

.simple-link.icon-link {
    gap: var(--gap);
}

.icon-link {
    --size: clamp(25px, 5vw, 35px);
    --gap: clamp(calc(var(--size) / 6), 0.75vw, calc(var(--size) / 3));
    display: inline-grid;
    grid-template-columns: var(--size) auto;
    height: var(--size);
    -moz-column-gap: var(--gap);
    column-gap: var(--gap);
    color: var(--text-col);
    align-items: center;
}

.icon-link.icon-after {
    grid-template-columns: auto var(--size);
}

.icon-link.accent {
    color: var(--accent);
}

.icon-link.underline span {
    text-decoration: underline;
}

.icon-link.tiny {
    --size: clamp(14px, 5vw, 16px);
}

.content-box-container .simple-link {
    padding-top: var(--spc-sm-h);
}

button.simple-link:hover {
    background-color: transparent;
    box-shadow: unset;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    width: 70%;
    max-width: 800px;
    max-height: 98vh;
    margin-right: -50%;
    padding: var(--spc-lg);
    transform: translate(-50%, -50%);
    transition: var(--fade);
    background-color: var(--site-bg-70);
    border: 2px solid var(--neu-btn);
    overflow: auto;
    outline: none;
}

.ReactModal__Overlay {
    z-index: 999;
}

.table thead {
    text-transform: capitalize;
}

th,
td {
    transition: var(--fade);
}

.modal.table {
    width: 95vw;
    max-height: 95vh;
    max-width: unset;
    overflow: auto;
}

.modal.table section {
    overflow: auto;
}

.modal .results-table {
    font-size: 0.8em;
}

.modal .results-table thead th {
    cursor: pointer;
}

.modal .results-table thead th:hover {
    background-color: var(--bg);
}

.modal .results-table thead th.selected:hover {
    background-color: var(--text-col);
}

.modal .results-table .selected {
    background-color: var(--text-col);
    color: var(--site-bg);
}

.modal-title {
    margin-top: var(--spc-lg-h);
    text-align: center;
}

.modal-details {
    margin: var(--spc-md-h) 0;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: var(--grid-gap);
    place-items: center;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--site-bg-50);
}

.icon-list-wrapper {
    display: grid;
    gap: var(--grid-gap-xs);
    /* background-color: var(--site-bg); */
    padding: var(--lg) 0;
}

.icon-list-wrapper h5 {
    margin: 0;
    margin-bottom: var(--spc-xs-h);
}

.icon-list-item {
    display: grid;
    grid-template-columns: var(--spc-lg-w) auto;
    align-items: center;
    gap: var(--grid-gap-xs);
}

.icon-list-item .icon {
    grid-area: unset;
}

.icon-list-item p {
    text-align: unset;
    margin: 0;
    font-family: var(--condensed-font);
}

h4.system-estimate {
    /* font-size: clamp(var(--lg), 4vw, var(--xl)); */
    /* color: var(--text-col); */
    margin: var(--spc-lg);
}

.brands .glass-panel {
    display: flex;
    padding: 0 var(--xl3);
    box-shadow: none;
    background-color: var(--white);
    width: 100%;
    height: 380px;
    place-items: center;
    place-content: center;
}

img.brand {
    align-self: center;
    border-radius: 0;
    -o-object-fit: contain;
    object-fit: contain;
    max-height: 220px;
    max-width: 300px;
}

/* ------------ 4-COLUMN SECTION ------------ */

.four-column {
    display: grid;
    grid-template-rows: auto auto;
    align-content: center;
    padding-bottom: var(--spc-xl3-h);
    margin: auto;
    place-self: center;
    place-content: center;
    place-items: center;
}

.four-column > .section-content-wrapper {
    grid-template-columns: unset;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
        'title'
        'columns'
        'cta';
    align-content: space-around;
    justify-items: center;
    min-width: 100%;
    max-width: 100%;
}

.four-column > .section-content-wrapper > h2 {
    grid-area: title;
    text-align: center;
}

.four-column-wrapper {
    display: grid;
    grid-area: columns;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--grid-gap);
    justify-content: center;
}

.column-wrapper > img {
    height: 20vh;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: var(--corner-radius-lg);
}

.column-wrapper > .image-background {
    padding: 3vh 2vw;
    -o-object-fit: contain;
    object-fit: contain;
    background: var(--bg-gradient-blue-dp);
}

.column-text > h3,
.column-text > p {
    text-align: center;
}

/* ---------- CONTACT CARD ---------- */

.wire-img a {
    margin: auto;
}

.page {
    max-width: 2000px;
    margin: var(--spc-xl-h) auto;
    padding: 0 var(--xl2);
}

.page > h2 {
    margin: var(--spc-md-h) auto;
    max-width: 70vw;
}

.cards {
    margin: auto;
    max-width: 1000px;
}

.card {
    display: inline-block;
    border: 1px solid var(--grey);
    border-radius: var(--corner-radius-lg);
    padding: var(--spc-sm);
    height: 380px;
    min-width: 300px;
    margin: 12px;
    overflow: hidden;
    box-shadow: var(--shadow);
    transition: var(--fade);
}

.contact {
    text-align: center;
}

.contact img {
    display: block;
    margin: 0 auto 10px;
    border-radius: 100%;
    border: 10px solid white;
    width: 180px;
    -o-object-fit: cover;
    object-fit: cover;
    height: 180px;
    transition: var(--fade);
}

.bio {
    font-style: italic;
}

/* ---------- DOWNLOAD / SUBSCRIBE SECTIONS ---------- */

.download-section {
    min-height: 400px;
    padding-top: var(--xl2);
    padding-bottom: var(--xl2);
    margin-bottom: 0;
}

.download-section > .section-content-wrapper {
    max-width: 900px;
    grid-template-columns: auto auto;
    grid-template-rows: repeat(2, auto);
    grid-template-areas: 'details a' 'details a';
}

.subscribe > .section-content-wrapper {
    max-width: 1100px;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: repeat(2, auto);
    grid-template-areas: 'details a' 'details a';
}

.download-section h3 {
    text-align: center;
}

.download-section > .section-content-wrapper a {
    grid-area: a;
}

.download-section img {
    max-height: 300px;
    width: auto;
    box-shadow: var(--shadow-full);
    transition: var(--fade);
}

.download-section img:hover {
    opacity: 0.5;
    box-shadow: var(--shadow-close);
}

.smaller-text {
    font-size: smaller;
}

.download-section #subscriber-form {
    display: grid;
    grid-area: a;
    justify-content: center;
}

/* ---------- ROLLER ---------- */
.roller-iframe-wrapper {
    border-radius: var(--rnd-md);
}

.roller {
    --roller-menu-ht: 100px;
    position: relative;
    color: var(--white);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    transition: var(--fade);
}

.roller h1,
.roller h2,
.roller h3,
.roller h4,
.roller h5,
.roller p {
    color: var(--white);
    text-shadow: var(--shadow-tight), var(--shadow-close), var(--shadow-md), var(--shadow-far);
}

.roller > .section-content-wrapper {
    position: relative;
    width: 200%;
    /* min-height: calc(100vh - var(--mobile-nav-ht)); */
    grid-template-columns: 1fr 2fr;
    gap: 0;
    align-content: center;
    justify-items: start;
    max-width: unset;
    padding-top: var(--spc-xl-h);
    padding-bottom: var(--spc-xl-h);
    padding-left: var(--spc-xl-w);
    margin: auto;
    background: rgb(37, 46, 64, 0.6);
    background: linear-gradient(107deg, transparent 40%, var(--blue-dp) 40%, var(--black-80) 70%),
        radial-gradient(circle at 0% 50%, var(--black-50) 20%, transparent 40%);
    transition: var(--fade-slow);
}

.roller-title-wrapper,
.roller-details-wrapper {
    min-height: 500px;
    display: grid;
}

.roller-title-wrapper {
    opacity: 1;
    position: relative;
    transition: var(--fade-slow);
    align-content: center;
}

.roller-title-wrapper h2 {
    margin: 0;
}
.roller-title-wrapper h1,
.roller-title-wrapper h2 {
    max-width: 50vw;
}

.roller-btn {
    position: absolute;
    display: grid;
    grid-template-columns: auto 40px;
    right: 30px;
    bottom: 120px;
    cursor: pointer;
    transition: var(--fade-slow);
}

.roller-btn > img {
    transition: var(--fade-slow);
    transform: rotate(-90deg);
}

.roller-btn.close {
    gap: 10px;
}
.roller-btn.close > .icon#x {
    height: unset;
    width: unset;
    position: unset;
}

.roller-btn:hover {
    transform: translateX(10px);
}
.roller-btn.close:hover {
    transform: translateX(0px);
}
.roller-btn.close > .icon#x:hover {
    transform: rotate(-90deg);
}

.roller > .section-content-wrapper.details-visible .roller-title-wrapper {
    opacity: 0;
}
.roller > .section-content-wrapper.details-visible .roller-menu {
    opacity: 0;
}
.roller > .section-content-wrapper.details-visible .roller-details-wrapper {
    opacity: 1;
}
.roller > .section-content-wrapper.details-visible {
    transform: translateX(-32%);
}

.roller-details-wrapper {
    width: 90vw;
    display: grid;
    align-items: start;
    position: relative;
    gap: calc(var(--grid-gap) * 1);
    grid-template-columns: minmax(20vw, 350px) 2fr 3fr;
    grid-template-rows: auto;
    grid-template-areas:
        'roller-img headline headline'
        'roller-img text list'
        'roller-img text list'
        'roller-img text list'
        'roller-img roller-cta list';
    opacity: 0;
    transition: var(--fade-slow);
}

.roller-details-headline {
    grid-area: headline;
}
.roller-details-headline h3.h5,
.roller-details-headline h4 {
    margin: 0;
}

.roller-details-img {
    grid-area: roller-img;
    width: auto;
}

.roller-details-list {
    grid-area: list;
    display: grid;
    gap: var(--grid-gap-sm);
    max-width: 600px;
}
.roller-details-list .icon-list-item {
    align-items: center;
}

.roller-details-list .icon-list-item > p,
.roller-details-list .icon-list-item > .icon {
    padding: 0;
    margin: 0;
}

.roller-details-text {
    grid-area: text;
    margin: 0;
}

.roller-cta-wrapper {
    grid-area: roller-cta;
}
.roller-cta-wrapper a.btn {
    border-color: var(--white);
    color: var(--white);
    /* background-color: unset; */
    box-shadow: var(--shadow-full);
}

.roller-cta-wrapper a.btn:hover {
    border-color: unset;
    color: unset;
    background-color: var(--white-80);
}

.roller-details-wrapper .roller-btn {
    grid-template-columns: 50px auto;
    left: 50px;
    right: unset;
    bottom: -80px;
    width: auto;
}

.roller-details-wrapper .roller-btn:hover {
    transform: translateX(-10px);
}

.roller-details-wrapper .roller-btn > img {
    transform: rotate(90deg);
}

.roller-menu {
    display: grid;
    gap: 5vw;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: 1fr;
    place-content: center;
    place-items: center;
    height: var(--roller-menu-ht);
    background-color: var(--black-80);
    backdrop-filter: var(--blur);
    padding: 0 var(--lg);
    -webkit-backdrop-filter: var(--blur);
    width: 100%;
    transition: var(--fade-slow);
}

.roller-menu-title {
    transition: var(--fade);
    cursor: pointer;
    margin: auto;
    text-align: center;
    color: var(--white);
    font-family: var(--headline-font);
}

.roller-menu-title:hover {
    padding-bottom: 10px;
}

.selected-item {
    padding-bottom: 10px;
    border-bottom: 4px solid white;
}

.hero.two-column-img-right {
    padding-top: calc(var(--header-ht) * 1.25);
}

/* ---------- VIDEO SECTION ---------- */

.video-section {
    position: relative;
    width: 100%;
    height: 55vw;
    max-width: var(--content-width);
    max-height: 700px;
    grid-column: span 2;
    margin-top: 100px;
    padding-top: var(--spc-lg-h);
}

.full-width-video {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: var(--corner-radius-lg);
}

.background-video,
.background-video-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#youtube-video {
    border-radius: var(--corner-radius-lg);
}

.youtube-video {
    width: 100%;
    height: 100%;
    border: 0;
}

.video-section .icon#x {
    top: -10px;
    right: 25px;
    z-index: 100;
}

.video-bg-section {
    position: relative;
    padding: 0;
}

.video-bg {
    width: 100%;
    height: auto;
    z-index: 2;
}

.video-overlay-wrapper {
    position: absolute;
    padding: var(--spc-lg);
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    display: grid;
    grid-template-columns: 1fr 18vw 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: '. . right' 'left . .';
    place-content: center;
    place-items: center;
}

#video-overlay-wrapper .glass-panel {
    gap: var(--grid-gap);
    padding: var(--spc-sm-h) var(--spc-lg-w);
    color: var(--accent);
    max-width: 500px;
}

#video-overlay-wrapper .glass-panel h3 {
    color: var(--accent);
}

/* ------------ THANK YOU CARD ------------ */

.thank-you {
    padding: 50px 15vw;
    height: auto;
}

.thank-you > h1 {
    text-align: center;
}

.thank-you > h4 {
    text-align: left;
}

/* ---------- NEW PRODUCT CARD ---------- */

.new-products-section {
    padding-top: var(--spc-xl-h);
    /* margin-bottom: var(--spc-xl-h); */
}

.new-products-section > h2 {
    text-align: center;
    margin-bottom: 0;
}

.product-nav-bar {
    display: flex;
    place-content: center;
    align-content: space-around;
    margin-bottom: var(--spc-xl-h);
}

.product-nav-link {
    display: inline;
    font-family: var(--headline-font);
    text-align: center;
    padding: var(--base);
    margin: var(--spc-xs-h) 0;
    width: clamp(50px, 15vw, 250px);
    color: var(--accent);
    cursor: pointer;
}
.new-product-card-wrapper {
    --deg: 110deg;
    --start: 70%;
    --start2: calc(var(--start) * 0.99);
    margin: var(--spc-xl2-h) auto;
    margin-top: var(--spc-lg-h);
    padding: var(--xl3) clamp(var(--sm), 5vw, var(--xl3));
    border-radius: var(--corner-radius-lg);
    min-height: 500px;
    width: clamp(300px, 90vw, 1600px);
    background: linear-gradient(var(--deg), transparent var(--start), var(--blue-dp) var(--start), var(--blue-dp-40) 100%),
        linear-gradient(calc(var(--deg) * 1), transparent var(--start2), var(--purple-50) var(--start2), var(--purple) calc(var(--start) * 1.5)),
        linear-gradient(calc(var(--deg) * 1), transparent var(--start2), var(--blue-dp) var(--start2));
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: var(--start) var(--start);
    /* --time: 60s;
	--animation: shard-motion;
	-webkit-animation: var(--animation) var(--time) ease-in-out infinite;
	-moz-animation: var(--animation) var(--time) ease-in-out infinite;
	-o-animation: var(--animation) var(--time) ease-in-out infinite;
	animation: var(--animation) var(--time) ease-in-out infinite; */
    box-shadow: var(--shadow-far);
    transition: background-size 5s;
}

.new-product-card-wrapper:hover {
    background-size: 100% 2000%;
}

@-webkit-keyframes shard-motion {
    0% {
        background-position: 35% 35%;
    }
    50% {
        background-position: 65% 65%;
    }
    100% {
        background-position: 35% 35%;
    }
}
@keyframes shard-motion {
    0% {
        background-position: 60%;
    }
    50% {
        background-position: 100%;
    }
    100% {
        background-position: 60%;
    }
}

.card-content {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: var(--grid-gap);
}

.card-content > div > p {
    margin-bottom: var(--spc-xl-h);
}

.card-content > div > h2 {
    /* text-transform: uppercase; */
    font-family: var(--headline-font);
    color: var(--accent);
    margin-bottom: 0;
}

.card-content > div > h3 {
    text-transform: uppercase;
    font-size: var(--xl);
    font-family: var(--headline-font);
    margin: 0;
}

.card-content > div > .model {
    color: var(--text-col-50);
    font-size: calc(var(--xl) * 0.8);
    font-family: var(--headline-font);
    margin-top: 0;
}

.card-content img {
    margin: var(--xl) 0 var(--lg);
}

.images-column {
    display: grid;
    gap: var(--grid-gap);
    grid-template-rows: auto auto;
    place-items: center;
}

.images-column > img {
    border-radius: var(--corner-radius-sm);
    max-height: 500px;
}

.mobile-only {
    display: none;
}

.feature-item {
    display: grid;
    grid-template-columns: 80px 1fr;
    gap: var(--grid-gap-sm);
    align-content: flex-start;
    margin: var(--base) 0;
}

.feature-item > img.icon {
    display: flex;
    align-self: flex-start;
    height: unset;
    margin: 0;
}

.feature-item .h5 {
    margin: 0;
    font-family: var(--main-font-bold);
}

.feature-item p {
    margin-top: var(--spc-xs-h);
}

/* ---------- TABS AND iFRAME STYLES ---------- */

.iframe {
    max-height: 1400px;
}
.tabs-indoor-solutions .section-content-wrapper {
    display: block;
}

.tabs-indoor-solutions h2 {
    text-align: center;
}

.iframe-instructions-hero {
    padding: var(--spc-xl4-h) 0 var(--spc-xl-h) 0;
}

.iframe-preview-wrapper {
    border: 4px dotted var(--gray);
    border-radius: var(--corner-radius-md);
    max-width: var(--content-width);
    margin: 0 auto;
    min-height: 300px;
    padding: var(--spc-md);
    padding-bottom: 0;
}

.iframe-preview-wrapper > h5 {
    text-align: center;
}

.iframe-code-placeholder {
    display: flex;
    border-radius: var(--rnd-md);
    place-content: center;
    height: 300px;
    width: 100%;
    margin: 0 auto;
    background-color: var(--black-80);
}

.iframe-code-placeholder h5 {
    color: var(--white);
}

.tabs {
    position: relative;
    width: clamp(300px, 100%, var(--content-width));
    border-radius: var(--corner-radius-lg);
    /* box-shadow: var(--shadow-far); */
    margin: var(--spc-xl-h) auto;
    grid-area: img;
}

.tabs > nav {
    display: flex;
    height: var(--xl4);
    margin: auto;
    padding: var(--spc-xs);
    background-color: var(--blue-dp);
    place-content: center;
    gap: var(--spc-xs-w);
}

.btn-tab {
    font-size: clamp(10px, 3vw, var(--md));
    color: var(--white);
    height: 100%;
    margin: 0;
    width: clamp(200px, 20vw, 300px);
    background-color: unset;
    padding: unset;
    box-shadow: unset;
    border-radius: var(--corner-radius-sm);
}

.btn-tab.selected,
.btn-tab:hover {
    color: var(--accent);
    background-color: var(--black-80);
    font-family: var(--headline-font-bold);
    box-shadow: unset;
}

.tab-content-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: clamp(var(--grid-gap-sm), 5vw, var(--grid-gap-lg));
    min-height: 600px;
    max-height: 1000px;
    padding: var(--spc-md-h) var(--spc-xl-w);
    background: var(--bg-gradient-gray);
    white-space: pre-wrap;
}

.tab-content-wrapper.products {
    grid-template-columns: clamp(100px, 15vw, 220px) 1fr;
    padding: 0;
    gap: 0;
    align-items: flex-start;
    /* height: 750px; */
    overflow-y: auto;
}

.tab-content-wrapper.products nav {
    height: 100%;
    background-color: var(--blue-dp);
}

.product-btn-wrapper {
    display: grid;
    place-items: center;
    width: 100%;
    cursor: pointer;
    padding: var(--spc-sm-h) 5px;
    margin: 0;
    border-radius: unset;
    background-color: transparent;
    box-shadow: unset;
}

.product-btn-wrapper:hover,
.product-btn-wrapper.selected {
    background-color: var(--black-80);
}

.product-btn-wrapper img {
    max-height: 120px;
    max-width: 150px;
    padding: clamp(var(--spc-xs-h), 1vmax, var(--lg));
}

.product-btn-wrapper h6 {
    /* font-family: var(--headline-font); */
    text-align: center;
    color: var(--white);
    margin: 0;
}

.product-btn-wrapper.selected h6 {
    font-family: var(--headline-font-bold);
    color: var(--accent);
}

.product-wrapper {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
        'title title title title img img img img'
        'details details details details img img img img'
        'features features features features features specs specs specs'
        'features features features features features specs specs specs';
    gap: var(--spc-md-h) var(--spc-lg-w);
    padding: var(--spc-xl);
    /* padding-bottom: 0; */
}

.product-wrapper .product-title {
    margin: 0;
    grid-area: title;
}

.product-wrapper .product-details {
    grid-area: details;
    margin: 0;
    min-height: 76px;
}

.product-wrapper .product-img {
    display: flex;
    place-self: center;
    grid-area: img;
    max-height: 280px;
    height: 100%;
    padding: var(--spc-md-h) var(--spc-xl-w);
    border-radius: var(--rnd-md);
}

.product-wrapper .product-features {
    grid-area: features;
}

.features-wrapper {
    padding-left: var(--spc-sm-w);
}

.product-features .feature-wrapper {
    display: grid;
    grid-template-columns: 25px 1fr;
    gap: var(--spc-sm-w);
    place-content: flex-start;
    place-items: flex-start;
    margin: var(--spc-xs-h) 0;
}

.feature-wrapper .icon {
    margin: 0;
    height: auto;
}

.feature-name {
    margin: 0;
}

.feature-details {
    font-size: 0.8em;
    font-family: var(--condensed-font);
}

.product-wrapper .product-specs {
    grid-area: specs;
}

.product-specs ul {
    -webkit-padding-start: 20px;
    padding-inline-start: 20px;
}

.iframe .slider-wrapper > img {
    height: clamp(250px, 30vh, 400px);
    border-radius: var(--corner-radius-lg);
}

.product-img {
    padding: var(--spc-lg);
    background: var(--bg-gradient-blue-dp);
}

.photo-img {
    -o-object-fit: cover;
    object-fit: cover;
    /* -o-object-position: top;
     object-position: top; */
}

.slider-wrapper .icon-wrapper {
    --size: 250px;
    width: var(--size);
    height: var(--size);
    margin: auto;
    padding: var(--spc-xl);
    background: var(--bg-gradient);
    border-radius: var(--size);
    cursor: pointer;
    transition: var(--fade);
}

.slider-btn-row {
    --btn-size: clamp(60px, 7vw, 100px);
    display: flex;
    place-content: center;
    justify-content: space-between;
}

.slider-btn-row > img {
    width: var(--btn-size);
    height: calc(var(--btn-size) * 0.8);
    background: var(--bg-gradient-white);
    border-radius: 12px;
    padding: 4px;
    cursor: pointer;
    transition: var(--fade);
}

.slider-btn-row .icon-wrapper {
    --btn-size: clamp(55px, 8vw, 90px);
    width: var(--btn-size);
    height: var(--btn-size);
    padding: var(--spc-sm);
}

.slider-btn-row .icon-wrapper:hover,
.slider-btn-row .icon-wrapper.selected {
    border: 3px solid var(--accent);
}

.slider-btn-row > img:hover {
    background: transparent;
    background-color: var(--gray-50);
}

.slider-btn-row > img.selected {
    background: var(--bg-gradient-blue-dp);
    border: 3px solid var(--accent);
}

.rebate-preview-wrapper {
    --img-width: clamp(80px, 10vw, 150px);
    --gap: var(--spc-sm-w);
    width: 100%;
    background-color: var(--site-bg);
    border-radius: var(--rnd-lg);
    padding: var(--spc-md);
}

.rebate-preview-wrapper > div:first-child {
    display: grid;
    grid-template-columns: var(--img-width) 1fr;
    align-items: center;
    -moz-column-gap: var(--gap);
    column-gap: var(--gap);
}

.rebate-preview-wrapper .condensed {
    margin: var(--spc-xs-h) 0;
}

.rebate-preview-wrapper .simple-link {
    transform: translateX(calc(((var(--img-width) + var(--gap)) / 2) * -1));
    margin: var(--spc-lg-h) auto 0 auto;
}

.simple-link.closed .icon::before {
    content: 'Show Details';
}

.simple-link.open .icon::before {
    content: 'Hide Details';
}

.rebate-list-wrapper {
    transition: var(--fade-slow);
}

.rebate-list-wrapper > div {
    margin: var(--spc-md);
    padding: var(--spc-md-w) 0;
    border-top: 1px solid var(--gray);
}

.rebate-list-wrapper.closed {
    max-height: 0;
    visibility: hidden;
}

.rebate-list-wrapper.open {
    max-height: 10000px;
    visibility: visible;
}

.rebate-list-wrapper > div > div {
    display: grid;
    grid-template-columns: clamp(80px, 10vw, 150px) auto;
    -moz-column-gap: var(--spc-lg-w);
    column-gap: var(--spc-lg-w);
}

/* ---------- RESIZE WRAPPER ---------- */

/* ---------- MOBILE STYLES ---------- */

@media screen and (max-width: 1200px) {
    .section-content-wrapper {
        row-gap: var(--spc-sm-h);
    }

    .two-column-img-left > .section-content-wrapper {
        grid-template-columns: 1fr;
        grid-template-areas: 'img' 'details';
    }

    .two-column-img-right > .section-content-wrapper {
        grid-template-columns: 1fr;
        grid-template-areas: 'img' 'details';
        place-items: center;
    }

    .section-content-wrapper > img {
        max-height: 600px;
    }

    .download-section {
        height: auto;
    }
    .download-section > .section-content-wrapper,
    #Subscribe > .section-content-wrapper {
        max-width: 700px;
        grid-template-columns: 1fr;
        grid-template-areas: 'details' 'a';
        row-gap: calc(var(--grid-gap) * 2.5);
    }

    .content-box-container {
        grid-template-columns: 1fr;
        padding: 0;
    }
    .content-box-container .glass-panel {
        width: 65vw;
        max-width: 500px;
    }

    .modal {
        max-width: 80%;
        padding: var(--lg) var(--xl);
    }
    .roller-details-wrapper {
        width: 90vw;
        gap: calc(var(--grid-gap) * 1);
        grid-template-columns: 1fr 2fr;
        grid-template-areas:
            'headline headline'
            'roller-img text'
            'roller-img list'
            'roller-img list'
            'roller-img roller-cta';
    }

    .new-product-card-wrapper {
        margin: var(--spc-xl-h) auto;
        padding-bottom: var(--spc-lg-h);
        background: linear-gradient(var(--deg), transparent 65%, var(--gray-10) 65%), linear-gradient(var(--deg), var(--gray-10) 0%, transparent 60%);
    }

    .new-product-card-wrapper:hover {
        background-size: unset;
    }

    .card-content {
        grid-template-columns: 1fr;
        gap: var(--grid-gap);
    }

    .card-content h2,
    .card-content h3,
    .card-content img {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .card-content .mobile-only {
        margin: var(--xl) auto;
        max-height: 400px;
    }

    /* .card-content > div > h3 {
    margin-bottom: 0;
  }

  .card-content > div > .model {
    margin-top: 0;
  } */

    .images-column {
        display: none;
    }

    .mobile-only {
        display: flex;
    }
}

@media screen and (max-width: 1000px) {
    .product-wrapper {
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
            'title title title title'
            'img img img img'
            'details details features features'
            'details details features features';
    }

    .product-specs {
        display: none;
    }

    .product-features .feature-wrapper {
        grid-template-columns: 25px auto;
    }

    .feature-details {
        display: none;
        width: 0;
    }

    .tab-content-wrapper.products nav h6 {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .four-column-wrapper {
        display: grid;
        grid-area: columns;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--grid-gap);
        justify-content: center;
        justify-items: center;
    }

    .content-box-container .glass-panel {
        width: 100%;
        max-width: 90vw;
        background-color: transparent;
        border: none;
        box-shadow: none;
        -webkit-backdrop-filter: none;
        backdrop-filter: none;
        padding-left: 0;
        padding-right: 0;
    }

    .brands .glass-panel {
        background-color: var(--white);
        padding: var(--spc-xl2);
        height: unset;
    }

    .content-box-container .glass-panel h3 {
        /* font-size: 1.3em; */
        padding-top: 0;
        margin-bottom: var(--spc-xs-h);
    }

    .content-box-container .glass-panel h4 {
        margin-top: var(--spc-xs-h);
        margin-bottom: var(--spc-xs-h);
    }

    .simple-link,
    .content-box-container a.phone {
        font-size: clamp(var(--md), 3vw, var(--lg));
    }

    .modal {
        width: 100%;
        max-width: 100%;
        padding: var(--lg) var(--lg);
        border-radius: 0;
    }

    .modal-title {
        margin: var(--spc-sm-h) auto;
    }
    .modal-details {
        margin: 0;
        grid-template-columns: 1fr;
        gap: 10px;
    }

    .roller {
        --roller-menu-ht: 80px;
        width: 100%;
    }
    .roller > .section-content-wrapper {
        padding-left: var(--spc-lg-w);
    }
    .roller > .section-content-wrapper.details-visible {
        transform: translateX(-33%);
    }

    .roller-cta-wrapper {
        margin: auto;
        padding: 20px;
    }
    .roller-btn.close:hover {
        transform: translateX(0px);
    }
    .btn-tab {
        width: auto;
        padding: 0 var(--spc-sm-w);
    }
}

@media screen and (max-width: 700px) {
    .section-content-wrapper > img {
        max-height: 400px;
    }

    .no-form > .section-content-wrapper {
        grid-template-columns: 1fr;
    }

    .roller-details-wrapper {
        width: 90vw;
        gap: calc(var(--grid-gap) * 1.5);
        grid-template-columns: 2fr 3fr;
        grid-template-areas:
            'headline headline'
            'roller-img text'
            'roller-img text'
            'list list'
            'list list'
            'roller-cta roller-cta';
    }

    .roller-menu {
        grid-template-columns: repeat(4, 1fr);
        gap: var(--grid-gap-xs);
    }

    .product-wrapper .product-img {
        max-height: 230px;
    }

    .product-nav-bar {
        display: grid;
        place-content: center;
        align-content: center;
        margin-bottom: var(--spc-xl-h);
    }

    .product-nav-link {
        padding: var(--spc-xs);
        margin: var(--spc-xs-h) 0;
        width: unset;
    }

    .new-product-card-wrapper {
        box-shadow: none;
        margin-right: 0;
        margin-left: 0;
        width: unset;
    }

    .nav-bar.below {
        --height: 55px;
        position: fixed;
        position: -webkit-fixed;
        top: unset;
        transform: none;
        bottom: 0;
    }

    nav.below a {
        width: unset;
    }

    .tab-content-wrapper {
        grid-template-columns: 1fr;
    }

    #tab-title {
        margin: 0;
        text-align: center;
    }

    #tab-p {
        display: none;
    }
}
@media screen and (max-width: 500px) {
    .tab-content-wrapper.products {
        grid-template-columns: 1fr;
    }

    .tab-content-wrapper.products nav {
        display: grid;
        height: unset;
        /* padding: var(--spc-xs-h) 0; */
        grid-template-columns: var(--grid-auto);
    }

    .tab-content-wrapper.products nav button {
        padding: var(--spc-xs-h) 0;
    }

    .product-btn-wrapper {
        padding: 0;
    }

    .product-btn-wrapper img {
        min-height: 50px;
        max-height: 60px;
        max-width: 80px;
    }

    .product-wrapper .product-img {
        max-height: 200px;
    }

    .product-wrapper {
        grid-template-areas:
            'title title title title'
            'img img img img'
            'details details details details'
            'features features features features';
    }

    .features-wrapper {
        display: grid;
        -moz-column-gap: var(--spc-sm-w);
        column-gap: var(--spc-sm-w);
        grid-template-columns: auto auto;
    }
}
@media screen and (max-width: 400px) {
    .modal {
        padding-left: var(--spc-md-w);
        padding-right: var(--spc-md-w);
    }

    .modal-details img {
        display: none;
    }
    .roller > .section-content-wrapper {
        align-items: start;
        /* padding-top: calc(var(--header-ht) + var(--roller-menu-ht) + 20px); */
        padding-left: var(--spc-md-w);
        padding-bottom: 10px;
        grid-template-columns: 202px auto;
        background: linear-gradient(107deg, transparent 40%, var(--blue-dp) 40%, var(--black) 100%),
            radial-gradient(circle at 0% 20%, var(--black-50) 20%, transparent 40%);
        justify-content: start;
    }
    .roller-title-wrapper {
        align-content: start;
    }
    .roller-title-wrapper h1 {
        margin-bottom: var(--std);
    }
    .roller-title-wrapper h1,
    .roller-title-wrapper h2 {
        max-width: 100%;
        margin-top: 0;
    }
    .roller-btn {
        grid-template-columns: auto 20px;
        grid-template-rows: 21px;
        align-items: center;
        right: unset;
        left: var(--spc-md-w);
        top: 500px;
        bottom: unset;
        transition: var(--fade);
        background-color: var(--blue-dp);
        padding: var(--spc-xs-h) var(--base);
        border-radius: var(--corner-radius-sm);
    }

    .roller-btn > h5 {
        margin: 0;
        text-shadow: none;
    }
    .roller-btn:hover {
        transform: unset;
    }
    .roller-btn.close {
        grid-template-columns: auto 30px;
        top: calc(var(--header-ht) + var(--roller-menu-ht) + 5px);
        left: unset;
        right: 5px;
        background-color: unset;
        padding: 0;
        border-radius: 0;
    }
    .roller > .section-content-wrapper.details-visible {
        transform: translateX(-27%);
    }

    .roller-menu-title {
        font-size: var(--base);
    }

    .roller-details-img {
        margin-left: 50%;
    }

    .roller-details-text {
        display: none;
    }
    .selected-item {
        padding-bottom: 5px;
        border-bottom: 4px solid white;
    }
}

@media screen and (max-width: 320px) {
    .roller > .section-content-wrapper.details-visible {
        transform: translateX(-32%);
    }
}
