.header {
    display: flex;
    padding: var(--spc-sm-h) var(--spc-lg-w);
    grid-template-columns: auto minmax(0, 3fr) auto;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: space-between;
    height: var(--header-ht);
    width: 100%;
    margin: 0 auto;
    position: -webkit-fixed;
    position: fixed;
    top: 0px;
    z-index: 10;
    font-family: var(--headline-font);
    background-color: var(--site-bg-70);
    /* box-shadow: var(--shadow-far); */
    border-bottom: 1px solid var(--gray-lt-10);
    -webkit-backdrop-filter: var(--blur);
    backdrop-filter: var(--blur);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transition: var(--fade-slow);
    overflow-y: hidden;
}

.product-header {
    place-items: center;
    width: var(--content-width);
    max-width: 1200px;
    margin: 50px auto 0 auto;
    border-radius: var(--corner-radius-lg) var(--corner-radius-lg) 0 0;
    position: -webkit-sticky;
    position: sticky;
    box-shadow: none;
    top: -30px;
}

header.side-bar {
    width: calc(100% - var(--sidebar-width));
}

.dark-mode-header {
    background-color: var(--black-80);
}

.header.no-phone {
    grid-template-columns: auto 1fr;
    justify-items: flex-end;
    align-items: flex-start;
    gap: var(--spc-md);
    grid-template-areas: unset;
    background: transparent;
    -webkit-backdrop-filter: var(--blur);
    backdrop-filter: var(--blur);
}

.header h4 {
    text-align: center;
    font-size: clamp(var(--md), 3vw, var(--xl));
}

#header-nav {
    margin-top: var(--spc-sm-h);
}

#header-nav > a {
    padding: 0 var(--spc-sm-w);
    color: var(--text-col);
}

.header .phone {
    display: grid;
    align-self: flex-start;
    grid-template-columns: 30px auto;
    gap: var(--spc-xs);
    color: var(--text-col);
    font-family: var(--headline-font);
    margin-top: var(--spc-sm-h);
}

.header .phone > div {
    display: grid;
    align-items: center;
}

a#header-scroll-btn {
    color: var(--site-bg);
    display: inline-block;
    opacity: 0;
    transform: translateY(-20px);
    margin: 0;
    margin-right: var(--spc-xs-w);
    padding: var(--spc-xs-h) var(--spc-sm-w);
    transition: var(--fade-slow);
    box-shadow: none;
}

#header-scroll-btn.fade-down {
    transform: translateY(0px);
    opacity: 1;
}

/* .dark-mode-header a#header-scroll-btn {
    text-shadow: 0px 0px 40px rgba(0, 0, 0, 1), 0px 0px 20px rgba(0, 0, 0, 1), 0px 0px 5px rgba(0, 0, 0, 1);
} */

.iframe-header {
    margin: 0 auto;
    background: var(--blue-dp);
    height: auto;
    width: 100%;
    max-width: var(--content-width);
    padding-top: var(--spc-sm-w);
    padding-bottom: calc(var(--spc-sm-w) * 1.2);
    padding-left: var(--spc-xl-w);
    padding-right: var(--spc-xl-w);
    border-top-left-radius: var(--corner-radius-lg);
    border-top-right-radius: var(--corner-radius-lg);
    box-shadow: var(--shadow-full);
}

.iframe-header.with-title {
    display: grid;
    gap: var(--spc-md-w);
    grid-template-columns: 1fr auto;
    align-items: center;
}

.iframe-header-title {
    margin: 0;
    color: var(--white);
    font-family: var(--headline-font);
}

.iframe-header-logo {
    height: clamp(35px, 5vmin, 50px);
    /* width: clamp(180px, 20vw, 350px); */
    margin: auto;
}

.logo {
    margin: 0;
    display: flex;
    align-self: flex-start;
    justify-self: center;
    width: auto;
    max-width: clamp(210px, 20vw, 320px);
    height: clamp(40px, 100%, 55px);
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transition: none;
    border-radius: 0;
}

#mobile-nav {
    display: none;
    height: var(--mobile-nav-ht);
    grid-auto-flow: column;
    background-color: var(--site-bg-80);
    width: 100%;
    position: fixed;
    position: -webkit-fixed;
    bottom: 0;
    padding: var(--spc-sm);
    place-content: center;
    place-items: center;
    box-shadow: -8px -8px 8px rgba(0, 0, 0, 0.25);
    z-index: 100;
    text-align: center;
}

#mobile-nav a {
    padding: 0 var(--spc-sm-w);
    font-family: var(--headline-font);
    color: var(--text-col);
}

#mobile-nav a#header-scroll-btn {
    color: var(--accent);
    width: auto;
    opacity: 1;
    transform: unset;
    font-family: var(--headline-font-bold);
    text-shadow: 0 0 0.7em var(--site-bg);
}

/* disable transitions in safari */
/* ios */
@supports (-webkit-touch-callout: none) {
    header,
    a.logo {
        transition: none;
    }
}

@media screen and (max-width: 1200px) {
    a#header-scroll-btn {
        opacity: 1;
    }

    .product-header {
        top: -20px;
    }

    .header .phone {
        grid-area: phone;
    }

    #header-nav {
        display: none;
    }

    #header-nav a {
        padding: 0 var(--spc-xs-w);
        color: var(--text-col);
    }

    #mobile-nav {
        display: grid;
    }
    /* #header-nav a::after {
		display: none;
	} */
    .header .phone > div {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .product-header {
        margin-top: var(--spc-sm-w);
        grid-template-columns: 1fr 1fr;
    }

    .product-header .btn:hover,
    .product-header button:hover {
        background-color: initial;
        box-shadow: initial;
    }

    .product-header .btn:focus {
        outline: var(--outline);
    }

    .product-header .btn.secondary,
    .product-header button.secondary {
        padding: var(--spc-xs);
        border: none;
        color: var(--red);
        box-shadow: none;
        background: none;
    }

    .product-header .btn.secondary:hover,
    .product-header button.secondary:hover {
        background-color: initial;
        border: initial;
    }

    #header-btn {
        margin: 0;
    }
}

@media screen and (max-width: 400px) {
    #mobile-nav {
        padding-left: 0;
        padding-right: 0;
    }

    #mobile-nav a {
        padding: 0 var(--spc-xs-w);
        font-size: 12px;
    }

    .iframe-header.with-title {
        grid-template-areas: 'logo' 'title';
        row-gap: var(--spc-md-h);
        -moz-column-gap: 0;
             column-gap: 0;
        align-items: center;
    }
    
    .iframe-header-title {
        text-align: center;
        grid-area: title
    }
    
    .iframe-header-logo {
        grid-area: logo;
    }
}
