@import './fonts.css';
@import './colors.css';
@import './definitions.css';
@import './reset.css';
@import './custom-props.css';
@import './sections.css';
@import './hero-sections.css';
@import './forms.css';
@import './input-placeholders.css';
@import './bu-map.css';
@import './sidebar.css';
@import './color-grid.css';
@import './color-gradients.css';
@import './cookie-bar.css';

/* DARK mode */

@media (prefers-color-scheme: dark) {
    :root {
        --accent: var(--accent-col-dark-mode);
        --svg-folder: 'white';
        --logo-url: url('./logos/ME-logo-reverse.svg');
        --icon-filter: var(--white-filter);
        --site-bg: var(--gray-dp);
        --bg: var(--gray-dk);
        --text-col: var(--white);
        --term-col: var(--term-col-dark-mode);
        --glow-col: var(--gray-lt-10);
        --glass-col: var(--gray-dk-80);
        --shadow-col: var(--black-50);
        --accent-filter: var(--blue-filter);
        --highlight-tight: none;
        --neumorph-md: var(--neumorph-md-dk);
        --input-bg: var(--site-bg-50);
        --neu-btn: var(--bg-50);
        --bg-gradient: var(--bg-gradient-blue-dp);
    }
}

/* LIGHT mode */

@media (prefers-color-scheme: light) {
    :root {
        --accent: var(--accent-col-light-mode);
        --svg-folder: 'black';
        --logo-url: url('./logos/ME-logo-primary.svg');
        --site-bg: var(--white);
        --bg: var(--off-white);
        --text-col: var(--black);
        --term-col: var(--term-col-light-mode);
        --glow-col: var(--white);
        --glass-col: var(--gray-lt-80);
        --shadow-col: var(--gray-dp-20);
        --accent-filter: var(--blue-dk-filter);
        --input-bg: transparent;
        --neu-btn: transparent;
        --bg-gradient: var(--bg-gradient-gray);
    }
}

body {
    background: linear-gradient(to right, var(--bg) -30%, var(--site-bg), var(--bg) 130%);
    color: var(--text-col);
    font-size: var(--md);
    font-family: var(--main-font);
    /* overflow-x: hidden; */
}

.grecaptcha-badge {
    opacity: 0;
    display: none;
    visibility: hidden;
}

#ie-output {
    margin-top: 50px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    /* line-height: clamp(var(--lg), 6vw, 1.4em); */
    color: var(--text-col);
    font-family: var(--headline-font);
    margin: var(--lg) 0;
    white-space: pre-wrap;
    letter-spacing: 0em;
    line-height: 1.4em;
}

h1,
.h1 {
    font-size: clamp(var(--xl), 8vw, var(--xl4));
    margin: var(--spc-lg-h) 0;
    letter-spacing: 0.06em;
}

h2,
.h2 {
    font-size: clamp(var(--xl), 6.5vw, var(--xl3));
    margin: calc(var(--spc-lg-h) * 0.833334) 0;
}

h3,
.h3 {
    font-size: clamp(var(--lg), 5vw, var(--xl2));
    margin: var(--spc-md-h) 0;
}

h4,
.h4 {
    font-size: clamp(var(--md), 4vw, var(--xl));
    margin: calc(var(--spc-md-h) * 0.833334) 0;
}

h5,
.h5 {
    font-size: clamp(var(--md), 3vw, var(--lg));
    margin: var(--spc-sm-h) 0;
}

h6,
.h6 {
    font-size: clamp(var(--md), 2vw, var(--md));
    margin: var(--spc-sm-h) 0;
}

strong {
    font-family: var(--condensed-font-bold);
}

p {
    margin: var(--lg) 0;
    line-height: 1.3em;
    white-space: pre-wrap;
    /* text-align: justify; */
}

p,
ul,
li,
div,
label,
select,
option,
blockquote {
    color: var(--text-col);
}

.bold {
    font-family: var(--main-font-bold);
}

.condensed {
    font-family: var(--condensed-font);
}

.condensed-bold {
    font-family: var(--condensed-font-bold);
}

.dark-mode {
    color: var(--white);
}

.dark-mode p,
.dark-mode h1,
.dark-mode h2,
.dark-mode h3,
.dark-mode h4,
.dark-mode h5,
.dark-mode h6,
.dark-mode ul li {
    color: var(--white);
}

.dark-mode button,
.dark-mode input {
    box-shadow: var(--neumorph-md-dk);
}

.dark-mode a {
    --accent: var(--accent-col-dark-mode);
}

aside.dark-mode {
    background: var(--bg-gradient-blue-dp);
    color: var(--white);
}

aside {
    background: var(--bg-gradient);
}

.link,
a {
    font-family: var(--headline-font-bold);
    color: var(--accent);
    text-decoration: none;
    cursor: pointer;
    transition: var(--fade);
}

a.on-accent-col {
    color: var(--white);
    text-decoration: underline;
}

a:target:before {
    content: '';
    display: block;
    height: var(--header-ht);
    margin: -100px 0 0;
}

ul {
    position: relative;
}

li {
    position: relative;
    line-height: 1.4em;
    /* left: -20px; */
}

table {
    border-collapse: collapse;
    width: 100%;
    font-family: var(--condensed-font);
}

table,
th,
td {
    border: 1px solid var(--gray-50);
    padding: var(--sm);
}

main {
    display: grid;
    grid-template-rows: auto;
    padding: 0;
    margin: auto;
}

/* removing below will break some layouts, like iframes */
section {
    overflow-x: hidden;
}

/* .btn,
button,
input[type='submit']:valid {
	background-color: var(--accent);
	border: none;
	border-radius: var(--corner-radius-sm);
	padding: var(--base) var(--md);
	color: var(--white);
	font-family: var(--headline-font);
	font-size: var(--md);
	text-transform: capitalize;
	text-decoration: none;
	text-align: center;
	cursor: pointer;
	width: auto;
	box-shadow: var(--neumorph-md);
	transition: var(--fade);
} */

.btn,
button,
input[type='submit'] {
    --animation: button-gradient 20s ease-in-out infinite;
    display: inline-block;
    border: none;
    padding: 14px var(--spc-md-w);
    border-radius: 50px;
    background-color: var(--accent);
    /* background: radial-gradient(
			circle at 50% 130%,
			var(--accent-dk),
			transparent 30%
		),
		radial-gradient(circle at 50% 50%, var(--blue-dp), var(--accent-dk));
	background-size: 200% 100%;
	-webkit-animation: var(--animation);
	animation: var(--animation);
	-webkit-animation-direction: alternate;
	animation-direction: alternate; */
    color: var(--site-bg);
    font-family: var(--headline-font);
    font-size: var(--md);
    text-transform: capitalize;
    text-decoration: none;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: var(--spc-sm-h) 0;
    cursor: pointer;
    box-shadow: var(--shadow-far);
    transition: var(--fade);
}

.btn.mini,
.btn.secondary.mini,
button.secondary.mini {
    padding: 1px var(--spc-xs-w);
    margin: 0;
    display: inline;
    text-transform: unset;
    font-size: 1.25rem;
}

@-webkit-keyframes button-gradient {
    0% {
        background-position: left;
    }
    100% {
        background-position: right;
    }
}

@keyframes button-gradient {
    0% {
        background-position: 0%;
    }
    100% {
        background-position: 100%;
    }
}

input[type='submit'] {
    padding: var(--spc-xs-h) var(--spc-sm-w);
}

.btn:hover,
button:hover,
input[type='submit']:hover {
    border: none;
}

.btn:active,
button:active,
input[type='submit']:active {
    box-shadow: var(--shadow-tight);
}

.btn:focus,
button:focus,
input[type='submit']:focus {
    outline: var(--outline);
}

.btn:disabled,
button:disabled,
input[type='submit']:disabled {
    box-shadow: none;
    opacity: 0.5;
    cursor: not-allowed;
}

.product-btn-wrapper:focus,
.btn-tab:focus {
    outline: 3px solid var(--white);
}

.btn.secondary,
button.secondary {
    background: var(--site-bg-50);
    border: 2px solid var(--accent);
    padding: var(--spc-xs-h) var(--spc-sm-w);
    color: var(--accent);
    text-transform: none;
}

.btn.secondary:hover,
button.secondary:hover {
    background-color: var(--site-bg);
}

.btn.secondary:active,
button.secondary:active {
    color: var(--accent-50);
}

.icon#x {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

img {
    width: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
}

svg,
.svg {
    display: block;
    width: 100%;
    height: auto;
}

#root ~ img {
    height: 0;
}

video {
    width: 100%;
    /* height: 100%; */
    background-repeat: no-repeat;
    background-size: var(--xl6);
    background-position: center;
}

/* generic style classes */

.red {
    color: var(--red);
}

.center {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.right {
    text-align: right;
    margin-right: 0;
}

.hidden {
    display: none;
}

.grid-parent {
    display: grid;
    gap: var(--spc-sm-w);
}

.grid-2-col-auto {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: var(--spc-md-w);
}

.grid-2-col-even {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spc-md-w);
}

.grid-2-col-1-2 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: var(--spc-md-w);
}

.grid-2-col-2-3 {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: var(--spc-md-w);
}

.grid-2-col-1-auto {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: var(--spc-md-w);
}

.flex-parent {
    display: flex;
    gap: var(--spc-md-w);
}

.align-center {
    align-items: center;
}

.align-top {
    align-items: flex-start;
}

.align-btm {
    align-items: flex-end;
}

.align-left {
    justify-items: flex-start;
}

.align-right {
    justify-items: flex-end;
}

.gap-xs {
    gap: var(--spc-xs-w);
}

.smaller {
    font-size: smaller;
}

.bg-gradient {
    /* background: transparent; */
    background: var(--bg-gradient);
}

.no-margin {
    margin: 0;
}

.no-padding {
    padding: 0;
}

div.cluster > div {
    font-family: var(--headline-font-bold) !important;
    font-size: 13px !important;
    color: var(--white) !important;
}

.bu-links-row {
    background-color: var(--site-bg-50);
    backdrop-filter: var(--blur);
    -webkit-backdrop-filter: var(--blur);
    position: fixed;
    right: 0;
    bottom: 0;
    padding: 10px 20px;
    font-family: var(--headline-font);
    font-size: var(--std);
}

.bu-links-row > a {
    padding: 5px;
    margin: 5px;
    border: 1px solid red;
    border-radius: 5px;
}

#bg {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1;
}

.full-width {
    width: 100%;
}

.quote {
    display: inline;
    position: relative;
    font-size: 2em;
    top: 0.15em;
    color: inherit;
    line-height: 0.5em;
    padding: 0 5px;
}

/*.quote:before,*/
/*.quote:after {*/
/*	content: '"';*/
/*}*/

.superscript {
    display: inline;
    position: relative;
    font-size: 0.5em;
    top: -0.7em;
    color: inherit;
}

.superscript.big {
    font-size: 0.9em;
    top: -0.5em;
}

.fs-bg {
    background-image: url('./fs/AS-LifestyleLivingRoomWall.webp');
}

.img3 {
    /* background: transparent; */
    background: radial-gradient(circle at center, var(--text-col), transparent), radial-gradient(circle at top, var(--off-white), transparent),
        radial-gradient(circle at bottom right, var(--site-bg), transparent), radial-gradient(circle at left, var(--gray), transparent);
}

.img4 {
    background: transparent;
}

.icon {
    margin: auto;
    width: 100%;
    height: 100%;
    -o-object-position: center;
    object-position: center;
    -o-object-fit: contain;
    object-fit: contain;
    background-color: transparent;
    background-image: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.icon.lazy {
    border-radius: 0;
    background-image: none;
    background-color: transparent;
}

.icon.tiny {
    margin: 0;
    width: 20px;
    height: 20px;
}

.icon.small {
    margin: 0;
    width: 30px;
}

button.sc-beqWaB.bAKgyq,
button.gXIUqQ {
    --size: 30px;
    width: var(--size);
    height: var(--size);
    max-width: var(--size);
    max-height: var(--size);
}

button.sc-beqWaB.bAKgyq .icon,
button.gXIUqQ .icon {
    margin: 0;
    width: 100%;
    height: auto;
}

.spinner-component-wrapper {
    /* position: relative; */
    display: grid;
    width: 100%;
    place-items: center;
    place-content: center;
    padding: 0 var(--lg);
    margin: 0 auto;
}

.spinner-component-wrapper h4 {
    margin-bottom: 0;
}

.spinner-symbols-wrapper {
    --size: clamp(20px, 8vmin, 40px);
    --deg: 20deg;
    --deg2: -20deg;
    --time: 6000ms;
    --start: translate(-50%, -50%) rotate(45deg) skew(var(--deg), var(--deg));
    --end: translate(-50%, -50%) rotate(225deg) skew(var(--deg), var(--deg));
    width: 100%;
    height: calc(var(--size) + 50px);
    position: relative;
    /* top: var(--size); */
}

.spinner-symbols-wrapper div {
    top: 50%;
    left: 50%;
    transform: var(--start);
    position: absolute;
    width: var(--size);
    height: var(--size);
    background-color: var(--text-col-30);
    -webkit-animation-duration: var(--time);
    animation-duration: var(--time);
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

.dark .spinner-symbols-wrapper div {
    background-color: var(--site-bg-30);
}

#spinner1 {
    /* --time: 4500ms; */
    /* background-color: var(--site-bg-50); */
    -webkit-animation-name: spinner1;
    animation-name: spinner1;
}

#spinner2 {
    /* --time: 5000ms; */
    /* background-color: var(--text-col-50); */
    -webkit-animation-name: spinner2;
    animation-name: spinner2;
}

#spinner3 {
    /* --time: 5500ms; */
    /* background-color: var(--site-bg-50); */
    -webkit-animation-name: spinner3;
    animation-name: spinner3;
}

@-webkit-keyframes spinner1 {
    0% {
        transform: var(--start);
    }

    100% {
        transform: var(--end);
    }
}

@keyframes spinner1 {
    0% {
        transform: var(--start);
    }

    100% {
        transform: var(--end);
    }
}

@-webkit-keyframes spinner2 {
    0% {
        transform: var(--start);
    }
    10% {
        transform: var(--start);
    }
    20% {
        transform: translate(-50%, -50%) rotate(45deg) skew(var(--deg2), var(--deg2));
    }
    80% {
        transform: translate(-50%, -50%) rotate(45deg) skew(var(--deg2), var(--deg2));
    }
    100% {
        transform: var(--start);
    }
}

@keyframes spinner2 {
    0% {
        transform: var(--start);
    }
    10% {
        transform: var(--start);
    }
    20% {
        transform: translate(-50%, -50%) rotate(45deg) skew(var(--deg2), var(--deg2));
    }
    80% {
        transform: translate(-50%, -50%) rotate(45deg) skew(var(--deg2), var(--deg2));
    }
    100% {
        transform: var(--start);
    }
}

@-webkit-keyframes spinner3 {
    0% {
        transform: var(--start);
    }
    15% {
        transform: translate(-50%, -50%) rotate(45deg) skew(var(--deg2), var(--deg2));
    }
    30% {
        transform: translate(-50%, -50%) rotate(135deg) skew(var(--deg2), var(--deg2));
    }
    45% {
        transform: translate(-50%, -50%) rotate(135deg) skew(var(--deg), var(--deg));
    }
    60% {
        transform: var(--end);
    }
    80% {
        transform: translate(-50%, -50%) rotate(225deg) skew(var(--deg2), var(--deg2));
    }
    100% {
        transform: var(--end);
    }
}

@keyframes spinner3 {
    0% {
        transform: var(--start);
    }
    15% {
        transform: translate(-50%, -50%) rotate(45deg) skew(var(--deg2), var(--deg2));
    }
    30% {
        transform: translate(-50%, -50%) rotate(135deg) skew(var(--deg2), var(--deg2));
    }
    45% {
        transform: translate(-50%, -50%) rotate(135deg) skew(var(--deg), var(--deg));
    }
    60% {
        transform: var(--end);
    }
    80% {
        transform: translate(-50%, -50%) rotate(225deg) skew(var(--deg2), var(--deg2));
    }
    100% {
        transform: var(--end);
    }
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    /* .header, */
    .product-header,
    .glass-panel,
    .footnote-bar,
    .roller-menu,
    #mobile-btns,
    #mobile-instructions,
    #mobile-nav {
        backdrop-filter: var(--blur);
        -webkit-backdrop-filter: var(--blur);
    }
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .header,
    .product-header,
    .glass-panel,
    .footnote-bar,
    .roller-menu,
    #mobile-btns,
    #mobile-instructions,
    #mobile-nav {
        background-color: var(--site-bg-80);
    }
}

@media screen and (max-width: 480px) {
    :root {
        --content-width: 100%;
        --grid-gap: var(--md);
    }
}

/* --------------------- SCROLLBAR */

/* Chrome and Safari (webkit) */
/* comments from MDN https://developer.mozilla.org/en-US/docs/Web/CSS/::-webkit-scrollbar */

/* ::-webkit-scrollbar — the entire scrollbar */
::-webkit-scrollbar {
    background: var(--site-bg);
    width: var(--spc-xs-w);
    border-radius: var(--spc-xs-w);
}

/* ::-webkit-scrollbar:vertical{} — the vertical scrollbar. */

/* ::-webkit-scrollbar:horizontal{} — the horizontal scrollbar. */

/* ::-webkit-scrollbar-button — the buttons on the scrollbar (arrows pointing upwards and downwards that scroll one line at a time). */
::-webkit-scrollbar-button {
    background: var(--accent);
}
/* ::-webkit-scrollbar-thumb — the draggable scrolling handle. */
::-webkit-scrollbar-thumb {
    border-radius: var(--spc-xs-w);
    background: var(--accent);
}

/* ::-webkit-scrollbar-track — the track (progress bar) of the scrollbar, where there is a gray bar on top of a white bar. */
::-webkit-scrollbar-track {
    background: transparent;
}

/* ::-webkit-scrollbar-track-piece — the part of the track (progress bar) not covered by the handle. */
::-webkit-scrollbar-track-piece {
    background-color: transparent;
}
/* ::-webkit-resizer — the draggable resizing handle that appears at the bottom corner of some elements. */

/* ::-webkit-scrollbar-corner — the bottom corner of the scrollbar, where both horizontal and vertical scrollbars meet. This is often the bottom-right corner of the browser window. */
::-webkit-scrollbar-corner {
    background: transparent;
}

/* Firefox */
html {
    scrollbar-width: thin; /* options are 'auto', 'thin', and 'none' */
    scrollbar-color: var(--accent) transparent; /* thumb (foreground) color and track (background) color */
}
