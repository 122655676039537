/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
	padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0;
}

/* Set core body defaults */
body {
	/* min-height: 100vh; */
	scroll-behavior: smooth;
	text-rendering: optimizeSpeed;
	/* line-height: 1.5; */
}

/* body * {
  -webkit-appearance: none;
} */

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
	list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
	-webkit-text-decoration-skip: ink;
	text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
	max-width: 100%;
	display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
	margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
	font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
	* {
		-webkit-animation-duration: 0.01ms !important;
		animation-duration: 0.01ms !important;
		-webkit-animation-iteration-count: 1 !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}

@media screen and (max-width: 768px) {
	/* Hide scrollbar on small screens */
	body::-webkit-scrollbar {
		display: none;
	}
	/* Chrome, Safari and Opera */
	body {
		overflow-x: hidden;
		/* IE and Edge */
		-ms-overflow-style: none;
		/* Firefox */
		scrollbar-width: none;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

/* reset styles for react-code-block wrapper and 'copy' button */

div.bxMCny {
	border-radius: var(--corner-radius-sm);
}
button.iTyqYj,
button.hafAWl {
	padding: var(--md);
	max-height: unset;
	max-width: unset;
	background: unset;
	background-color: var(--black-50);
	-webkit-backdrop-filter: blur(2px);
	backdrop-filter: blur(2px);
	box-shadow: unset;
}

button.iTyqYj:hover,
button.hafAWl:hover {
	background-color: var(--white-50);
}

button.iTyqYj .icon,
button.hafAWl .icon {
	width: 100%;
	height: 100%;
}

button.iTyqYj .icon:hover,
button.hafAWl .icon:hover {
	opacity: unset;
}
