/* body * { border: var(--dash) red} */

#glass {
    --radius: var(--xl);
    border-radius: 0;
    min-height: 75vh;
    width: var(--content-width);
    max-width: 1200px;
    margin: 0px auto 0 auto;
    box-shadow: var(--shadow-far);
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    #glass {
        backdrop-filter: var(--blur);
        -webkit-backdrop-filter: var(--blur);
        background-color: var(--site-bg-50);
    }
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    #glass {
        background-color: var(--site-bg-80);
    }
}

.product section {
    padding: var(--xl3) var(--xl);
}

.product section h1,
.product section h4 {
    color: var(--text-col);
}

.product section h2 {
    text-align: center;
    margin: 0;
}

.welcome {
    padding-top: var(--lg);
}

.welcome > h1 {
    text-align: center;
}

.welcome > .product-hero > p {
    margin: 0;
}

/* ------------ DUAL BARRIER COATING SECTION ------------ */

.dbc {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(4, auto);
    grid-template-areas: 'hl hl hl hl hl hl hl' 'p p p vid vid vid vid' '. wot wot wt wt wt .' 'img img img img img img img';
    place-content: center;
    place-items: center;
}

#dbc-headline {
    grid-area: hl;
    margin: 0 auto var(--xl2) auto;
}

#dbc-blurb {
    grid-area: p;
    margin: 0 40px 0 0;
    place-self: flex-start;
}

#dbc-blurb > img {
    margin-bottom: var(--lg);
    width: 340px;
}

#dbc-img {
    grid-area: img;
}

#dbc-without-text {
    grid-area: wot;
}

#dbc-with-text {
    grid-area: wt;
}

.dbc-bracket-text {
    color: var(--text-col);
    font-size: larger;
    text-align: center;
}

.dbc-video-wrapper {
    grid-area: vid;
    width: 100%;
}

#dbc-video {
    width: 100%;
    height: 400px;
    margin: 0 0 var(--xl) 0;
    box-shadow: var(--shadow-far);
    border-radius: var(--corner-radius-lg);
    border: none;
}

/* ------------ FEATURES SECTION ------------ */

.features {
    display: grid;
    gap: var(--grid-gap);
    align-content: center;
}

.features-columns {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: var(--grid-gap);
    align-items: center;
}

.features-left-column {
    display: grid;
    align-content: center;
    gap: 20px;
}

.features-left-column h3 {
    text-align: center;
}

/*
.slider-wrapper {
	position: relative;
}

#slider-image {
	margin: auto;
	width: auto;
	height: 100%;
	max-height: 520px;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: 100% 0;
	object-position: 100% 0;
}

#prev-img,
#next-img {
	position: absolute;
	top: 50%;
	width: 50px;
	height: 50px;
	background-color: var(--white-50);
	background-image: url('/icons/right-arrow.svg');
	background-size: 65%;
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 100%;
	opacity: 0.5;
}

#prev-img {
	left: 20px;
	transform: rotate(180deg);
}
#next-img {
	right: 20px;
}

#prev-img:hover,
#next-img:hover {
	cursor: pointer;
	opacity: 1;
} */

.features-boxes-wrapper {
    display: grid;
    gap: var(--grid-gap);
    align-content: flex-end;
}

.feature-box {
    display: grid;
    grid-template-columns: 1fr 8fr;
    gap: var(--grid-gap);
    grid-template-rows: auto;
    align-items: start;
}

.feature-box .icon {
    -o-object-position: top;
    object-position: top;
}

.feature-box h4 {
    text-align: left;
    margin-top: 0;
}

/* ------------ BENEFITS SECTION ------------ */

.benefits {
    display: grid;
    grid-template-rows: auto auto;
    align-content: center;
    gap: var(--xl);
}

.benefits-top-row {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--grid-gap);
    align-items: center;
    margin-bottom: var(--lg);
}

.benefit-boxes-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--grid-gap);
    justify-content: center;
    justify-items: center;
}

.benefit-text > h4 {
    text-align: center;
}

.benefit-box {
    display: grid;
    grid-template-rows: 100px auto auto;
}

/* ------------ WARRANTY SECTION ------------ */

.warranty-wrapper {
    display: grid;
    grid-template-columns: 1fr 5fr;
    padding: var(--std) var(--lg);
    gap: 0;
    place-content: center;
    place-items: center;
}

.warranty-wrapper h4 {
    text-align: left;
}

.warranty-left > img {
    height: 100%;
    width: 150px;
}

.warranty-right {
    margin: 0 var(--xl);
}

/* ------------ MOBILE ------------ */

@media screen and (max-width: 768px) {
    .product section {
        padding: var(--xl) var(--lg);
        overflow-x: hidden;
    }

    .product section h4 {
        margin: var(--md) auto;
        margin-bottom: var(--sm);
    }

    .product section p {
        margin-top: 0;
    }

    .product section h1,
    .product section h2,
    .product section h3 {
        margin: 0;
    }

    .product section h2 {
        font-size: 2.2em;
    }

    main > .product section > .right-panel {
        padding-top: 0;
    }

    .product section.product-hero {
        grid-template-areas: 'left left' 'right right';
        gap: 0;
    }

    .product-hero .right-panel a {
        margin: auto;
    }

    .product-hero p {
        margin: auto;
        margin-top: 15px;
    }

    .dbc {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(5, auto);
        grid-template-areas: 'hl hl hl hl' 'p p p p' 'vid vid vid vid' 'wot wot wt wt' 'img img img img';
        padding-bottom: var(--xl3);
    }

    #dbc-headline {
        margin: 0 auto var(--lg) auto;
    }

    #dbc-blurb {
        grid-area: p;
        margin: var(--lg) 0;
        place-self: flex-start;
    }

    #dbc-blurb > img {
        margin-bottom: var(--lg);
    }

    #dbc-video {
        height: 400px;
    }

    .features {
        padding-left: var(--lg);
    }

    .features-columns {
        grid-template-columns: 1fr;
    }

    .features-left-column {
        grid-template-rows: auto auto;
        align-content: center;
        gap: 20px;
    }

    .features-boxes-wrapper {
        justify-content: center;
    }

    .product-img {
        height: 170px;
        -o-object-fit: contain;
        object-fit: contain;
        -o-object-position: 50% 50%;
        object-position: 50% 50%;
    }

    .benefit-boxes-wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    .benefits-top-row {
        display: grid;
        grid-template-columns: 1fr;
        gap: var(--grid-gap);
        align-items: center;
    }

    .benefit-box {
        grid-template-columns: 1fr;
        grid-template-rows: 80px auto;
        gap: 0;
    }

    .benefit-text > h4 {
        margin-top: var(--sm);
        text-align: center;
    }

    #prev-img {
        left: -20px;
        top: unset;
        bottom: 0;
    }
    #next-img {
        right: -20px;
        top: unset;
        bottom: 0;
    }

    .form-fields-wrapper {
        padding: var(--lg);
        display: grid;
        grid-template-columns: 1fr;
        gap: var(--grid-gap);
    }

    .warranty-wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: 150px auto;
    }

    .warranty-right {
        margin: 0 var(--lg);
    }
}

@media screen and (max-width: 480px) {
    #dbc-video {
        height: 200px;
    }

    #dbc-img {
        transform: translate(28px, 50px) scale(1.8);
    }

    .dbc-bracket-text {
        color: var(--text-col);
        font-size: var(--md);
        text-align: center;
        margin-bottom: 0;
        line-height: var(--lg);
    }

    .warranty-right {
        margin: 0 var(--md);
    }
}
