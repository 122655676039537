footer {
	--grid-gap: var(--xl);
	display: grid;
	padding: var(--xl) var(--md);
	align-content: flex-end;
	justify-content: center;
	background-color: var(--site-bg-50);
	height: auto;
	gap: calc(var(--grid-gap) * 1.3);
	padding-bottom: 20px;
}

footer p {
	text-align: center;
	font-family: var(--condensed-font);
}

footer .logo {
	z-index: unset;
	align-self: center;
}

.social-icons-row {
	display: flex;
	height: 40px;
	justify-content: center;
}

.social-icons-row > a {
	margin: 0 var(--spc-sm-w);
}

.footer-links-row {
	padding-bottom: 20px;
	/*border-top: var(--solid) var(--text-col-50);*/
	text-align: center;
}

.footer-links-row a,
.footer-links-row .link {
	margin: 10px 20px;
	text-decoration: none;
	font-size: smaller;
	display: inline-block;
}

#copyright {
	font-family: var(--condensed-font);
	text-align: center;
	margin: 0;
	font-size: smaller;
}

/* disable transitions in safari */

/* ios */

@supports (-webkit-touch-callout: none) {
	footer,
	.logo {
		transition: none;
	}
}

/* header nav moves to bottom of viewport */
@media screen and (max-width: 900px) {
	footer {
		padding-bottom: var(--xl3);
		margin-bottom: var(--xl);
	}
}

@media screen and (max-width: 768px) {
	footer {
		--grid-gap: var(--xl);
	}

	.footer-links-row {
		padding-bottom: 10px;
		/*border-top: var(--solid) var(--text-col-50);*/
		text-align: center;
	}

	.footer-links-row a {
		display: block;
		margin: 10px;
	}

	p#copyright {
		font-size: var(--std);
	}
}

@media screen and (max-width: 560px) {
	.social-icons-row {
		grid-template-rows: 30px;
		/* grid-template-columns: repeat(9, 40px); */
	}
}

@media screen and (max-width: 360px) {
	.social-icons-row {
		grid-template-rows: 20px;
		/* grid-template-columns: repeat(9, 30px); */
	}
}
