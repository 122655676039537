:root {
	/* COLOR PALETTE */
	/* HUE, SATURATION, and LIGHTNESS color settings */

	/* HUE */
	/* ENSO: #ea3927, h=6 s=82 l=54 */
	--red-hue: 355;

	/* ENSO: #fd952e, h=30 s=98 l=59 */
	--orange-hue: 16;

	/* ENSO: #f5e33c, h=54 s=90 l=60 */
	--yellow-hue: 54;

	/* ENSO: #4dbe3a, h=111 s=53 l=49 */
	--green-hue: 111;

	/* ENSO: #408dff, h=216 s=100 l=63 */
	--blue-hue: 216;

	/* ENSO: #9127df, h=275 s=74 l=51 */
	--purple-hue: 275;

	/* ENSO: #041757, h=226 s=91 l=18 */
	--blue-dp-hue: 226;

	--white-hue: 0;
	--black-hue: 0;

	--cooling-hue: 200;
	--heating-hue: 12;

	/* SATURATION */
	--max-saturation: 100%;
	--default-saturation: 84%;
	--muted-saturation: 70%;
	--min-saturation: 0%;
	/* acceptions for greens */
	--green-default-saturation: 70%;
	--green-lt-saturation: 60%;

	/* LIGHTNESS */
	--max-lightness: 100%;
	--lighter: 75%;
	--default-lightness: 52%;
	--darker: 35%;
	--deep: 30%;
	--deeper: 20%;
	--min-lightness: 0%;
	/* acceptions for neutrals */
	--off-white-lightness: 98%;
	--light-gray-lightness: 74%;
	--gray-lightness: 46%;
	--dark-gray-lightness: 20%;
	/* acceptions for greens */
	--green-default-lightness: 37%;
	--green-lt-lightness: 70%;
	--green-dk-lightness: 27%;
}
