.color-grid-wrapper {
	--gap: 8px;
	padding: var(--spc-lg);
	padding-top: var(--header-ht);
	min-height: 50vh;
	max-width: var(--content-width);
	margin: auto;
}

.color-grid-wrapper h1 {
	margin-top: 0;
}

.color-grid {
	margin: var(--spc-xl2-h) auto;
}

.color-row {
	position: relative;
	display: grid;
	grid-template-columns: var(--spc-lg-w) 1fr;
	margin-bottom: var(--spc-md-h);

	align-items: center;
}

.color-title-wrapper {
	position: relative;
	border-radius: var(--spc-lg-w);
	overflow: hidden;
	height: 100%;
	min-height: 100px;
	box-shadow: var(--shadow-far);
}

.no-shadow {
	box-shadow: none;
}

.color-title {
	position: absolute;
	margin: 0;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(270deg);
	text-align: center;
	white-space: nowrap;
}

.swatch-row {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
}

.swatch-row > p {
	display: flex;
	place-self: center;
	margin: 0;
}

.color-swatch {
	display: flex;
	place-content: center;
	place-items: center;
	/* border-radius: 4px; */
	cursor: pointer;
	padding: var(--spc-md);
	height: var(--spc-xl2-w);
}

.color-swatch.short {
	padding: var(--spc-xs);
	height: var(--spc-xl-w);
}

.hex-label {
	text-align: center;
	margin: 0;
	visibility: hidden;
	font-size: small;
	/* padding-top: var(--spc-sm-h); */
}

.color-swatch:hover .hex-label {
	visibility: visible;
}
