input[type='text']:focus::-webkit-input-placeholder, input[type='name']:focus::-webkit-input-placeholder, input[type='email']:focus::-webkit-input-placeholder, input[type='tel']:focus::-webkit-input-placeholder {
    color: var(--text-col-50);
    text-align: right;
    font-size: smaller;
}
input[type='text']:focus::-moz-placeholder, input[type='name']:focus::-moz-placeholder, input[type='email']:focus::-moz-placeholder, input[type='tel']:focus::-moz-placeholder {
    color: var(--text-col-50);
    text-align: right;
    font-size: smaller;
}
input[type='text']:focus:-ms-input-placeholder, input[type='name']:focus:-ms-input-placeholder, input[type='email']:focus:-ms-input-placeholder, input[type='tel']:focus:-ms-input-placeholder {
    color: var(--text-col-50);
    text-align: right;
    font-size: smaller;
}
input[type='text']:focus::-ms-input-placeholder, input[type='name']:focus::-ms-input-placeholder, input[type='email']:focus::-ms-input-placeholder, input[type='tel']:focus::-ms-input-placeholder {
    color: var(--text-col-50);
    text-align: right;
    font-size: smaller;
}
input[type='text']:focus::placeholder,
input[type='name']:focus::placeholder,
input[type='email']:focus::placeholder,
input[type='tel']:focus::placeholder {
    color: var(--text-col-50);
    text-align: right;
    font-size: smaller;
}
