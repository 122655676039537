:root {
    /* METUS COLOR PALETTE */

    /* dynamic color variables calculated based on current theme/color mode */
    --input-bg: var(--bg);
    --error-col: var(--red-sec);
    --hover-col: var(--accent-50);
    --success-col: var(--green-sec);
    --border-col: var(--text-col-10);
    --disabled-col: var(--hover-col);
    --accent-col-light-mode: var(--blue-dk);
    --accent-col-dark-mode: var(--blue);
    --term-col-light-mode: var(--purple-dk);
    --term-col-dark-mode: var(--purple-sec);

    --accent-lt: color-mix(in srgb, var(--accent) 70%, var(--white));
    --accent-dk: color-mix(in srgb, var(--accent) 70%, var(--black));
    
    --accent-10: color-mix(in srgb, var(--accent) 10%, transparent);
    --accent-20: color-mix(in srgb, var(--accent) 20%, transparent);
    --accent-30: color-mix(in srgb, var(--accent) 30%, transparent);
    --accent-40: color-mix(in srgb, var(--accent) 40%, transparent);
    --accent-50: color-mix(in srgb, var(--accent) 50%, transparent);
    --accent-60: color-mix(in srgb, var(--accent) 60%, transparent);
    --accent-70: color-mix(in srgb, var(--accent) 70%, transparent);
    --accent-80: color-mix(in srgb, var(--accent) 80%, transparent);
    --accent-90: color-mix(in srgb, var(--accent) 90%, transparent);
    --site-bg-10: color-mix(in srgb, var(--site-bg) 10%, transparent);
    --site-bg-20: color-mix(in srgb, var(--site-bg) 20%, transparent);
    --site-bg-30: color-mix(in srgb, var(--site-bg) 30%, transparent);
    --site-bg-40: color-mix(in srgb, var(--site-bg) 40%, transparent);
    --site-bg-50: color-mix(in srgb, var(--site-bg) 50%, transparent);
    --site-bg-60: color-mix(in srgb, var(--site-bg) 60%, transparent);
    --site-bg-70: color-mix(in srgb, var(--site-bg) 70%, transparent);
    --site-bg-80: color-mix(in srgb, var(--site-bg) 80%, transparent);
    --site-bg-90: color-mix(in srgb, var(--site-bg) 90%, transparent);
    --bg-10: color-mix(in srgb, var(--bg) 10%, transparent);
    --bg-20: color-mix(in srgb, var(--bg) 20%, transparent);
    --bg-30: color-mix(in srgb, var(--bg) 30%, transparent);
    --bg-40: color-mix(in srgb, var(--bg) 40%, transparent);
    --bg-50: color-mix(in srgb, var(--bg) 50%, transparent);
    --bg-60: color-mix(in srgb, var(--bg) 60%, transparent);
    --bg-70: color-mix(in srgb, var(--bg) 70%, transparent);
    --bg-80: color-mix(in srgb, var(--bg) 80%, transparent);
    --bg-90: color-mix(in srgb, var(--bg) 90%, transparent);
    --text-col-10: color-mix(in srgb, var(--text-col) 10%, transparent);
    --text-col-20: color-mix(in srgb, var(--text-col) 20%, transparent);
    --text-col-30: color-mix(in srgb, var(--text-col) 30%, transparent);
    --text-col-40: color-mix(in srgb, var(--text-col) 40%, transparent);
    --text-col-50: color-mix(in srgb, var(--text-col) 50%, transparent);
    --text-col-60: color-mix(in srgb, var(--text-col) 60%, transparent);
    --text-col-70: color-mix(in srgb, var(--text-col) 70%, transparent);
    --text-col-80: color-mix(in srgb, var(--text-col) 80%, transparent);
    --text-col-90: color-mix(in srgb, var(--text-col) 90%, transparent);

    /* color filters for icons */
    --white-filter: invert(100%) sepia(10%) saturate(0%) hue-rotate(184deg) brightness(110%) contrast(100%);
    --blue-filter: invert(46%) sepia(96%) saturate(2002%) hue-rotate(202deg) brightness(98%) contrast(92%);
    --blue-dk-filter: invert(25%) sepia(85%) saturate(3012%) hue-rotate(220deg) brightness(92%) contrast(91%);
    --red-filter: invert(46%) sepia(95%) saturate(3500%) hue-rotate(334deg) brightness(97%) contrast(98%);
    --orange-filter: invert(55%) sepia(56%) saturate(673%) hue-rotate(330deg) brightness(95%) contrast(103%);
    --yellow-filter: invert(90%) sepia(68%) saturate(6826%) hue-rotate(327deg) brightness(110%) contrast(92%);
    --green-filter: invert(84%) sepia(15%) saturate(2964%) hue-rotate(53deg) brightness(84%) contrast(82%);
    --cool-blue-filter: invert(69%) sepia(82%) saturate(1350%) hue-rotate(162deg) brightness(93%) contrast(98%);
    --purple-filter: invert(37%) sepia(36%) saturate(6067%) hue-rotate(256deg) brightness(87%) contrast(108%);

    /* static co-brand colors */
    --trane-orange: #ff2b00;
    --trane-orange-50: #ff2b0080;
    --trane-orange-dk: #a0321c;
    --trane-orange-dk-50: #a0321c80;

    --as-blue: #0069b4;
    --as-blue-50: #0069b480;
    --as-blue-dk: #00395f;
    --as-blue-dk-50: #00395f80;

	--dispatch-gray: #011c2c;
	--dispatch-green: #0fc132

    /* HUE, SATURATION, and LIGHTNESS color settings */

    /* -------------------------- HUE -------------------------- */
    /* ENSO: #ea3927, h=6 s=82 l=54 */;
    --red-hue: 6;

    /* ENSO: #fd952e, h=30 s=98 l=59 */
    --orange-hue: 22;

    /* ENSO: #f5e33c, h=54 s=90 l=60 */
    --yellow-hue: 54;

    /* ENSO: #4dbe3a, h=111 s=53 l=49 */
    --green-hue: 111;

    /* ENSO: none */
    --cool-blue-hue: 195;

    /* ENSO: #408dff, h=216 s=100 l=63 */
    --blue-hue: 216;

    /* ENSO: #9127df, h=275 s=74 l=51 */
    --purple-hue: 275;

    /* ENSO: #041757, h=226 s=91 l=18 */
    --blue-dp-hue: var(--blue-hue);

    /* #071426 */
    --neutral-hue: var(--blue-hue);
    --black-hue: 0;
    
    /* -------------------------- SATURATION -------------------------- */
    --default-saturation: 90%;
    --max-saturation: 100%;
    --muted-saturation: 70%;
    --min-saturation: 15%;
    /* acceptions for neutrals */
    --dark-gray-saturation: 50%;
    /* acceptions for greens */
    --green-default-saturation: 60%;
    --green-lt-saturation: 60%;

    /* -------------------------- LIGHTNESS -------------------------- */
    --default-lightness: 60%;
    --secondary-lightness: 70%;
    --light: 85%;
    --dark: 45%;
    --deep: 20%;
    --min-lightness: 0%;
    --max-lightness: 100%;
    /* acceptions for neutrals */
    --off-white-lightness: 97%;
    --light-gray-lightness: 80%;
    --gray-lightness: 46%;
    --dark-gray-lightness: 15%;
    --deep-gray-lightness: 7%;
    /* acceptions for greens */
    --green-default-lightness: 50%;
    --green-dk-lightness: 27%;

    /* Abbreviated variables for HSLA */
    /* -------------------------- HUE -------------------------- */
    --h-n: var(--neutral-hue);
    --h-bk: var(--black-hue);
    --h-r: var(--red-hue);
    --h-o: var(--orange-hue);
    --h-y: var(--yellow-hue);
    --h-g: var(--green-hue);
    --h-bl: var(--blue-hue);
    --h-p: var(--purple-hue);
    --h-cl: var(--cool-blue-hue);

    /* -------------------------- SATURATION -------------------------- */
    --s-max: var(--max-saturation);
    --s-def: var(--default-saturation);
    --s-mtd: var(--muted-saturation);
    --s-min: var(--min-saturation);
    --s-dk-gray: var(--dark-gray-saturation);
    --s-gr-def: var(--green-default-saturation);
    --s-gr-lt: var(--green-lt-saturation);

    /* -------------------------- LIGHTNESS -------------------------- */
    --l-def: var(--default-lightness);
    --l-sec: var(--secondary-lightness);
    --l-max: var(--max-lightness);
    --l-min: var(--min-lightness);
    --l-off: var(--off-white-lightness);
    --l-lt-gray: var(--light-gray-lightness);
    --l-gray: var(--gray-lightness);
    --l-dk-gray: var(--dark-gray-lightness);
    --l-dp-gray: var(--deep-gray-lightness);
    --l-lt: var(--light);
    --l-dk: var(--dark);
    --l-dp: var(--deep);

    --l-grn-def: var(--green-default-lightness);
    --l-grn-dk: var(--green-dk-lightness);

    /* COLOR DEFINITIONS WITH ALPHA */
    --white: hsla(var(--h-n), var(--s-min), var(--l-max), 1);
    --white-10: hsla(var(--h-n), var(--s-min), var(--l-max), 0.1);
    --white-20: hsla(var(--h-n), var(--s-min), var(--l-max), 0.2);
    --white-30: hsla(var(--h-n), var(--s-min), var(--l-max), 0.3);
    --white-40: hsla(var(--h-n), var(--s-min), var(--l-max), 0.4);
    --white-50: hsla(var(--h-n), var(--s-min), var(--l-max), 0.5);
    --white-60: hsla(var(--h-n), var(--s-min), var(--l-max), 0.6);
    --white-70: hsla(var(--h-n), var(--s-min), var(--l-max), 0.7);
    --white-80: hsla(var(--h-n), var(--s-min), var(--l-max), 0.8);
    --white-90: hsla(var(--h-n), var(--s-min), var(--l-max), 0.9);

    --off-white: hsla(var(--h-n), var(--s-min), var(--l-off), 1);
    --off-white-10: hsla(var(--h-n), var(--s-min), var(--l-off), 0.1);
    --off-white-20: hsla(var(--h-n), var(--s-min), var(--l-off), 0.2);
    --off-white-30: hsla(var(--h-n), var(--s-min), var(--l-off), 0.3);
    --off-white-40: hsla(var(--h-n), var(--s-min), var(--l-off), 0.4);
    --off-white-50: hsla(var(--h-n), var(--s-min), var(--l-off), 0.5);
    --off-white-60: hsla(var(--h-n), var(--s-min), var(--l-off), 0.6);
    --off-white-70: hsla(var(--h-n), var(--s-min), var(--l-off), 0.7);
    --off-white-80: hsla(var(--h-n), var(--s-min), var(--l-off), 0.8);
    --off-white-90: hsla(var(--h-n), var(--s-min), var(--l-off), 0.9);

    --neutral-lt: var(--off-white);
    --neutral-lt-10: var(--off-white-10);
    --neutral-lt-20: var(--off-white-20);
    --neutral-lt-30: var(--off-white-30);
    --neutral-lt-40: var(--off-white-40);
    --neutral-lt-50: var(--off-white-50);
    --neutral-lt-60: var(--off-white-60);
    --neutral-lt-70: var(--off-white-70);
    --neutral-lt-80: var(--off-white-80);
    --neutral-lt-90: var(--off-white-90);

    --gray-lt: hsla(var(--h-n), var(--s-min), var(--l-lt-gray), 1);
    --gray-lt-10: hsla(var(--h-n), var(--s-min), var(--l-lt-gray), 0.1);
    --gray-lt-20: hsla(var(--h-n), var(--s-min), var(--l-lt-gray), 0.2);
    --gray-lt-30: hsla(var(--h-n), var(--s-min), var(--l-lt-gray), 0.3);
    --gray-lt-40: hsla(var(--h-n), var(--s-min), var(--l-lt-gray), 0.4);
    --gray-lt-50: hsla(var(--h-n), var(--s-min), var(--l-lt-gray), 0.5);
    --gray-lt-60: hsla(var(--h-n), var(--s-min), var(--l-lt-gray), 0.6);
    --gray-lt-70: hsla(var(--h-n), var(--s-min), var(--l-lt-gray), 0.7);
    --gray-lt-80: hsla(var(--h-n), var(--s-min), var(--l-lt-gray), 0.8);
    --gray-lt-90: hsla(var(--h-n), var(--s-min), var(--l-lt-gray), 0.9);

    --neutral-sec: var(--gray-lt);
    --neutral-sec-10: var(--gray-lt-10);
    --neutral-sec-20: var(--gray-lt-20);
    --neutral-sec-30: var(--gray-lt-30);
    --neutral-sec-40: var(--gray-lt-40);
    --neutral-sec-50: var(--gray-lt-50);
    --neutral-sec-60: var(--gray-lt-60);
    --neutral-sec-70: var(--gray-lt-70);
    --neutral-sec-80: var(--gray-lt-80);
    --neutral-sec-90: var(--gray-lt-90);

    --gray: hsla(var(--h-n), var(--s-min), var(--l-gray), 1);
    --gray-10: hsla(var(--h-n), var(--s-min), var(--l-gray), 0.1);
    --gray-20: hsla(var(--h-n), var(--s-min), var(--l-gray), 0.2);
    --gray-30: hsla(var(--h-n), var(--s-min), var(--l-gray), 0.3);
    --gray-40: hsla(var(--h-n), var(--s-min), var(--l-gray), 0.4);
    --gray-50: hsla(var(--h-n), var(--s-min), var(--l-gray), 0.5);
    --gray-60: hsla(var(--h-n), var(--s-min), var(--l-gray), 0.6);
    --gray-70: hsla(var(--h-n), var(--s-min), var(--l-gray), 0.7);
    --gray-80: hsla(var(--h-n), var(--s-min), var(--l-gray), 0.8);
    --gray-90: hsla(var(--h-n), var(--s-min), var(--l-gray), 0.9);

    --neutral: var(--gray);
    --neutral-10: var(--gray-10);
    --neutral-20: var(--gray-20);
    --neutral-30: var(--gray-30);
    --neutral-40: var(--gray-40);
    --neutral-50: var(--gray-50);
    --neutral-60: var(--gray-60);
    --neutral-70: var(--gray-70);
    --neutral-80: var(--gray-80);
    --neutral-90: var(--gray-90);

    --gray-dk: hsla(var(--h-n), var(--s-dk-gray), var(--l-dk-gray), 1);
    --gray-dk-10: hsla(var(--h-n), var(--s-dk-gray), var(--l-dk-gray), 0.1);
    --gray-dk-20: hsla(var(--h-n), var(--s-dk-gray), var(--l-dk-gray), 0.2);
    --gray-dk-30: hsla(var(--h-n), var(--s-dk-gray), var(--l-dk-gray), 0.3);
    --gray-dk-40: hsla(var(--h-n), var(--s-dk-gray), var(--l-dk-gray), 0.4);
    --gray-dk-50: hsla(var(--h-n), var(--s-dk-gray), var(--l-dk-gray), 0.5);
    --gray-dk-60: hsla(var(--h-n), var(--s-dk-gray), var(--l-dk-gray), 0.6);
    --gray-dk-70: hsla(var(--h-n), var(--s-dk-gray), var(--l-dk-gray), 0.7);
    --gray-dk-80: hsla(var(--h-n), var(--s-dk-gray), var(--l-dk-gray), 0.8);
    --gray-dk-90: hsla(var(--h-n), var(--s-dk-gray), var(--l-dk-gray), 0.9);

    --neutral-dk: var(--gray-dk);
    --neutral-dk-10: var(--gray-dk-10);
    --neutral-dk-20: var(--gray-dk-20);
    --neutral-dk-30: var(--gray-dk-30);
    --neutral-dk-40: var(--gray-dk-40);
    --neutral-dk-50: var(--gray-dk-50);
    --neutral-dk-60: var(--gray-dk-60);
    --neutral-dk-70: var(--gray-dk-70);
    --neutral-dk-80: var(--gray-dk-80);
    --neutral-dk-90: var(--gray-dk-90);

    --gray-dp: hsla(var(--h-n), var(--s-mtd), var(--l-dp-gray), 1);
    --gray-dp-10: hsla(var(--h-n), var(--s-mtd), var(--l-dp-gray), 0.1);
    --gray-dp-20: hsla(var(--h-n), var(--s-mtd), var(--l-dp-gray), 0.2);
    --gray-dp-30: hsla(var(--h-n), var(--s-mtd), var(--l-dp-gray), 0.3);
    --gray-dp-40: hsla(var(--h-n), var(--s-mtd), var(--l-dp-gray), 0.4);
    --gray-dp-50: hsla(var(--h-n), var(--s-mtd), var(--l-dp-gray), 0.5);
    --gray-dp-60: hsla(var(--h-n), var(--s-mtd), var(--l-dp-gray), 0.6);
    --gray-dp-70: hsla(var(--h-n), var(--s-mtd), var(--l-dp-gray), 0.7);
    --gray-dp-80: hsla(var(--h-n), var(--s-mtd), var(--l-dp-gray), 0.8);
    --gray-dp-90: hsla(var(--h-n), var(--s-mtd), var(--l-dp-gray), 0.9);

    --neutral-dp: var(--gray-dp);
    --neutral-dp-10: var(--gray-dp-10);
    --neutral-dp-20: var(--gray-dp-20);
    --neutral-dp-30: var(--gray-dp-30);
    --neutral-dp-40: var(--gray-dp-40);
    --neutral-dp-50: var(--gray-dp-50);
    --neutral-dp-60: var(--gray-dp-60);
    --neutral-dp-70: var(--gray-dp-70);
    --neutral-dp-80: var(--gray-dp-80);
    --neutral-dp-90: var(--gray-dp-90);

    --black: hsla(var(--h-bk), var(--s-min), var(--l-min), 1);
    --black-10: hsla(var(--h-bk), var(--s-min), var(--l-min), 0.1);
    --black-20: hsla(var(--h-bk), var(--s-min), var(--l-min), 0.2);
    --black-30: hsla(var(--h-bk), var(--s-min), var(--l-min), 0.3);
    --black-40: hsla(var(--h-bk), var(--s-min), var(--l-min), 0.4);
    --black-50: hsla(var(--h-bk), var(--s-min), var(--l-min), 0.5);
    --black-60: hsla(var(--h-bk), var(--s-min), var(--l-min), 0.6);
    --black-70: hsla(var(--h-bk), var(--s-min), var(--l-min), 0.7);
    --black-80: hsla(var(--h-bk), var(--s-min), var(--l-min), 0.8);
    --black-90: hsla(var(--h-bk), var(--s-min), var(--l-min), 0.9);

    --red: hsla(var(--h-r), var(--s-def), var(--l-def), 1);
    --red-10: hsla(var(--h-r), var(--s-def), var(--l-def), 0.1);
    --red-20: hsla(var(--h-r), var(--s-def), var(--l-def), 0.2);
    --red-30: hsla(var(--h-r), var(--s-def), var(--l-def), 0.3);
    --red-40: hsla(var(--h-r), var(--s-def), var(--l-def), 0.4);
    --red-50: hsla(var(--h-r), var(--s-def), var(--l-def), 0.5);
    --red-60: hsla(var(--h-r), var(--s-def), var(--l-def), 0.6);
    --red-70: hsla(var(--h-r), var(--s-def), var(--l-def), 0.7);
    --red-80: hsla(var(--h-r), var(--s-def), var(--l-def), 0.8);
    --red-90: hsla(var(--h-r), var(--s-def), var(--l-def), 0.9);

    --red-sec: hsla(var(--h-r), var(--s-def), var(--l-sec), 1);
    --red-sec-10: hsla(var(--h-r), var(--s-def), var(--l-sec), 0.1);
    --red-sec-20: hsla(var(--h-r), var(--s-def), var(--l-sec), 0.2);
    --red-sec-30: hsla(var(--h-r), var(--s-def), var(--l-sec), 0.3);
    --red-sec-40: hsla(var(--h-r), var(--s-def), var(--l-sec), 0.4);
    --red-sec-50: hsla(var(--h-r), var(--s-def), var(--l-sec), 0.5);
    --red-sec-60: hsla(var(--h-r), var(--s-def), var(--l-sec), 0.6);
    --red-sec-70: hsla(var(--h-r), var(--s-def), var(--l-sec), 0.7);
    --red-sec-80: hsla(var(--h-r), var(--s-def), var(--l-sec), 0.8);
    --red-sec-90: hsla(var(--h-r), var(--s-def), var(--l-sec), 0.9);

    --red-lt: hsla(var(--h-r), var(--s-def), var(--l-lt), 1);
    --red-lt-10: hsla(var(--h-r), var(--s-def), var(--l-lt), 0.1);
    --red-lt-20: hsla(var(--h-r), var(--s-def), var(--l-lt), 0.2);
    --red-lt-30: hsla(var(--h-r), var(--s-def), var(--l-lt), 0.3);
    --red-lt-40: hsla(var(--h-r), var(--s-def), var(--l-lt), 0.4);
    --red-lt-50: hsla(var(--h-r), var(--s-def), var(--l-lt), 0.5);
    --red-lt-60: hsla(var(--h-r), var(--s-def), var(--l-lt), 0.6);
    --red-lt-70: hsla(var(--h-r), var(--s-def), var(--l-lt), 0.7);
    --red-lt-80: hsla(var(--h-r), var(--s-def), var(--l-lt), 0.8);
    --red-lt-90: hsla(var(--h-r), var(--s-def), var(--l-lt), 0.9);

    --red-dk: hsla(var(--h-r), var(--s-def), var(--l-dk), 1);
    --red-dk-10: hsla(var(--h-r), var(--s-def), var(--l-dk), 0.1);
    --red-dk-20: hsla(var(--h-r), var(--s-def), var(--l-dk), 0.2);
    --red-dk-30: hsla(var(--h-r), var(--s-def), var(--l-dk), 0.3);
    --red-dk-40: hsla(var(--h-r), var(--s-def), var(--l-dk), 0.4);
    --red-dk-50: hsla(var(--h-r), var(--s-def), var(--l-dk), 0.5);
    --red-dk-60: hsla(var(--h-r), var(--s-def), var(--l-dk), 0.6);
    --red-dk-70: hsla(var(--h-r), var(--s-def), var(--l-dk), 0.7);
    --red-dk-80: hsla(var(--h-r), var(--s-def), var(--l-dk), 0.8);
    --red-dk-90: hsla(var(--h-r), var(--s-def), var(--l-dk), 0.9);

    --red-dp: hsla(var(--h-r), var(--s-def), var(--l-dp), 1);
    --red-dp-10: hsla(var(--h-r), var(--s-def), var(--l-dp), 0.1);
    --red-dp-20: hsla(var(--h-r), var(--s-def), var(--l-dp), 0.2);
    --red-dp-30: hsla(var(--h-r), var(--s-def), var(--l-dp), 0.3);
    --red-dp-40: hsla(var(--h-r), var(--s-def), var(--l-dp), 0.4);
    --red-dp-50: hsla(var(--h-r), var(--s-def), var(--l-dp), 0.5);
    --red-dp-60: hsla(var(--h-r), var(--s-def), var(--l-dp), 0.6);
    --red-dp-70: hsla(var(--h-r), var(--s-def), var(--l-dp), 0.7);
    --red-dp-80: hsla(var(--h-r), var(--s-def), var(--l-dp), 0.8);
    --red-dp-90: hsla(var(--h-r), var(--s-def), var(--l-dp), 0.9);

    --orange: hsla(var(--h-o), var(--s-def), var(--l-def), 1);
    --orange-10: hsla(var(--h-o), var(--s-def), var(--l-def), 0.1);
    --orange-20: hsla(var(--h-o), var(--s-def), var(--l-def), 0.2);
    --orange-30: hsla(var(--h-o), var(--s-def), var(--l-def), 0.3);
    --orange-40: hsla(var(--h-o), var(--s-def), var(--l-def), 0.4);
    --orange-50: hsla(var(--h-o), var(--s-def), var(--l-def), 0.5);
    --orange-60: hsla(var(--h-o), var(--s-def), var(--l-def), 0.6);
    --orange-70: hsla(var(--h-o), var(--s-def), var(--l-def), 0.7);
    --orange-80: hsla(var(--h-o), var(--s-def), var(--l-def), 0.8);
    --orange-90: hsla(var(--h-o), var(--s-def), var(--l-def), 0.9);

    --orange-sec: hsla(var(--h-o), var(--s-def), var(--l-sec), 1);
    --orange-sec-10: hsla(var(--h-o), var(--s-def), var(--l-sec), 0.1);
    --orange-sec-20: hsla(var(--h-o), var(--s-def), var(--l-sec), 0.2);
    --orange-sec-30: hsla(var(--h-o), var(--s-def), var(--l-sec), 0.3);
    --orange-sec-40: hsla(var(--h-o), var(--s-def), var(--l-sec), 0.4);
    --orange-sec-50: hsla(var(--h-o), var(--s-def), var(--l-sec), 0.5);
    --orange-sec-60: hsla(var(--h-o), var(--s-def), var(--l-sec), 0.6);
    --orange-sec-70: hsla(var(--h-o), var(--s-def), var(--l-sec), 0.7);
    --orange-sec-80: hsla(var(--h-o), var(--s-def), var(--l-sec), 0.8);
    --orange-sec-90: hsla(var(--h-o), var(--s-def), var(--l-sec), 0.9);

    --orange-lt: hsla(var(--h-o), var(--s-def), var(--l-lt), 1);
    --orange-lt-10: hsla(var(--h-o), var(--s-def), var(--l-lt), 0.1);
    --orange-lt-20: hsla(var(--h-o), var(--s-def), var(--l-lt), 0.2);
    --orange-lt-30: hsla(var(--h-o), var(--s-def), var(--l-lt), 0.3);
    --orange-lt-40: hsla(var(--h-o), var(--s-def), var(--l-lt), 0.4);
    --orange-lt-50: hsla(var(--h-o), var(--s-def), var(--l-lt), 0.5);
    --orange-lt-60: hsla(var(--h-o), var(--s-def), var(--l-lt), 0.6);
    --orange-lt-70: hsla(var(--h-o), var(--s-def), var(--l-lt), 0.7);
    --orange-lt-80: hsla(var(--h-o), var(--s-def), var(--l-lt), 0.8);
    --orange-lt-90: hsla(var(--h-o), var(--s-def), var(--l-lt), 0.9);

    --orange-dk: hsla(var(--h-o), var(--s-def), var(--l-dk), 1);
    --orange-dk-10: hsla(var(--h-o), var(--s-def), var(--l-dk), 0.1);
    --orange-dk-20: hsla(var(--h-o), var(--s-def), var(--l-dk), 0.2);
    --orange-dk-30: hsla(var(--h-o), var(--s-def), var(--l-dk), 0.3);
    --orange-dk-40: hsla(var(--h-o), var(--s-def), var(--l-dk), 0.4);
    --orange-dk-50: hsla(var(--h-o), var(--s-def), var(--l-dk), 0.5);
    --orange-dk-60: hsla(var(--h-o), var(--s-def), var(--l-dk), 0.6);
    --orange-dk-70: hsla(var(--h-o), var(--s-def), var(--l-dk), 0.7);
    --orange-dk-80: hsla(var(--h-o), var(--s-def), var(--l-dk), 0.8);
    --orange-dk-90: hsla(var(--h-o), var(--s-def), var(--l-dk), 0.9);

    --orange-dp: hsla(var(--h-o), var(--s-def), var(--l-dp), 1);
    --orange-dp-10: hsla(var(--h-o), var(--s-def), var(--l-dp), 0.1);
    --orange-dp-20: hsla(var(--h-o), var(--s-def), var(--l-dp), 0.2);
    --orange-dp-30: hsla(var(--h-o), var(--s-def), var(--l-dp), 0.3);
    --orange-dp-40: hsla(var(--h-o), var(--s-def), var(--l-dp), 0.4);
    --orange-dp-50: hsla(var(--h-o), var(--s-def), var(--l-dp), 0.5);
    --orange-dp-60: hsla(var(--h-o), var(--s-def), var(--l-dp), 0.6);
    --orange-dp-70: hsla(var(--h-o), var(--s-def), var(--l-dp), 0.7);
    --orange-dp-80: hsla(var(--h-o), var(--s-def), var(--l-dp), 0.8);
    --orange-dp-90: hsla(var(--h-o), var(--s-def), var(--l-dp), 0.9);

    --yellow: hsla(var(--h-y), var(--s-def), var(--l-def), 1);
    --yellow-10: hsla(var(--h-y), var(--s-def), var(--l-def), 0.1);
    --yellow-20: hsla(var(--h-y), var(--s-def), var(--l-def), 0.2);
    --yellow-30: hsla(var(--h-y), var(--s-def), var(--l-def), 0.3);
    --yellow-40: hsla(var(--h-y), var(--s-def), var(--l-def), 0.4);
    --yellow-50: hsla(var(--h-y), var(--s-def), var(--l-def), 0.5);
    --yellow-60: hsla(var(--h-y), var(--s-def), var(--l-def), 0.6);
    --yellow-70: hsla(var(--h-y), var(--s-def), var(--l-def), 0.7);
    --yellow-80: hsla(var(--h-y), var(--s-def), var(--l-def), 0.8);
    --yellow-90: hsla(var(--h-y), var(--s-def), var(--l-def), 0.9);

    --yellow-sec: hsla(var(--h-y), var(--s-def), var(--l-sec), 1);
    --yellow-sec-10: hsla(var(--h-y), var(--s-def), var(--l-sec), 0.1);
    --yellow-sec-20: hsla(var(--h-y), var(--s-def), var(--l-sec), 0.2);
    --yellow-sec-30: hsla(var(--h-y), var(--s-def), var(--l-sec), 0.3);
    --yellow-sec-40: hsla(var(--h-y), var(--s-def), var(--l-sec), 0.4);
    --yellow-sec-50: hsla(var(--h-y), var(--s-def), var(--l-sec), 0.5);
    --yellow-sec-60: hsla(var(--h-y), var(--s-def), var(--l-sec), 0.6);
    --yellow-sec-70: hsla(var(--h-y), var(--s-def), var(--l-sec), 0.7);
    --yellow-sec-80: hsla(var(--h-y), var(--s-def), var(--l-sec), 0.8);
    --yellow-sec-90: hsla(var(--h-y), var(--s-def), var(--l-sec), 0.9);

    --yellow-lt: hsla(var(--h-y), var(--s-def), var(--l-lt), 1);
    --yellow-lt-10: hsla(var(--h-y), var(--s-def), var(--l-lt), 0.1);
    --yellow-lt-20: hsla(var(--h-y), var(--s-def), var(--l-lt), 0.2);
    --yellow-lt-30: hsla(var(--h-y), var(--s-def), var(--l-lt), 0.3);
    --yellow-lt-40: hsla(var(--h-y), var(--s-def), var(--l-lt), 0.4);
    --yellow-lt-50: hsla(var(--h-y), var(--s-def), var(--l-lt), 0.5);
    --yellow-lt-60: hsla(var(--h-y), var(--s-def), var(--l-lt), 0.6);
    --yellow-lt-70: hsla(var(--h-y), var(--s-def), var(--l-lt), 0.7);
    --yellow-lt-80: hsla(var(--h-y), var(--s-def), var(--l-lt), 0.8);
    --yellow-lt-90: hsla(var(--h-y), var(--s-def), var(--l-lt), 0.9);

    --yellow-dk: hsla(var(--h-y), var(--s-def), var(--l-dk), 1);
    --yellow-dk-10: hsla(var(--h-y), var(--s-def), var(--l-dk), 0.1);
    --yellow-dk-20: hsla(var(--h-y), var(--s-def), var(--l-dk), 0.2);
    --yellow-dk-30: hsla(var(--h-y), var(--s-def), var(--l-dk), 0.3);
    --yellow-dk-40: hsla(var(--h-y), var(--s-def), var(--l-dk), 0.4);
    --yellow-dk-50: hsla(var(--h-y), var(--s-def), var(--l-dk), 0.5);
    --yellow-dk-60: hsla(var(--h-y), var(--s-def), var(--l-dk), 0.6);
    --yellow-dk-70: hsla(var(--h-y), var(--s-def), var(--l-dk), 0.7);
    --yellow-dk-80: hsla(var(--h-y), var(--s-def), var(--l-dk), 0.8);
    --yellow-dk-90: hsla(var(--h-y), var(--s-def), var(--l-dk), 0.9);

    --yellow-dp: hsla(var(--h-y), var(--s-def), var(--l-dp), 1);
    --yellow-dp-10: hsla(var(--h-y), var(--s-def), var(--l-dp), 0.1);
    --yellow-dp-20: hsla(var(--h-y), var(--s-def), var(--l-dp), 0.2);
    --yellow-dp-30: hsla(var(--h-y), var(--s-def), var(--l-dp), 0.3);
    --yellow-dp-40: hsla(var(--h-y), var(--s-def), var(--l-dp), 0.4);
    --yellow-dp-50: hsla(var(--h-y), var(--s-def), var(--l-dp), 0.5);
    --yellow-dp-60: hsla(var(--h-y), var(--s-def), var(--l-dp), 0.6);
    --yellow-dp-70: hsla(var(--h-y), var(--s-def), var(--l-dp), 0.7);
    --yellow-dp-80: hsla(var(--h-y), var(--s-def), var(--l-dp), 0.8);
    --yellow-dp-90: hsla(var(--h-y), var(--s-def), var(--l-dp), 0.9);

    --green: hsla(var(--h-g), var(--s-gr-def), var(--l-grn-def), 1);
    --green-10: hsla(var(--h-g), var(--s-gr-def), var(--l-grn-def), 0.1);
    --green-20: hsla(var(--h-g), var(--s-gr-def), var(--l-grn-def), 0.2);
    --green-30: hsla(var(--h-g), var(--s-gr-def), var(--l-grn-def), 0.3);
    --green-40: hsla(var(--h-g), var(--s-gr-def), var(--l-grn-def), 0.4);
    --green-50: hsla(var(--h-g), var(--s-gr-def), var(--l-grn-def), 0.5);
    --green-60: hsla(var(--h-g), var(--s-gr-def), var(--l-grn-def), 0.6);
    --green-70: hsla(var(--h-g), var(--s-gr-def), var(--l-grn-def), 0.7);
    --green-80: hsla(var(--h-g), var(--s-gr-def), var(--l-grn-def), 0.8);
    --green-90: hsla(var(--h-g), var(--s-gr-def), var(--l-grn-def), 0.9);

    --green-sec: hsla(var(--h-g), var(--s-gr-def), var(--l-sec), 1);
    --green-sec-10: hsla(var(--h-g), var(--s-gr-def), var(--l-sec), 0.1);
    --green-sec-20: hsla(var(--h-g), var(--s-gr-def), var(--l-sec), 0.2);
    --green-sec-30: hsla(var(--h-g), var(--s-gr-def), var(--l-sec), 0.3);
    --green-sec-40: hsla(var(--h-g), var(--s-gr-def), var(--l-sec), 0.4);
    --green-sec-50: hsla(var(--h-g), var(--s-gr-def), var(--l-sec), 0.5);
    --green-sec-60: hsla(var(--h-g), var(--s-gr-def), var(--l-sec), 0.6);
    --green-sec-70: hsla(var(--h-g), var(--s-gr-def), var(--l-sec), 0.7);
    --green-sec-80: hsla(var(--h-g), var(--s-gr-def), var(--l-sec), 0.8);
    --green-sec-90: hsla(var(--h-g), var(--s-gr-def), var(--l-sec), 0.9);

    --green-lt: hsla(var(--h-g), var(--s-gr-lt), var(--l-lt), 1);
    --green-lt-10: hsla(var(--h-g), var(--s-gr-lt), var(--l-lt), 0.1);
    --green-lt-20: hsla(var(--h-g), var(--s-gr-lt), var(--l-lt), 0.2);
    --green-lt-30: hsla(var(--h-g), var(--s-gr-lt), var(--l-lt), 0.3);
    --green-lt-40: hsla(var(--h-g), var(--s-gr-lt), var(--l-lt), 0.4);
    --green-lt-50: hsla(var(--h-g), var(--s-gr-lt), var(--l-lt), 0.5);
    --green-lt-60: hsla(var(--h-g), var(--s-gr-lt), var(--l-lt), 0.6);
    --green-lt-70: hsla(var(--h-g), var(--s-gr-lt), var(--l-lt), 0.7);
    --green-lt-80: hsla(var(--h-g), var(--s-gr-lt), var(--l-lt), 0.8);
    --green-lt-90: hsla(var(--h-g), var(--s-gr-lt), var(--l-lt), 0.9);

    --green-dk: hsla(var(--h-g), var(--s-gr-def), var(--l-grn-dk), 1);
    --green-dk-10: hsla(var(--h-g), var(--s-gr-def), var(--l-grn-dk), 0.1);
    --green-dk-20: hsla(var(--h-g), var(--s-gr-def), var(--l-grn-dk), 0.2);
    --green-dk-30: hsla(var(--h-g), var(--s-gr-def), var(--l-grn-dk), 0.3);
    --green-dk-40: hsla(var(--h-g), var(--s-gr-def), var(--l-grn-dk), 0.4);
    --green-dk-50: hsla(var(--h-g), var(--s-gr-def), var(--l-grn-dk), 0.5);
    --green-dk-60: hsla(var(--h-g), var(--s-gr-def), var(--l-grn-dk), 0.6);
    --green-dk-70: hsla(var(--h-g), var(--s-gr-def), var(--l-grn-dk), 0.7);
    --green-dk-80: hsla(var(--h-g), var(--s-gr-def), var(--l-grn-dk), 0.8);
    --green-dk-90: hsla(var(--h-g), var(--s-gr-def), var(--l-grn-dk), 0.9);

    --green-dp: hsla(var(--h-g), var(--s-gr-def), var(--l-dp), 1);
    --green-dp-10: hsla(var(--h-g), var(--s-gr-def), var(--l-dp), 0.1);
    --green-dp-20: hsla(var(--h-g), var(--s-gr-def), var(--l-dp), 0.2);
    --green-dp-30: hsla(var(--h-g), var(--s-gr-def), var(--l-dp), 0.3);
    --green-dp-40: hsla(var(--h-g), var(--s-gr-def), var(--l-dp), 0.4);
    --green-dp-50: hsla(var(--h-g), var(--s-gr-def), var(--l-dp), 0.5);
    --green-dp-60: hsla(var(--h-g), var(--s-gr-def), var(--l-dp), 0.6);
    --green-dp-70: hsla(var(--h-g), var(--s-gr-def), var(--l-dp), 0.7);
    --green-dp-80: hsla(var(--h-g), var(--s-gr-def), var(--l-dp), 0.8);
    --green-dp-90: hsla(var(--h-g), var(--s-gr-def), var(--l-dp), 0.9);

    --cool-blue: hsla(var(--h-cl), var(--s-def), var(--l-def), 1);
    --cool-blue-10: hsla(var(--h-cl), var(--s-def), var(--l-def), 0.1);
    --cool-blue-20: hsla(var(--h-cl), var(--s-def), var(--l-def), 0.2);
    --cool-blue-30: hsla(var(--h-cl), var(--s-def), var(--l-def), 0.3);
    --cool-blue-40: hsla(var(--h-cl), var(--s-def), var(--l-def), 0.4);
    --cool-blue-50: hsla(var(--h-cl), var(--s-def), var(--l-def), 0.5);
    --cool-blue-60: hsla(var(--h-cl), var(--s-def), var(--l-def), 0.6);
    --cool-blue-70: hsla(var(--h-cl), var(--s-def), var(--l-def), 0.7);
    --cool-blue-80: hsla(var(--h-cl), var(--s-def), var(--l-def), 0.8);
    --cool-blue-90: hsla(var(--h-cl), var(--s-def), var(--l-def), 0.9);

    --cool-blue-sec: hsla(var(--h-cl), var(--s-def), var(--l-sec), 1);
    --cool-blue-sec-10: hsla(var(--h-cl), var(--s-def), var(--l-sec), 0.1);
    --cool-blue-sec-20: hsla(var(--h-cl), var(--s-def), var(--l-sec), 0.2);
    --cool-blue-sec-30: hsla(var(--h-cl), var(--s-def), var(--l-sec), 0.3);
    --cool-blue-sec-40: hsla(var(--h-cl), var(--s-def), var(--l-sec), 0.4);
    --cool-blue-sec-50: hsla(var(--h-cl), var(--s-def), var(--l-sec), 0.5);
    --cool-blue-sec-60: hsla(var(--h-cl), var(--s-def), var(--l-sec), 0.6);
    --cool-blue-sec-70: hsla(var(--h-cl), var(--s-def), var(--l-sec), 0.7);
    --cool-blue-sec-80: hsla(var(--h-cl), var(--s-def), var(--l-sec), 0.8);
    --cool-blue-sec-90: hsla(var(--h-cl), var(--s-def), var(--l-sec), 0.9);

    --cool-blue-lt: hsla(var(--h-cl), var(--s-def), var(--l-lt), 1);
    --cool-blue-lt-10: hsla(var(--h-cl), var(--s-def), var(--l-lt), 0.1);
    --cool-blue-lt-20: hsla(var(--h-cl), var(--s-def), var(--l-lt), 0.2);
    --cool-blue-lt-30: hsla(var(--h-cl), var(--s-def), var(--l-lt), 0.3);
    --cool-blue-lt-40: hsla(var(--h-cl), var(--s-def), var(--l-lt), 0.4);
    --cool-blue-lt-50: hsla(var(--h-cl), var(--s-def), var(--l-lt), 0.5);
    --cool-blue-lt-60: hsla(var(--h-cl), var(--s-def), var(--l-lt), 0.6);
    --cool-blue-lt-70: hsla(var(--h-cl), var(--s-def), var(--l-lt), 0.7);
    --cool-blue-lt-80: hsla(var(--h-cl), var(--s-def), var(--l-lt), 0.8);
    --cool-blue-lt-90: hsla(var(--h-cl), var(--s-def), var(--l-lt), 0.9);

    --cool-blue-dk: hsla(var(--h-cl), var(--s-def), var(--l-dk), 1);
    --cool-blue-dk-10: hsla(var(--h-cl), var(--s-def), var(--l-dk), 0.1);
    --cool-blue-dk-20: hsla(var(--h-cl), var(--s-def), var(--l-dk), 0.2);
    --cool-blue-dk-30: hsla(var(--h-cl), var(--s-def), var(--l-dk), 0.3);
    --cool-blue-dk-40: hsla(var(--h-cl), var(--s-def), var(--l-dk), 0.4);
    --cool-blue-dk-50: hsla(var(--h-cl), var(--s-def), var(--l-dk), 0.5);
    --cool-blue-dk-60: hsla(var(--h-cl), var(--s-def), var(--l-dk), 0.6);
    --cool-blue-dk-70: hsla(var(--h-cl), var(--s-def), var(--l-dk), 0.7);
    --cool-blue-dk-80: hsla(var(--h-cl), var(--s-def), var(--l-dk), 0.8);
    --cool-blue-dk-90: hsla(var(--h-cl), var(--s-def), var(--l-dk), 0.9);

    --cool-blue-dp: hsla(var(--h-cl), var(--s-def), var(--l-dp), 1);
    --cool-blue-dp-10: hsla(var(--h-cl), var(--s-def), var(--l-dp), 0.1);
    --cool-blue-dp-20: hsla(var(--h-cl), var(--s-def), var(--l-dp), 0.2);
    --cool-blue-dp-30: hsla(var(--h-cl), var(--s-def), var(--l-dp), 0.3);
    --cool-blue-dp-40: hsla(var(--h-cl), var(--s-def), var(--l-dp), 0.4);
    --cool-blue-dp-50: hsla(var(--h-cl), var(--s-def), var(--l-dp), 0.5);
    --cool-blue-dp-60: hsla(var(--h-cl), var(--s-def), var(--l-dp), 0.6);
    --cool-blue-dp-70: hsla(var(--h-cl), var(--s-def), var(--l-dp), 0.7);
    --cool-blue-dp-80: hsla(var(--h-cl), var(--s-def), var(--l-dp), 0.8);
    --cool-blue-dp-90: hsla(var(--h-cl), var(--s-def), var(--l-dp), 0.9);

    --blue: hsla(var(--h-bl), var(--s-def), var(--l-def), 1);
    --blue-10: hsla(var(--h-bl), var(--s-def), var(--l-def), 0.1);
    --blue-20: hsla(var(--h-bl), var(--s-def), var(--l-def), 0.2);
    --blue-30: hsla(var(--h-bl), var(--s-def), var(--l-def), 0.3);
    --blue-40: hsla(var(--h-bl), var(--s-def), var(--l-def), 0.4);
    --blue-50: hsla(var(--h-bl), var(--s-def), var(--l-def), 0.5);
    --blue-60: hsla(var(--h-bl), var(--s-def), var(--l-def), 0.6);
    --blue-70: hsla(var(--h-bl), var(--s-def), var(--l-def), 0.7);
    --blue-80: hsla(var(--h-bl), var(--s-def), var(--l-def), 0.8);
    --blue-90: hsla(var(--h-bl), var(--s-def), var(--l-def), 0.9);

    --blue-sec: hsla(var(--h-bl), var(--s-def), var(--l-sec), 1);
    --blue-sec-10: hsla(var(--h-bl), var(--s-def), var(--l-sec), 0.1);
    --blue-sec-20: hsla(var(--h-bl), var(--s-def), var(--l-sec), 0.2);
    --blue-sec-30: hsla(var(--h-bl), var(--s-def), var(--l-sec), 0.3);
    --blue-sec-40: hsla(var(--h-bl), var(--s-def), var(--l-sec), 0.4);
    --blue-sec-50: hsla(var(--h-bl), var(--s-def), var(--l-sec), 0.5);
    --blue-sec-60: hsla(var(--h-bl), var(--s-def), var(--l-sec), 0.6);
    --blue-sec-70: hsla(var(--h-bl), var(--s-def), var(--l-sec), 0.7);
    --blue-sec-80: hsla(var(--h-bl), var(--s-def), var(--l-sec), 0.8);
    --blue-sec-90: hsla(var(--h-bl), var(--s-def), var(--l-sec), 0.9);

    --blue-lt: hsla(var(--h-bl), var(--s-def), var(--l-lt), 1);
    --blue-lt-10: hsla(var(--h-bl), var(--s-def), var(--l-lt), 0.1);
    --blue-lt-20: hsla(var(--h-bl), var(--s-def), var(--l-lt), 0.2);
    --blue-lt-30: hsla(var(--h-bl), var(--s-def), var(--l-lt), 0.3);
    --blue-lt-40: hsla(var(--h-bl), var(--s-def), var(--l-lt), 0.4);
    --blue-lt-50: hsla(var(--h-bl), var(--s-def), var(--l-lt), 0.5);
    --blue-lt-60: hsla(var(--h-bl), var(--s-def), var(--l-lt), 0.6);
    --blue-lt-70: hsla(var(--h-bl), var(--s-def), var(--l-lt), 0.7);
    --blue-lt-80: hsla(var(--h-bl), var(--s-def), var(--l-lt), 0.8);
    --blue-lt-90: hsla(var(--h-bl), var(--s-def), var(--l-lt), 0.9);

    --blue-dk: hsla(var(--h-bl), var(--s-def), var(--l-dk), 1);
    --blue-dk-10: hsla(var(--h-bl), var(--s-def), var(--l-dk), 0.1);
    --blue-dk-20: hsla(var(--h-bl), var(--s-def), var(--l-dk), 0.2);
    --blue-dk-30: hsla(var(--h-bl), var(--s-def), var(--l-dk), 0.3);
    --blue-dk-40: hsla(var(--h-bl), var(--s-def), var(--l-dk), 0.4);
    --blue-dk-50: hsla(var(--h-bl), var(--s-def), var(--l-dk), 0.5);
    --blue-dk-60: hsla(var(--h-bl), var(--s-def), var(--l-dk), 0.6);
    --blue-dk-70: hsla(var(--h-bl), var(--s-def), var(--l-dk), 0.7);
    --blue-dk-80: hsla(var(--h-bl), var(--s-def), var(--l-dk), 0.8);
    --blue-dk-90: hsla(var(--h-bl), var(--s-def), var(--l-dk), 0.9);

    --blue-dp: hsla(var(--h-bl), var(--s-def), var(--l-dp), 1);
    --blue-dp-10: hsla(var(--h-bl), var(--s-def), var(--l-dp), 0.1);
    --blue-dp-20: hsla(var(--h-bl), var(--s-def), var(--l-dp), 0.2);
    --blue-dp-30: hsla(var(--h-bl), var(--s-def), var(--l-dp), 0.3);
    --blue-dp-40: hsla(var(--h-bl), var(--s-def), var(--l-dp), 0.4);
    --blue-dp-50: hsla(var(--h-bl), var(--s-def), var(--l-dp), 0.5);
    --blue-dp-60: hsla(var(--h-bl), var(--s-def), var(--l-dp), 0.6);
    --blue-dp-70: hsla(var(--h-bl), var(--s-def), var(--l-dp), 0.7);
    --blue-dp-80: hsla(var(--h-bl), var(--s-def), var(--l-dp), 0.8);
    --blue-dp-90: hsla(var(--h-bl), var(--s-def), var(--l-dp), 0.9);

    --purple: hsla(var(--h-p), var(--s-def), var(--l-def), 1);
    --purple-10: hsla(var(--h-p), var(--s-def), var(--l-def), 0.1);
    --purple-20: hsla(var(--h-p), var(--s-def), var(--l-def), 0.2);
    --purple-30: hsla(var(--h-p), var(--s-def), var(--l-def), 0.3);
    --purple-40: hsla(var(--h-p), var(--s-def), var(--l-def), 0.4);
    --purple-50: hsla(var(--h-p), var(--s-def), var(--l-def), 0.5);
    --purple-60: hsla(var(--h-p), var(--s-def), var(--l-def), 0.6);
    --purple-70: hsla(var(--h-p), var(--s-def), var(--l-def), 0.7);
    --purple-80: hsla(var(--h-p), var(--s-def), var(--l-def), 0.8);
    --purple-90: hsla(var(--h-p), var(--s-def), var(--l-def), 0.9);

    --purple-sec: hsla(var(--h-p), var(--s-def), var(--l-sec), 1);
    --purple-sec-10: hsla(var(--h-p), var(--s-mtd), var(--l-sec), 0.1);
    --purple-sec-20: hsla(var(--h-p), var(--s-mtd), var(--l-sec), 0.2);
    --purple-sec-30: hsla(var(--h-p), var(--s-mtd), var(--l-sec), 0.3);
    --purple-sec-40: hsla(var(--h-p), var(--s-mtd), var(--l-sec), 0.4);
    --purple-sec-50: hsla(var(--h-p), var(--s-mtd), var(--l-sec), 0.5);
    --purple-sec-60: hsla(var(--h-p), var(--s-mtd), var(--l-sec), 0.6);
    --purple-sec-70: hsla(var(--h-p), var(--s-mtd), var(--l-sec), 0.7);
    --purple-sec-80: hsla(var(--h-p), var(--s-mtd), var(--l-sec), 0.8);
    --purple-sec-90: hsla(var(--h-p), var(--s-mtd), var(--l-sec), 0.9);

    --purple-lt: hsla(var(--h-p), var(--s-mtd), var(--l-lt), 1);
    --purple-lt-10: hsla(var(--h-p), var(--s-mtd), var(--l-lt), 0.1);
    --purple-lt-20: hsla(var(--h-p), var(--s-mtd), var(--l-lt), 0.2);
    --purple-lt-30: hsla(var(--h-p), var(--s-mtd), var(--l-lt), 0.3);
    --purple-lt-40: hsla(var(--h-p), var(--s-mtd), var(--l-lt), 0.4);
    --purple-lt-50: hsla(var(--h-p), var(--s-mtd), var(--l-lt), 0.5);
    --purple-lt-60: hsla(var(--h-p), var(--s-mtd), var(--l-lt), 0.6);
    --purple-lt-70: hsla(var(--h-p), var(--s-mtd), var(--l-lt), 0.7);
    --purple-lt-80: hsla(var(--h-p), var(--s-mtd), var(--l-lt), 0.8);
    --purple-lt-90: hsla(var(--h-p), var(--s-mtd), var(--l-lt), 0.9);

    --purple-dk: hsla(var(--h-p), var(--s-def), var(--l-dk), 1);
    --purple-dk-10: hsla(var(--h-p), var(--s-def), var(--l-dk), 0.1);
    --purple-dk-20: hsla(var(--h-p), var(--s-def), var(--l-dk), 0.2);
    --purple-dk-30: hsla(var(--h-p), var(--s-def), var(--l-dk), 0.3);
    --purple-dk-40: hsla(var(--h-p), var(--s-def), var(--l-dk), 0.4);
    --purple-dk-50: hsla(var(--h-p), var(--s-def), var(--l-dk), 0.5);
    --purple-dk-60: hsla(var(--h-p), var(--s-def), var(--l-dk), 0.6);
    --purple-dk-70: hsla(var(--h-p), var(--s-def), var(--l-dk), 0.7);
    --purple-dk-80: hsla(var(--h-p), var(--s-def), var(--l-dk), 0.8);
    --purple-dk-90: hsla(var(--h-p), var(--s-def), var(--l-dk), 0.9);

    --purple-dp: hsla(var(--h-p), var(--s-def), var(--l-dp), 1);
    --purple-dp-10: hsla(var(--h-p), var(--s-def), var(--l-dp), 0.1);
    --purple-dp-20: hsla(var(--h-p), var(--s-def), var(--l-dp), 0.2);
    --purple-dp-30: hsla(var(--h-p), var(--s-def), var(--l-dp), 0.3);
    --purple-dp-40: hsla(var(--h-p), var(--s-def), var(--l-dp), 0.4);
    --purple-dp-50: hsla(var(--h-p), var(--s-def), var(--l-dp), 0.5);
    --purple-dp-60: hsla(var(--h-p), var(--s-def), var(--l-dp), 0.6);
    --purple-dp-70: hsla(var(--h-p), var(--s-def), var(--l-dp), 0.7);
    --purple-dp-80: hsla(var(--h-p), var(--s-def), var(--l-dp), 0.8);
    --purple-dp-90: hsla(var(--h-p), var(--s-def), var(--l-dp), 0.9);
}
