:root {
    --grid-min: var(--spc-lg-w);
    --grid-auto: repeat(auto-fit, minmax(var(--grid-min), auto));
    --grid-even: repeat(auto-fit, minmax(var(--grid-min), 1fr));

    --grid-1: repeat(1, minmax(var(--grid-min), 1fr));
    --grid-1-even: var(--grid-1);
    --grid-2: repeat(2, minmax(var(--grid-min), 1fr));
    --grid-2-even: var(--grid-2);
    --grid-3: repeat(3, minmax(var(--grid-min), 1fr));
    --grid-3-even: var(--grid-3);
    --grid-4: repeat(4, minmax(var(--grid-min), 1fr));
    --grid-4-even: var(--grid-4);
    --grid-5: repeat(5, minmax(var(--grid-min), 1fr));
    --grid-5-even: var(--grid-5);
    --grid-6: repeat(6, minmax(var(--grid-min), 1fr));
    --grid-6-even: var(--grid-6);
    --grid-7: repeat(7, minmax(var(--grid-min), 1fr));
    --grid-7-even: var(--grid-7);
    --grid-8: repeat(8, minmax(var(--grid-min), 1fr));
    --grid-8-even: var(--grid-8);
    --grid-9: repeat(9, minmax(var(--grid-min), 1fr));
    --grid-9-even: var(--grid-9);
    --grid-10: repeat(10, minmax(var(--grid-min), 1fr));
    --grid-10-even: var(--grid-10);
    --grid-11: repeat(11, minmax(var(--grid-min), 1fr));
    --grid-11-even: var(--grid-11);
    --grid-12: repeat(12, minmax(var(--grid-min), 1fr));
    --grid-12-even: var(--grid-12);

    --grid-1-auto: repeat(1, minmax(var(--grid-min), auto));
    --grid-2-auto: repeat(2, minmax(var(--grid-min), auto));
    --grid-3-auto: repeat(3, minmax(var(--grid-min), auto));
    --grid-4-auto: repeat(4, minmax(var(--grid-min), auto));
    --grid-5-auto: repeat(5, minmax(var(--grid-min), auto));
    --grid-6-auto: repeat(6, minmax(var(--grid-min), auto));
    --grid-7-auto: repeat(7, minmax(var(--grid-min), auto));
    --grid-8-auto: repeat(8, minmax(var(--grid-min), auto));
    --grid-9-auto: repeat(9, minmax(var(--grid-min), auto));
    --grid-10-auto: repeat(10, minmax(var(--grid-min), auto));
    --grid-11-auto: repeat(11, minmax(var(--grid-min), auto));
    --grid-12-auto: repeat(12, minmax(var(--grid-min), auto));

    --gap-xs: var(--spc-xs-w);
    --gap-sm: var(--spc-sm-w);
    --gap-md: var(--spc-md-w);
    --gap-lg: var(--spc-lg-w);
    --gap-xl: var(--spc-xl-w);
}

.grid-auto {
    display: grid;
    grid-template-columns: var(--grid-auto);
}

.grid-1 {
    display: grid;
    grid-template-columns: var(--grid-1);
}

.grid-2 {
    display: grid;
    grid-template-columns: var(--grid-2);
}

.grid-3 {
    display: grid;
    grid-template-columns: var(--grid-3);
}

.grid-4 {
    display: grid;
    grid-template-columns: var(--grid-4);
}

.grid-5 {
    display: grid;
    grid-template-columns: var(--grid-5);
}

.grid-6 {
    display: grid;
    grid-template-columns: var(--grid-6);
}

.grid-7 {
    display: grid;
    grid-template-columns: var(--grid-7);
}

.grid-8 {
    display: grid;
    grid-template-columns: var(--grid-8);
}

.grid-1-auto {
    display: grid;
    grid-template-columns: var(--grid-1-auto);
}

.grid-2-auto {
    display: grid;
    grid-template-columns: var(--grid-2-auto);
}

.grid-3-auto {
    display: grid;
    grid-template-columns: var(--grid-3-auto);
}

.grid-4-auto {
    display: grid;
    grid-template-columns: var(--grid-4-auto);
}

.grid-5-auto {
    display: grid;
    grid-template-columns: var(--grid-5-auto);
}

.grid-6-auto {
    display: grid;
    grid-template-columns: var(--grid-6-auto);
}

.grid-7-auto {
    display: grid;
    grid-template-columns: var(--grid-7-auto);
}

.grid-8-auto {
    display: grid;
    grid-template-columns: var(--grid-8-auto);
}

.align-center {
    align-content: center;
}

.gap-xs {
    gap: var(--gap-xs);
}

.gap-sm {
    gap: var(--gap-sm);
}

.gap-md {
    gap: var(--gap-md);
}

.gap-lg {
    gap: var(--gap-lg);
}

.gap-xl {
    gap: var(--gap-xl);
}

.row-gap-xs {
    row-gap: var(--gap-xs);
}

.row-gap-sm {
    row-gap: var(--gap-sm);
}

.row-gap-md {
    row-gap: var(--gap-md);
}

.row-gap-lg {
    row-gap: var(--gap-lg);
}

.row-gap-xl {
    row-gap: var(--gap-xl);
}

.col-gap-xs {
    -moz-column-gap: var(--gap-xs);
         column-gap: var(--gap-xs);
}

.col-gap-sm {
    -moz-column-gap: var(--gap-sm);
         column-gap: var(--gap-sm);
}

.col-gap-md {
    -moz-column-gap: var(--gap-md);
         column-gap: var(--gap-md);
}

.col-gap-lg {
    -moz-column-gap: var(--gap-lg);
         column-gap: var(--gap-lg);
}

.col-gap-xl {
    -moz-column-gap: var(--gap-xl);
         column-gap: var(--gap-xl);
}

.headline-bold {
    font-family: var(--headline-font-bold);
}

.main-bold {
    font-family: var(--main-font-bold);
}

.main-italic {
    font-family: var(--main-font-italic);
}

.condensed-bold {
    font-family: var(--condensed-font-bold);
}

.center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.right {
    text-align: right;
    margin-left: auto;
    margin-right: 0;
}

.capitalize {
    text-transform: capitalize;
}

.pointer {
    cursor: pointer;
}

.visually-hidden {
    position: absolute;
    left: -9999px;
    width: 1px;
    height: 1px;
    overflow: hidden;
}


.mar-0 {
    margin: 0;
}

.mar-xs {
    margin: var(--spc-xs);
}

.mar-sm {
    margin: var(--spc-sm);
}

.mar-md {
    margin: var(--spc-md);
}

.mar-lg {
    margin: var(--spc-lg);
}

.mar-xl {
    margin: var(--spc-xl);
}

.mar-top-0 {
    margin-top: 0;
}

.mar-btm-0 {
    margin-bottom: 0;
}

.mar-y-0 {
    margin-top: 0;
    margin-bottom: 0;
}

.mar-y-xs {
    margin-top: var(--spc-xs-h);
    margin-bottom: var(--spc-xs-h);
}

.mar-y-sm {
    margin-top: var(--spc-sm-h);
    margin-bottom: var(--spc-sm-h);
}

.mar-y-md {
    margin-top: var(--spc-md-h);
    margin-bottom: var(--spc-md-h);
}

.mar-y-lg {
    margin-top: var(--spc-lg-h);
    margin-bottom: var(--spc-lg-h);
}

.mar-y-xl {
    margin-top: var(--spc-xl-h);
    margin-bottom: var(--spc-xl-h);
}

.mar-y-xl2 {
    margin-top: var(--spc-xl2-h);
    margin-bottom: var(--spc-xl2-h);
}

.mar-x-0 {
    margin-left: 0;
    margin-right: 0;
}

.mar-x-xs {
    margin-top: var(--spc-xs-w);
    margin-bottom: var(--spc-xs-w);
}

.mar-x-sm {
    margin-top: var(--spc-sm-w);
    margin-bottom: var(--spc-sm-w);
}

.mar-x-md {
    margin-top: var(--spc-md-w);
    margin-bottom: var(--spc-md-w);
}

.mar-x-lg {
    margin-top: var(--spc-lg-w);
    margin-bottom: var(--spc-lg-w);
}

.mar-x-xl {
    margin-top: var(--spc-xl-w);
    margin-bottom: var(--spc-xl-w);
}

.mar-x-xl2 {
    margin-top: var(--spc-xl2-w);
    margin-bottom: var(--spc-xl2-w);
}

.mar-auto {
    margin-left: auto;
    margin-right: auto;
}

.mar-top-xs {
    margin-top: var(--spc-xs-h);
}

.mar-btm-xs {
    margin-bottom: var(--spc-xs-h);
}

.mar-top-0 {
    margin-top: 0;
}

.mar-top-sm {
    margin-top: var(--spc-sm-h);
}

.mar-btm-sm {
    margin-bottom: var(--spc-sm-h);
}

.mar-top-md {
    margin-top: var(--spc-md-h);
}

.mar-btm-md {
    margin-bottom: var(--spc-md-h);
}

.mar-top-lg {
    margin-top: var(--spc-lg-h);
}

.mar-btm-lg {
    margin-bottom: var(--spc-lg-h);
}

.mar-top-xl {
    margin-top: var(--spc-xl-h);
}

.mar-btm-xl {
    margin-bottom: var(--spc-xl-h);
}

.pad-0 {
    padding: 0;
}

.pad-xs {
    padding: var(--spc-xs);
}

.pad-sm {
    padding: var(--spc-sm);
}

.pad-md {
    padding: var(--spc-md);
}

.pad-lg {
    padding: var(--spc-lg);
}

.pad-xl {
    padding: var(--spc-xl);
}

.pad-top-0 {
    padding-top: 0;
}

.pad-btm-0 {
    padding-bottom: 0;
}

.pad-y-0 {
    padding-top: 0;
    padding-bottom: 0;
}

.pad-y-xs {
    padding-top: var(--spc-xs-h);
    padding-bottom: var(--spc-xs-h);
}

.pad-y-sm {
    padding-top: var(--spc-sm-h);
    padding-bottom: var(--spc-sm-h);
}

.pad-y-md {
    padding-top: var(--spc-md-h);
    padding-bottom: var(--spc-md-h);
}

.pad-y-lg {
    padding-top: var(--spc-lg-h);
    padding-bottom: var(--spc-lg-h);
}

.pad-y-xl {
    padding-top: var(--spc-xl-h);
    padding-bottom: var(--spc-xl-h);
}

.pad-y-xl2 {
    padding-top: var(--spc-xl2-h);
    padding-bottom: var(--spc-xl2-h);
}

.pad-x-0 {
    padding-left: 0;
    padding-right: 0;
}

.pad-btm-xl {
    padding-bottom: var(--spc-xl-h);
}

.pad-x-xs {
    padding-top: var(--spc-xs-w);
    padding-bottom: var(--spc-xs-w);
}

.pad-x-sm {
    padding-top: var(--spc-sm-w);
    padding-bottom: var(--spc-sm-w);
}

.pad-x-md {
    padding-top: var(--spc-md-w);
    padding-bottom: var(--spc-md-w);
}

.pad-x-lg {
    padding-top: var(--spc-lg-w);
    padding-bottom: var(--spc-lg-w);
}

.pad-x-xl {
    padding-top: var(--spc-xl-w);
    padding-bottom: var(--spc-xl-w);
}

.pad-x-xl2 {
    padding-top: var(--spc-xl2-w);
    padding-bottom: var(--spc-xl2-w);
}

.pad-top-xs {
    padding-top: var(--spc-xs-h);
}

.pad-btm-xs {
    padding-bottom: var(--spc-xs-h);
}

.pad-top-sm {
    padding-top: var(--spc-sm-h);
}

.pad-btm-sm {
    padding-bottom: var(--spc-sm-h);
}

.pad-top-md {
    padding-top: var(--spc-md-h);
}

.pad-btm-md {
    padding-bottom: var(--spc-md-h);
}

.pad-top-lg {
    padding-top: var(--spc-lg-h);
}

.pad-btm-lg {
    padding-bottom: var(--spc-lg-h);
}

.pad-top-xl {
    padding-top: var(--spc-xl-h);
}

.pad-btm-xl {
    padding-bottom: var(--spc-xl-h);
}

.border {
    border: 2px solid var(--text-col-20);
}

.border-rad-xs {
    border-radius: var(--spc-xs-w);
}

.border-rad-sm {
    border-radius: var(--spc-sm-w);
}

.border-rad-md {
    border-radius: var(--spc-md-w);
}

