.mapped-projects {
	background: var(--bg-gradient-gray);
}

.mapped-projects > .section-content-wrapper {
	display: block;
	width: 100%;
	/* min-width: 90vw; */
	padding: 0 var(--spc-md-w);
}

.bu-region {
	fill: var(--blue-dp-80);
	stroke: #fff;
	stroke-linejoin: round;
	stroke-width: 1px;
	transition: var(--fade);
}

.bu-region:hover {
	fill: var(--blue-dp) !important;
	cursor: pointer;
	stroke-width: 3px;
}

.bu-region.selected {
	fill: var(--blue-dp);
	stroke-width: 3px;
}

#us-map {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

#details-box {
	box-shadow: 0px 7px 40px rgba(0, 0, 0, 0.7);
	opacity: 0%;
	padding: 1rem;
	border-radius: 8px;
	font-size: var(--md);
	position: fixed;
	color: white;
	background-color: black;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	transform: translateX(-50%);
	transition: opacity 0.4s ease;
	z-index: 1;
	white-space: pre-wrap;
	text-align: center;
}

.map-projects-wrapper {
	display: grid;
	grid-template-columns: 3fr 5fr;
	place-items: center;
	width: 100%;
	min-height: 650px;
	-moz-column-gap: var(--grid-gap-sm);
	column-gap: var(--grid-gap-sm);
	margin-top: var(--spc-xl-h);
}

.map-projects-wrapper svg {
	max-height: 100%;
}

.projects-wrapper {
	background: var(--bg-gradient-blue-dp);
	border-radius: var(--rnd-md);
	padding: 0 var(--spc-md-w);
	height: 100%;
	width: 100%;
	/* max-height: 650px; */
	overflow-y: scroll;
	box-shadow: var(--neumorph-md);
	/* hide scrollbar */
	/* Chrome, Safari and Opera */
	overflow-x: hidden;
	/* IE and Edge */
	-ms-overflow-style: none;
	/* Firefox */
	scrollbar-width: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.projects-wrapper::-webkit-scrollbar {
	display: none;
}

.projects-wrapper h4 {
	text-align: center;
}
.projects-wrapper h5,
.projects-wrapper h6,
.projects-wrapper p {
	margin: var(--spc-xs-h) 0;
}
.projects-wrapper img {
	border-radius: var(--rnd-sm);
}

@media screen and (max-width: 1200px) {
	.map-projects-wrapper {
		grid-template-columns: 1fr;
		grid-template-areas: 'top' 'bottom';
		row-gap: var(--grid-gap-sm);
	}
	.map-projects-wrapper svg {
		grid-area: top;
	}
	.projects-wrapper {
		grid-area: bottom;
		min-height: 400px;
		width: clamp(300px, 100%, 800px);
	}
}
