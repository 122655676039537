:root {
	--g-spread: 60%;
	--g-left: 0%;
	--g-rt: 100%;
	--g-top: 0%;
	--g-btm: 100%;

	/* red */
	--bg-gradient-red-top-left: radial-gradient(
		circle at var(--g-left) var(--g-top),
		var(--red-60) 0%,
		transparent var(--g-spread)
	);
	--bg-gradient-red-bottom-left: radial-gradient(
		circle at var(--g-left) var(--g-btm),
		var(--red-60) 0%,
		transparent var(--g-spread)
	);
	--bg-gradient-red-top-right: radial-gradient(
		circle at var(--g-rt) var(--g-top),
		var(--red-60) 0%,
		transparent var(--g-spread)
	);
	--bg-gradient-red-bottom-right: radial-gradient(
		circle at var(--g-rt) var(--g-btm),
		var(--red-60) 0%,
		transparent var(--g-spread)
	);

	/* orange */
	--bg-gradient-orange-top-left: radial-gradient(
		circle at var(--g-left) var(--g-top),
		var(--orange-60) 0%,
		transparent var(--g-spread)
	);
	--bg-gradient-orange-bottom-left: radial-gradient(
		circle at var(--g-left) var(--g-btm),
		var(--orange-60) 0%,
		transparent var(--g-spread)
	);
	--bg-gradient-orange-top-right: radial-gradient(
		circle at var(--g-rt) var(--g-top),
		var(--orange-60) 0%,
		transparent var(--g-spread)
	);
	--bg-gradient-orange-bottom-right: radial-gradient(
		circle at var(--g-rt) var(--g-btm),
		var(--orange-60) 0%,
		transparent var(--g-spread)
	);

	/* yellow */
	--bg-gradient-yellow-top-left: radial-gradient(
		circle at var(--g-left) var(--g-top),
		var(--yellow-60) 0%,
		transparent var(--g-spread)
	);
	--bg-gradient-yellow-bottom-left: radial-gradient(
		circle at var(--g-left) var(--g-btm),
		var(--yellow-60) 0%,
		transparent var(--g-spread)
	);
	--bg-gradient-yellow-top-right: radial-gradient(
		circle at var(--g-rt) var(--g-top),
		var(--yellow-60) 0%,
		transparent var(--g-spread)
	);
	--bg-gradient-yellow-bottom-right: radial-gradient(
		circle at var(--g-rt) var(--g-btm),
		var(--yellow-60) 0%,
		transparent var(--g-spread)
	);

	/* green */
	--bg-gradient-green-top-left: radial-gradient(
		circle at var(--g-left) var(--g-top),
		var(--green-60) 0%,
		transparent var(--g-spread)
	);
	--bg-gradient-green-bottom-left: radial-gradient(
		circle at var(--g-left) var(--g-btm),
		var(--green-60) 0%,
		transparent var(--g-spread)
	);
	--bg-gradient-green-top-right: radial-gradient(
		circle at var(--g-rt) var(--g-top),
		var(--green-60) 0%,
		transparent var(--g-spread)
	);
	--bg-gradient-green-bottom-right: radial-gradient(
		circle at var(--g-rt) var(--g-btm),
		var(--green-60) 0%,
		transparent var(--g-spread)
	);

	/* blue */
	--bg-gradient-blue-top-left: radial-gradient(
		circle at var(--g-left) var(--g-top),
		var(--blue-60) 0%,
		transparent var(--g-spread)
	);
	--bg-gradient-blue-bottom-left: radial-gradient(
		circle at var(--g-left) var(--g-btm),
		var(--blue-60) 0%,
		transparent var(--g-spread)
	);
	--bg-gradient-blue-top-right: radial-gradient(
		circle at var(--g-rt) var(--g-top),
		var(--blue-60) 0%,
		transparent var(--g-spread)
	);
	--bg-gradient-blue-bottom-right: radial-gradient(
		circle at var(--g-rt) var(--g-btm),
		var(--blue-60) 0%,
		transparent var(--g-spread)
	);

	/* purple */
	--bg-gradient-purple-top-left: radial-gradient(
		circle at var(--g-left) var(--g-top),
		var(--purple-60) 0%,
		transparent var(--g-spread)
	);
	--bg-gradient-purple-bottom-left: radial-gradient(
		circle at var(--g-left) var(--g-btm),
		var(--purple-60) 0%,
		transparent var(--g-spread)
	);
	--bg-gradient-purple-top-right: radial-gradient(
		circle at var(--g-rt) var(--g-top),
		var(--purple-60) 0%,
		transparent var(--g-spread)
	);
	--bg-gradient-purple-bottom-right: radial-gradient(
		circle at var(--g-rt) var(--g-btm),
		var(--purple-60) 0%,
		transparent var(--g-spread)
	);

	/* NON-STANDARD GRADIENTS */
	--bg-gradient-blue-dp: radial-gradient(
		ellipse at bottom center,
		var(--blue),
		var(--blue-dp) 60%,
		var(--black) 140%
	);

	--bg-gradient-accent: radial-gradient(
		ellipse at bottom center,
		var(--blue) 0%,
		var(--blue-30) 100%
	);

	--bg-gradient-green: radial-gradient(
		ellipse at bottom center,
		var(--green-dk),
		var(--green-dp) 30%,
		var(--gray-dp) 80%
	);

	--bg-gradient-yellow-purple: radial-gradient(
			circle at 100% 100%,
			var(--purple-60) 0%,
			transparent 50%
		),
		radial-gradient(circle at 0% 0%, var(--yellow-60) 0%, transparent 50%);

	--bg-gradient-gray: radial-gradient(
			ellipse at bottom center,
			var(--site-bg),
			var(--gray-10)
		);

	--bg-gradient-gray-rev: radial-gradient(
		ellipse at bottom center,
		var(--gray-20),
		var(--site-bg)
	);

	--bg-gradient-dk: radial-gradient(
		ellipse at bottom center,
		var(--black) 50%,
		var(--blue-dp)
	);


	--bg-gradient-white: radial-gradient(
			ellipse at bottom center,
			var(--white),
			var(--bg-50)
		),
		linear-gradient(var(--white), var(--white-50));

	--bg-dk-overlay: radial-gradient(
		ellipse at bottom center,
		var(--blue-dp-60),
		var(--black-60)
	);
}
