/* ------------ FORM SECTION ------------ */

/*
Field value restrictions:
  -	State must be full text, no abbreviations
  -	ZIP must be five digits, include leading zeros
  -	Country must be full text, no abbreviations
  -	Text and textarea field values should max at 254 characters
*/

:root {
    --form-grid-gap: calc(var(--grid-gap) * 2);
}

form {
    --input-text-col: var(--text-col);
    --input-bg-col: var(--site-bg);
    --input-border-col: var(--text-col-10);
    container-type: inline-size;
}

form section {
    display: grid;
    row-gap: var(--grid-gap);
    overflow: visible;
    margin-bottom: var(--spc-lg-h);
}

fieldset {
    width: 100%;
    /* border-radius: var(--rnd-sm); */
    border: none;
    /* border-top: 2px solid var(--input-border-col); */
}

legend {
    color: var(--input-text-col);
}

.two-column-img-right.form > .section-content-wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'details img';
}

form.dark,
#thank-you-wrapper.dark,
#form-section.dark,
#form-section.dark form {
    --neumorph-md: var(--neumorph-md-dk);
    --input-text-col: var(--white);
    --input-bg-col: var(--black-80);
    --input-border-col: var(--white-10);
    background-color: var(--blue-dp);
    color: var(--white);
    /* padding: var(--xl4); */
}

#form-section h2,
#thank-you-wrapper h2,
#form-section h3,
#thank-you-wrapper h3,
#form-section h4,
#thank-you-wrapper h4,
#form-section h5,
#thank-you-wrapper h5,
#form-section h6,
#thank-you-wrapper h6,
#form-section p,
#thank-you-wrapper p,
#form-section legend,
#form-section label,
#form-section input,
#form-section .footnote {
    color: var(--input-text-col);
}

#form-section .glass-panel {
    width: clamp(290px, 100%, 800px);
}

.rebate-form-wrapper {
    --grid-gap: var(--lg);
    margin: auto;
    min-height: 200px;
}

.rebate-form-wrapper > p {
    margin: 0;
}

.standard-form-wrapper {
    --grid-gap: var(--lg);
    margin: var(--lg) auto;
    padding: var(--lg);
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--grid-gap);
}

.standard-form-wrapper #location-info-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--grid-gap);
    margin-top: var(--sm);
}

.contact-info-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--spc-lg-h) var(--spc-md-w);
    margin-top: var(--sm);
}

.rebate-form-wrapper #location-info-wrapper {
    padding: 0 var(--lg);
    display: grid;
    grid-column: span 2;
    grid-template-columns: auto auto;
    gap: var(--grid-gap);
    margin: var(--std) auto;
    place-items: center;
    place-content: center;
}

.rebate-form-wrapper #location-info-wrapper h2 {
    grid-area: unset;
    margin-top: var(--md);
}

.rebate-form-wrapper #location-info-wrapper .btn {
    margin: 0;
}

.ira-incentive-info-box {
    background-color: var(--input-bg-col);
    padding: var(--spc-xs-h) var(--spc-md-w);
    margin: var(--spc-md-h) 0;
    border-radius: var(--rnd-md);
}

.ira-incentive-info-box h5 {
    text-align: center;
    margin-bottom: var(--spc-md-h);
}

.ira-incentive-info-box ul {
    -webkit-padding-start: var(--spc-sm-w);
    padding-inline-start: var(--spc-sm-w);
}

.ira-incentive-info-box li {
    margin-bottom: var(--spc-xs-h);
}

#subscriber-form {
    display: grid;
    place-items: center;
    gap: var(--spc-md-h);
}

#subscriber-form input[type='email'] {
    width: clamp(250px, 25vw, 320px);
}

#lock-rebate {
    grid-column-start: span 3;
    margin: var(--lg) 0;
}

#lock-rebate * {
    line-height: var(--lg);
    text-align: center;
}

#new-system-wrapper {
    display: grid;
    gap: var(--grid-gap);
    margin-top: var(--sm);
    margin: auto;
    margin-bottom: var(--md);
    width: 100%;
}

.input-wrapper {
    position: relative;
}

#country-wrapper,
#state-wrapper {
    position: relative;
}

/* #zip-wrapper {
	display: grid;
	justify-items: center;
} */

#zip {
    width: 150px;
}

#mex-state-wrapper {
    margin: 0 0 0 auto;
}

#location-message {
    text-align: center;
}

#rebate-wrapper {
    border-bottom: var(--solid) var(--input-border-col);
    padding-bottom: 0;
    margin-bottom: var(--lg);
}

#rebate-wrapper h1,
#rebate-wrapper h2 {
    margin: 0;
    grid-area: unset;
}

#rebate-wrapper .footnote {
    text-align: left;
}

.incentives-wrapper {
    margin-top: var(--spc-sm-h);
    margin-bottom: var(--spc-lg-h);
}

.incentives-wrapper p {
    margin: 0;
}

.incentive-row {
    display: grid;
    align-items: center;
    /* justify-content: center; */
    gap: var(--spc-md-w);
    grid-template-columns: 3fr 2fr;
    margin: var(--spc-sm-h) 0;
}

.incentive-name {
    text-align: end;
}

.incentive-amount {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: var(--main-font-bold);
    font-size: 2.7rem;
}

.incentive-amount sup {
    font-family: var(--main-font);
    font-size: 1.3rem;
}

.incentive-amount .btn {
    translate: 0 -1px;
}

.rebate-message {
    text-align: center;
    margin: var(--spc-sm-h) auto;
}

.incentive-modal-type-heading {
    font-family: var(--main-font-bold);
    padding: 0;
    padding-bottom: 4px;
    margin: 0;
    border-bottom: var(--border);
}

.incentive-modal-disclaimer {
    font-size: 1.2rem;
    margin-bottom: 0;
}

#rebate-amount-wrapper {
    text-align: center;
    margin: auto;
    translate: 0 -6px;
}

#rebate-amount {
    letter-spacing: -1px;
}

#rebate-superscript {
    font-size: 12px;
    letter-spacing: -1.5px;
}

#rebate-btn-wrapper {
    display: grid;
    place-content: center;
}

#get-location-btn {
    width: auto;
    color: var(--accent);
    cursor: pointer;
    box-shadow: var(--shadow-md);
    text-transform: none;
    place-items: center;
    z-index: 10;
}

#get-location-btn * {
    color: var(--white);
}

.locate-btn-text {
    margin-left: 5px;
}

#get-location-btn:hover {
    box-shadow: var(--shadow-close);
}

.form-instructions {
    transition: var(--fade-slow);
}

#lead-form-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--grid-gap);
}

#rebate-footnote {
    text-align: left;
    margin: var(--lg) auto 0 auto;
    border-top: 1px solid var(--site-bg-50);
}

label.offset-label {
    position: absolute;
    left: 10px;
    top: 8px;
    transition: var(--fade);
    z-index: 1;
}

input,
textarea,
select {
    width: 100%;
    background-color: var(--input-bg);
    border: 2px solid transparent;
    border-radius: var(--corner-radius-sm);
    padding: var(--xs);
    color: var(--input-text-col);
    box-shadow: var(--neumorph-md);
    position: relative;
    transition: var(--fade);
    z-index: 2;
}

input,
select {
    height: 40px;
}

input.valid,
select.valid,
textarea.valid {
    box-shadow: var(--shadow-close);
    background-color: var(--input-bg-col);
}

a.btn.secondary:disabled,
input:disabled,
select:disabled,
textarea:disabled {
    opacity: 0.4;
    box-shadow: none;
}

input[type='submit']:disabled {
    border: none;
    background-color: var(--accent);
    cursor: not-allowed;
}

#dealer-select-input:disabled + label.offset-label {
    opacity: 0;
}
input.invalid + label.offset-label {
    font-size: smaller;
}

input:focus + label.offset-label,
input:active + label.offset-label,
input.valid + label.offset-label,
input.invalid + label.offset-label,
select:focus + label.offset-label,
select:active + label.offset-label,
select.valid + label.offset-label,
select.invalid + label.offset-label,
textarea:focus + label.offset-label,
textarea:active + label.offset-label,
textarea.valid + label.offset-label,
textarea.invalid + label.offset-label {
    left: 0;
    top: -20px;
    font-size: smaller;
}

input:focus + label.offset-label.zip,
input:active + label.offset-label.zip,
input.valid + label.offset-label.zip {
    margin-left: 0;
    left: 10px;
    top: 8px;
    opacity: 0;
}

input[type='checkbox'] + label::after,
input[type='radio'] + label::after {
    content: '';
}

.required::after {
    content: '*';
}

select,
textarea,
input[type='text'],
input[type='tel'],
input[type='email'],
input[type='submit'] {
    -webkit-appearance: none;
}

textarea {
    resize: none;
}

select::-ms-expand {
    display: none;
}

input:focus,
select:focus,
textarea:focus {
    background-color: var(--input-bg-col);
    box-shadow: var(--shadow-tight);
    outline: var(--outline);
}

/* Change the default autofilled BG and text color ;) */

/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active,
input:-internal-autofill-selected {
    -webkit-text-fill-color: var(--input-text-col) !important;
    -webkit-box-shadow: 0 0 0px 1000px var(--input-bg-col) inset !important;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
    background-color: var(--input-bg-col) !important;
} */

::-webkit-input-placeholder {
    color: transparent;
}

::-moz-placeholder {
    color: transparent;
}

:-ms-input-placeholder {
    color: transparent;
}

::-ms-input-placeholder {
    color: transparent;
}

::placeholder {
    color: transparent;
}

input[type='checkbox'] {
    height: 24px;
    width: 24px;
    box-shadow: none;
    border: 2px solid var(--input-text-col);
}

input[type='radio'] {
    margin: 0;
}

select.invalid,
textarea.invalid,
input.invalid {
    border: 2px solid var(--red-50);
    background-color: var(--input-bg-col);
    box-shadow: none;
}

select.valid,
textarea.valid,
input.valid {
    border: 2px solid var(--green-50);
    box-shadow: none;
}

.radio-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 500px;
    gap: 10px;
    margin: 0 auto;
    padding: 0;
}

.radio-group > div {
    display: grid;
    /* grid-template-columns: 24px auto; */
    align-items: center;
    gap: 5px;
}

.radio-title {
    margin-bottom: var(--spc-xs-h);
    font-family: var(--headline-font);
    text-align: center;
}

.radio-group label,
#communications-wrapper label {
    font-size: var(--std);
    padding: 6px;
    /* background-color: var(--input-bg-col); */
    border-radius: var(--rnd-sm);
    border: 2px solid var(--input-border-col);
    display: inline-block;
    cursor: pointer;
    transition: var(--fade);
    min-width: 60px;
}

.radio-group label {
    text-align: center;
}

#communications-wrapper label {
    border: none;
    padding: 0;
}

label:has(input[type='radio']:checked) {
    background-color: var(--input-bg-col);
    border: 2px solid var(--input-text-col);
}

.radio-group > div:focus-within label {
    outline: var(--outline);
}

#product-form-submit,
#bu-form-submit {
    width: 100%;
    display: block;
}

#service-wrapper {
    margin: auto;
    margin-top: var(--std);
    margin-bottom: var(--xl);
    width: 100%;
}

.radio-wrapper {
    display: grid;
    gap: var(--md);
    grid-template-columns: 1fr;
    align-items: center;
}

.radio-wrapper .radio-group {
    margin: 0;
}

.radio-wrapper p {
    margin: 0;
}

#communications-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    place-content: center;
    place-items: start;
    place-self: center;
    gap: 10px;
    margin: var(--lg) 0;
}

.final-form-checkbox-wrapper {
    display: grid;
    grid-gap: 14px;
    grid-template-columns: 1fr auto;
    place-items: center;
    margin: 0;
}

#form.form-success-wrapper h3 {
    color: var(--input-text-col);
    text-align: center;
}

.autocomplete-input-wrapper {
    position: relative;
}

.suggestions-wrapper {
    /* position: absolute; */
    padding: var(--spc-sm);
    background-color: var(--black-80);
    -webkit-backdrop-filter: var(--blur);
    backdrop-filter: var(--blur);
    border-radius: var(--rnd-md);
    width: 100%;
    max-height: 300px;
    overflow: scroll;
    z-index: 99;
}

.suggestions-wrapper ul {
    list-style-type: circle;
    -webkit-padding-start: var(--spc-sm-w);
    padding-inline-start: var(--spc-sm-w);
}

.suggestions-wrapper li {
    color: var(--white);
    font-family: var(--main-font-bold);
    line-height: 1.5em;
    cursor: pointer;
}

.suggestions-wrapper li:hover {
    text-decoration: underline;
}

/*---------------- Diamond Product Finder UI ----------------*/

.product-selector-page {
    padding: var(--spc-xl3-h) 0 0 var(--xl2);
    background-color: var(--gray-10);
    grid-template-columns: 1fr auto;
    align-items: flex-start;
    min-height: 100vh;
}

.product-selector-wrapper {
    max-width: 1400px;
    margin: 0;
}

.product-selector-hero {
    background-color: var(--gray-10);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--grid-gap);
    padding: var(--xl);
    padding-top: var(--xl6);
    padding-bottom: var(--lg);
    align-items: flex-start;
    /* border-bottom: 2px solid var(--site-bg-50); */
}

#product-selector-form {
    padding: 0;
    position: relative;
}

#product-jump-to-results {
    color: var(--accent);
    box-shadow: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

/* Inputs Area ----------------*/
.multi-input-row {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: calc(var(--grid-gap) * 3);
}

.product-selector-input-wrapper {
    --btn-size: clamp(40px, 5vw, 55px);
    display: grid;
    grid-template-columns: 160px 1fr;
    gap: calc(var(--grid-gap) * 0.8);
    margin: var(--xl) 0;
    min-height: 70px;
    width: fit-content(1000px);
}

.product-selector-input-wrapper h6 {
    position: relative;
    margin: 0;
    padding-top: var(--base);
    cursor: unset;
}

span.product-selector-tooltip {
    position: absolute;
    display: inline-flex;
    place-items: center;
    place-content: center;
    top: 2px;
    margin-left: 10px;
    text-align: center;
    border-radius: 100%;
    background-color: inherit;
    box-shadow: var(--neumorph-close);
    font-size: medium;
    font-family: var(--headline-font);
    vertical-align: middle;
    cursor: pointer;
    height: var(--lg);
    width: var(--lg);
    transition: var(--fade);
}

span.product-selector-tooltip::before {
    content: attr(data-tooltip);
    width: clamp(150px, 20vw, 500px);
    color: var(--text-col);
    font-family: var(--main-font);
    font-size: var(--base);
    font-weight: normal;
    line-height: 15px;
    min-height: 20px;
    max-height: 150px;
    position: absolute;
    left: var(--lg);
    top: var(--lg);
    padding: 5px 10px;
    display: none;
    background-color: var(--site-bg-80);
    -webkit-backdrop-filter: var(--blur);
    backdrop-filter: var(--blur);
    border: 2px solid var(--gray);
    border-radius: var(--rnd-md);
    border-top-left-radius: 0;
    box-shadow: var(--shadow-full);
    z-index: 9;
}

span.product-selector-tooltip:hover {
    background-color: var(--input-bg-col);
}

span.product-selector-tooltip:hover::before {
    display: inline-block;
}

.btn-row {
    display: grid;
    -moz-column-gap: calc(var(--grid-gap) * 1.2);
    column-gap: calc(var(--grid-gap) * 1.2);
    row-gap: var(--grid-gap-sm);
    grid-template-columns: repeat(auto-fill, var(--xl6));
    justify-content: start;
    align-items: center;
    transition: var(--fade);
}

.btn-row.number {
    -moz-column-gap: calc(var(--grid-gap) * 0.6);
    column-gap: calc(var(--grid-gap) * 0.6);
    row-gap: var(--base);
    grid-template-columns: repeat(auto-fill, var(--btn-size));
}

.image-btn,
.number-btn {
    background-color: var(--neu-btn);
    border: 2px solid var(--neu-btn);
    box-shadow: var(--neumorph-md);
}

.image-btn {
    display: flex;
    place-content: center;
    cursor: pointer;
    padding: var(--base);
    width: var(--xl7);
    height: var(--xl7);
    border-radius: var(--rnd-md);
    transition: var(--fade);
}

.image-btn div {
    display: grid;
    grid-template-rows: auto auto;
    gap: var(--xs);
    place-content: center;
    place-items: center;
    transition: var(--fade);
}

.image-btn:hover {
    background-color: var(--input-bg-col);
    /*box-shadow: var(--neumorph-close);*/
}

.image-btn.selected::after {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--corner-radius-lg);
    box-shadow: var(--neumorph-inset);
    transition: var(--fade);
}

.image-btn img {
    -o-object-position: bottom;
    object-position: bottom;
    width: clamp(70px, 20vw, 90px);
    max-height: 45px;
}

.image-btn h6 {
    margin: 0;
    text-align: center;
    text-transform: capitalize;
    transition: var(--fade);
    line-height: var(--md);
    /* font-size: medium; */
    padding-top: unset;
}

.image-btn.selected h6 {
    color: var(--accent);
}

.toggle-wrapper {
    --toggle-height: calc(var(--base) * 5);
    --toggle-width: calc(var(--toggle-height) * 2);
    position: relative;
    box-shadow: var(--neumorph-close-inset);
    border-radius: var(--toggle-height);
    height: var(--toggle-height);
    width: var(--toggle-width);
    background-color: var(--bg-50);
    cursor: pointer;
}

.toggle-wrapper:hover {
    background-color: var(--input-bg-col);
}

.toggle-wrapper,
.toggle-wrapper * {
    transition: var(--fade);
}

.toggle-wrapper.on {
    background-color: var(--red);
}

.toggle-wrapper.low_ambient_cooling.on {
    background-color: var(--blue);
}

.toggle-wrapper h6 {
    position: absolute;
    top: calc(var(--toggle-height) / 5);
    font-size: calc(var(--toggle-height) / 2.5);
    padding: 0;
    margin: 0;
}

.toggle-wrapper .k,
.toggle-wrapper .ft {
    font-size: 0.7em;
}

.toggle-btn {
    position: absolute;
    left: 0;
    border-radius: 100%;
    height: var(--toggle-height);
    width: var(--toggle-height);
    box-shadow: var(--shadow-tight), var(--shadow-close);
    background: var(--white);
}

.toggle-wrapper.on .toggle-btn {
    left: var(--toggle-height);
}

.on-text {
    color: var(--white);
    left: 11px;
}

.off-text {
    color: var(--gray);
    right: 11px;
}

.numbers-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, var(--xl4));
    /* place-items: center; */
    row-gap: var(--lg);
    /*place-content: center;*/
    width: 100%;
    transition: var(--fade);
}

/*.toggle-wrapper.numbers:hover {*/
/*	background-color: var(--input-bg-col);*/
/*}*/

.number-btn {
    display: flex;
    place-content: center;
    place-items: center;
    width: var(--btn-size);
    height: var(--btn-size);
    border-radius: 100%;
    cursor: pointer;
    transition: var(--fade);
}

.number-btn:hover {
    background-color: var(--input-bg-col);
}

.number-btn h6 {
    position: unset;
    display: inline-block;
    padding-top: unset;
    transition: var(--fade);
}

.number-btn .k,
.number-btn .ft {
    font-size: var(--base);
}

.image-btn.selected,
.number-btn.selected {
    color: var(--accent);
    transform: scale(1.1);
    background-color: var(--input-bg-col);
    /* border: 1px solid var(--bg); */
    box-shadow: unset;
}

.number-btn.selected h6 {
    color: var(--accent);
}

.image-btn.disabled,
.number-btn.disabled {
    opacity: 0.5;
    box-shadow: var(--neumorph-tight);
    cursor: not-allowed;
    background-color: unset;
}

.number-btn.disabled h6 {
    font-size: 1em;
}

.image-btn.disabled:hover,
.number-btn.disabled:hover {
    background-color: unset;
}

/* Slider input -------------------*/
.slider-input-wrapper {
    --slider-width: clamp(200px, 100%, 800px);
    display: flex;
    flex-direction: column;
}

.box-minmax {
    margin-top: 12px;
    width: var(--slider-width);
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    color: var(--accent);
}

.slider-input-wrapper span:first-child {
    margin-left: 10px;
}

/* .range-slider {
	margin-top: 30vh;
} */

input.rs-range {
    margin-top: 29px;
    width: var(--slider-width);
    padding: var(--base);
    background-color: var(--bg-50);
    box-shadow: var(--neumorph-tight-inset);
    -webkit-appearance: none;
}

.rs-range:focus {
    outline: var(--outline);
}

.rs-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0;
    cursor: pointer;
    box-shadow: none;
    background: var(--input-bg-col);
    border-radius: 0px;
    border: 0px solid #010101;
}

.rs-range::-moz-range-track {
    width: 100%;
    height: 0;
    cursor: pointer;
    box-shadow: none;
    /* background: #ffffff; */
    border-radius: 0px;
    border: 0px solid #010101;
}

.rs-range::-webkit-slider-thumb {
    box-shadow: none;
    border: 0px solid #ffffff;
    box-shadow: var(--neumorph-md);
    height: 40px;
    width: 22px;
    border-radius: 22px;
    background: var(--bg);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -20px;
    content: attr(value);
}

.rs-range::-moz-range-thumb {
    box-shadow: none;
    border: 0px solid #ffffff;
    box-shadow: var(--neumorph-md);
    height: 40px;
    width: 22px;
    border-radius: 22px;
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -20px;
    content: attr(value);
}

.rs-range::-moz-focus-outer {
    border: 0;
}

.rs-label {
    position: relative;
    transform-origin: center center;
    display: block;
    text-align: center;
    font-weight: bold;
    color: var(--accent);
}

/* Results Area ----------------*/
.mobile-bar,
#mobile-instructions {
    display: none;
}

.model-no-search-results {
    display: grid;
    gap: var(--spc-lg-h);
    background-color: var(--input-bg-col);
    padding: var(--spc-md);
    border-radius: var(--rnd-md);
}

#desktop-instructions {
    width: 546px;
    position: relative;
    padding: 0 var(--xl2) var(--xl);
    overflow-x: auto;
}

#product-selector-results {
    width: 546px;
    height: calc(96vh - var(--header-ht));
    position: relative;
    padding: 0 var(--xl2) var(--xl);
    /* background-color: var(--bg-50); */
    overflow-x: auto;
}

.results-buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: var(--spc-lg-h);
}

.results-buttons-wrapper button {
    margin: 0;
    padding: var(--xs) var(--base);
}

#filters-bar {
    margin: var(--md) 0;
}

#product-selector-results > h2 {
    text-align: center;
    margin-bottom: 0;
}

.product-filter {
    display: inline-block;
    text-transform: capitalize;
    font-size: smaller;
    margin: 0 0 var(--sm) var(--sm);
    padding: var(--sm) var(--base);
    background-color: var(--input-bg-col);
    border-radius: var(--corner-radius-lg);
}

.csv-download {
    margin-top: var(--spc-sm-h);
    padding: var(--xs) var(--md);
}

.product-listing-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, 450px);
    gap: var(--grid-gap-sm);
    justify-content: center;
    margin-top: var(--lg);
}

.product-card {
    position: relative;
    background-color: var(--input-bg-col);
    border-radius: var(--corner-radius-lg);
    padding: var(--lg);
    cursor: pointer;
}

.product-card > .product-selector-tooltip {
    position: absolute;
    margin: 0;
    bottom: unset;
    top: var(--base);
    left: var(--base);
}

.product-card-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--grid-gap-sm);
    height: 100%;
}

.product-card-logo {
    display: flex;
    max-width: 140px;
    max-height: 100px;
    margin: auto;
    margin-top: var(--sm);
    padding-bottom: var(--md);
}

.product-card-header h6 {
    /* text-align: center; */
    position: relative;
    font-family: var(--main-font-bold);
    color: var(--accent);
    padding-top: var(--base);
    margin: 0;
    margin-bottom: var(--sm);
    border-top: 1px solid var(--accent);
}

.product-card-header p {
    font-family: var(--condensed-font);
    color: var(--text-col-50);
    margin: 0;
    font-size: medium;
}

.modal .product-card-logo {
    margin-top: var(--lg);
    margin-bottom: var(--sm);
    max-width: 200px;
    max-height: 160px;
}

.indoor-units-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    -moz-column-gap: var(--md);
    column-gap: var(--md);
    row-gap: var(--xs);
    margin: var(--xs) 0 0 var(--xs);
    max-height: 20vh;
    overflow-y: auto;
    /* place-content: center; */
}

.indoor-units-title {
    margin: 0;
}
.indoor-units-wrapper img {
    height: 80px;
    -o-object-position: center;
    object-position: center;
}

.unit-specs {
    display: grid;
    grid-template-columns: auto auto auto;
    margin-top: var(--md);
    row-gap: 2px;
    -moz-column-gap: var(--base);
    column-gap: var(--base);
}

.unit-specs p {
    font-family: var(--condensed-font);
    text-transform: capitalize;
    margin: 0;
    font-size: var(--std);
}

.single-select-btn-row {
    display: flex;
    box-shadow: var(--shadow-far);
    border-radius: var(--corner-radius-sm);
}

.single-select-btn-row button {
    border-radius: 0;
    min-width: 100px;
    box-shadow: unset;
    outline: none;
    /* border-right: 2px solid var(--black-30); */
    margin: 0;
}

.single-select-btn-row button:first-child {
    border-top-left-radius: var(--corner-radius-sm);
    border-bottom-left-radius: var(--corner-radius-sm);
}

.single-select-btn-row button:last-child {
    border-top-right-radius: var(--corner-radius-sm);
    border-bottom-right-radius: var(--corner-radius-sm);
    /* border-right: unset; */
}

.single-select-btn-row button.selected {
    background-color: var(--accent-dk);
    box-shadow: var(--shadow-close-inset);
}

/*---------------- Mobile Styles ----------------*/
@media screen and (max-width: 1300px) {
    .product-selector-page {
        padding-left: var(--xl);
        padding-right: var(--xl);
        grid-template-columns: unset;
        min-height: 100vh;
    }

    .product-selector-wrapper {
        overflow: unset;
    }

    #product-jump-to-results {
        display: unset;
    }

    #product-selector-results {
        width: unset;
        width: 100%;
        height: 100%;
        min-height: 100%;
        background-color: unset;
        padding: unset;
        /* margin-top: var(--xl); */
        overflow: auto;
        /* border-radius: var(--corner-radius-lg) var(--corner-radius-lg) 0 0; */
    }

    #desktop-bar,
    #desktop-instructions {
        display: none;
    }

    #mobile-instructions {
        display: inline-block;
        grid-template-columns: auto auto;
    }

    #mobile-btns a.secondary {
        box-shadow: unset;
        /* padding: unset; */
        font-family: unset;
        background-color: unset;
        color: var(--accent);
    }

    .mobile-bar {
        position: -webkit-sticky;
        position: sticky;
        bottom: var(--base);
        display: flex;
        -moz-column-gap: var(--lg);
        column-gap: var(--lg);
        align-items: center;
        justify-content: center;
        padding: var(--base);
        margin-top: var(--xl2);
        border-radius: var(--corner-radius-sm);
        background-color: var(--text-col);
        box-shadow: var(--shadow-full);
    }

    .mobile-bar p {
        color: var(--site-bg);
    }

    .mobile-bar * {
        margin: 0;
    }

    .product-listing-wrapper {
        padding-bottom: var(--xl);
    }
}

@container (max-width: 1200px) {
    .two-column-img-right.form > .section-content-wrapper {
        /* padding: 0 var(--xl); */
        gap: var(--grid-gap);
    }
    .rebate-form #form,
    .product-form #form {
        margin-bottom: var(--xl3);
    }
    .product-selector-hero {
        grid-template-columns: unset;
        gap: 0;
    }
    .multi-input-row {
        grid-template-columns: 1fr;
        gap: var(--xl2);
        margin-bottom: var(--xl2);
    }
    .product-selector-input-wrapper {
        grid-template-columns: unset;
        align-items: start;
    }

    .multi-input-row .product-selector-input-wrapper {
        margin: 0;
    }

    .btn-row {
        margin-left: var(--lg);
    }
}

@container (max-width: 900px) {
    .form-hero {
        height: unset;
    }
    .two-column-img-right.form > .section-content-wrapper {
        gap: var(--grid-gap);
        grid-template-areas:
            'details details'
            'img img';
    }
    .two-column-img-right.form .details h2,
    .two-column-img-right.form .details h3 {
        text-align: center;
    }
}

@container (max-width: 768px) {
    section#form-section {
        padding-left: 0;
        padding-right: 0;
    }
    .standard-form-wrapper,
    .rebate-form-wrapper {
        --form-grid-gap: calc(var(--grid-gap) * 1.25);
        border: none;
    }

    #form-section.dark {
        padding: var(--xl3) 0;
        width: 100%;
    }
    #form-section.dark h2 {
        margin-bottom: 0;
    }

    .location-info-wrapper {
        margin-top: var(--md);
    }

    #zip-wrapper > input {
        width: 100px;
    }

    .offset-label#zip_label {
        margin-left: 0px;
    }

    #get-location-btn > span {
        display: none;
    }

    .final-form-checkbox-wrapper {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .radio-wrapper,
    .radio-group {
        grid-template-columns: 1fr;
        gap: 10px;
    }

    .product-selector-page {
        padding-left: var(--md);
        padding-right: var(--md);
    }

    .btn-row {
        margin-left: var(--base);
    }

    .number-btn,
    .image-btn {
        box-shadow: var(--neumorph-close);
    }

    .csv-download {
        padding: var(--xs) var(--sm);
    }

    #product-jump-to-results {
        top: var(--base);
    }

    .product-listing-wrapper {
        grid-template-columns: clamp(100px, 80vw, 400px);
    }

    #product-selector-results {
        padding: 0;
        background-color: unset;
        /* border-top: 2px solid var(--site-bg); */
        border-radius: 0;
    }
}

@media screen and (max-width: 560px) {
    .unit-specs {
        grid-template-columns: auto auto;
    }

    .product-card-header.preview {
        display: unset;
    }

    .product-card-header.preview .product-card-logo,
    .indoor-units-title,
    .indoor-units-wrapper {
        display: none;
    }
}

@media screen and (max-width: 400px) {
    /* .product-card-header {
		display: unset;
	} */
    .product-selector-input-wrapper {
        margin: var(--xl2) 0;
    }

    span.product-selector-tooltip::before {
        left: -24px;
        top: var(--xl);
        border-top-left-radius: var(--rnd-md);
    }
}
