/* 
    Old branding font faces
*/

@font-face {
	font-family: 'Gotham-Bold';
	src: url('./fonts/Gotham-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Frutiger';
	src: url('./fonts/FrutigerLTPro-Roman.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Frutiger-Bold';
	src: url('./fonts/FrutigerLTPro-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Frutiger-Condensed';
	src: url('./fonts/FrutigerLTPro-Condensed.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Frutiger-Condensed-Bold';
	src: url('./fonts/FrutigerLTPro-BoldCn.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

/*
    New branding font faces 
*/

/* Backup Google font face - Poppins */
@font-face {
	font-family: 'Poppins 700 I';
	src: url('./fonts/Poppins-BoldItalic.woff') format('woff2');
	font-weight: 700;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: 'Poppins 700';
	src: url('./fonts/Poppins-Bold.woff') format('woff2');
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Poppins 400 I';
	src: url('./fonts/Poppins-Italic.woff') format('woff2');
	font-weight: 400;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: 'Poppins 400';
	src: url('./fonts/Poppins-Regular.woff') format('woff2');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Poppins 300 I';
	src: url('./fonts/Poppins-LightItalic.woff') format('woff2');
	font-weight: 300;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: 'Poppins 300';
	src: url('./fonts/Poppins-Light.woff') format('woff2');
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Poppins 200 I';
	src: url('./fonts/Poppins-ExtraLightItalic.woff') format('woff2');
	font-weight: 200;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: 'Poppins 200';
	src: url('./fonts/Poppins-ExtraLight.woff') format('woff2');
	font-weight: 200;
	font-style: normal;
	font-stretch: normal;
}

/* Primary font face */
@font-face {
	font-family: 'Styrene A 900 I';
	src: url('./fonts/StyreneA-BlackItalic-Web.woff2') format('woff2'),
		url('./fonts/StyreneA-BlackItalic-Web.woff') format('woff');
	font-weight: 900;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: 'Styrene A 900';
	src: url('./fonts/StyreneA-Black-Web.woff2') format('woff2'),
		url('./fonts/StyreneA-Black-Web.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Styrene A 700 I';
	src: url('./fonts/StyreneA-BoldItalic-Web.woff2') format('woff2'),
		url('./fonts/StyreneA-BoldItalic-Web.woff') format('woff');
	font-weight: 700;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: 'Styrene A 700';
	src: url('./fonts/StyreneA-Bold-Web.woff2') format('woff2'),
		url('./fonts/StyreneA-Bold-Web.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Styrene A 500 I';
	src: url('./fonts/StyreneA-MediumItalic-Web.woff2') format('woff2'),
		url('./fonts/StyreneA-MediumItalic-Web.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: 'Styrene A 500';
	src: url('./fonts/StyreneA-Medium-Web.woff2') format('woff2'),
		url('./fonts/StyreneA-Medium-Web.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Styrene A 400 I';
	src: url('./fonts/StyreneA-RegularItalic-Web.woff2') format('woff2'),
		url('./fonts/StyreneA-RegularItalic-Web.woff') format('woff');
	font-weight: 400;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: 'Styrene A 400';
	src: url('./fonts/StyreneA-Regular-Web.woff2') format('woff2'),
		url('./fonts/StyreneA-Regular-Web.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Styrene A 300 I';
	src: url('./fonts/StyreneA-LightItalic-Web.woff2') format('woff2'),
		url('./fonts/StyreneA-LightItalic-Web.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: 'Styrene A 300';
	src: url('./fonts/StyreneA-Light-Web.woff2') format('woff2'),
		url('./fonts/StyreneA-Light-Web.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Styrene A 100 I';
	src: url('./fonts/StyreneA-ThinItalic-Web.woff2') format('woff2'),
		url('./fonts/StyreneA-ThinItalic-Web.woff') format('woff');
	font-weight: 100;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: 'Styrene A 100';
	src: url('./fonts/StyreneA-Thin-Web.woff2') format('woff2'),
		url('./fonts/StyreneA-Thin-Web.woff') format('woff');
	font-weight: 100;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Styrene B 900 I';
	src: url('./fonts/StyreneB-BlackItalic-Web.woff2') format('woff2'),
		url('./fonts/StyreneB-BlackItalic-Web.woff') format('woff');
	font-weight: 900;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: 'Styrene B 900';
	src: url('./fonts/StyreneB-Black-Web.woff2') format('woff2'),
		url('./fonts/StyreneB-Black-Web.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Styrene B 700 I';
	src: url('./fonts/StyreneB-BoldItalic-Web.woff2') format('woff2'),
		url('./fonts/StyreneB-BoldItalic-Web.woff') format('woff');
	font-weight: 700;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: 'Styrene B 700';
	src: url('./fonts/StyreneB-Bold-Web.woff2') format('woff2'),
		url('./fonts/StyreneB-Bold-Web.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Styrene B 500 I';
	src: url('./fonts/StyreneB-MediumItalic-Web.woff2') format('woff2'),
		url('./fonts/StyreneB-MediumItalic-Web.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: 'Styrene B 500';
	src: url('./fonts/StyreneB-Medium-Web.woff2') format('woff2'),
		url('./fonts/StyreneB-Medium-Web.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Styrene B 400 I';
	src: url('./fonts/StyreneB-RegularItalic-Web.woff2') format('woff2'),
		url('./fonts/StyreneB-RegularItalic-Web.woff') format('woff');
	font-weight: 400;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: 'Styrene B 400';
	src: url('./fonts/StyreneB-Regular-Web.woff2') format('woff2'),
		url('./fonts/StyreneB-Regular-Web.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Styrene B 300 I';
	src: url('./fonts/StyreneB-LightItalic-Web.woff2') format('woff2'),
		url('./fonts/StyreneB-LightItalic-Web.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: 'Styrene B 300';
	src: url('./fonts/StyreneB-Light-Web.woff2') format('woff2'),
		url('./fonts/StyreneB-Light-Web.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Styrene B 100 I';
	src: url('./fonts/StyreneB-ThinItalic-Web.woff2') format('woff2'),
		url('./fonts/StyreneB-ThinItalic-Web.woff') format('woff');
	font-weight: 100;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: 'Styrene B 100';
	src: url('./fonts/StyreneB-Thin-Web.woff2') format('woff2'),
		url('./fonts/StyreneB-Thin-Web.woff') format('woff');
	font-weight: 100;
	font-style: normal;
	font-stretch: normal;
}
