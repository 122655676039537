:root {
    font-size: clamp(9px, 1.5vmin, 10px); /* site's rem baseline*/

    --base: 1rem; /* set base for size calcs */
    --mult: 1.5; /* set multiplier for font scaling */

    --xs4: calc(var(--base) * 0.2); /* 2px */
    --xs3: calc(var(--base) * 0.3); /* 3px */
    --xs2: calc(var(--base) * 0.4); /* 4px */
    --xs: calc(var(--base) * 0.6); /* 6px */
    --sm: calc(var(--base) * 0.9); /* 9px */
    --std: calc(var(--base) * 1.4); /* 14px */
    --md: calc(var(--base) * 1.6); /* 16px */

    --lg: calc(var(--base) * 2.4); /* 24px */
    --xl: calc(var(--base) * 3.6); /* 36px */
    --xl2: calc(var(--base) * 4.8); /* 48px */
    --xl3: calc(var(--base) * 6); /* 60px */
    --xl4: calc(var(--base) * 7.2); /* 72px */
    --xl5: calc(var(--base) * 8.4); /* 84px */
    --xl6: calc(var(--base) * 9.6); /* 96px */
    --xl7: calc(var(--base) * 10.8); /* 108px */
    --xl8: calc(var(--base) * 12); /* 120px */
    --xl9: calc(var(--base) * 13.2); /* 132px */
    --xl10: calc(var(--base) * 36); /* 360px */

    /* --lg: calc(var(--md) * var(--mult));
	--xl: calc(var(--lg) * var(--mult));
	--xl2: calc(var(--xl) * var(--mult));
	--xl3: calc(var(--xl2) * var(--mult));
	--xl4: calc(var(--xl3) * var(--mult));
	--xl5: calc(var(--xl4) * var(--mult));
	--xl6: calc(var(--xl5) * var(--mult)); 
	--xl7: calc(var(--xl6) * var(--mult));
	--xl8: calc(var(--xl7) * var(--mult));
	--xl9: calc(var(--xl8) * var(--mult));
	--xl10: calc(var(--xl9) * var(--mult)); */

    /* --headline-font: 'Gotham-Bold', sans-serif;
	--main-font: 'Frutiger', Arial, sans-serif;
	--main-font-bold: 'Frutiger-Bold', Arial, sans-serif;
	--condensed-font: 'Frutiger-Condensed', Arial, sans-serif;
	--condensed-font-bold: 'Frutiger-Condensed-Bold', Arial, sans-serif;
	--footnote-font: 'Frutiger-Condensed', Arial, sans-serif; */

    --headline-font: 'Styrene A 400', 'Poppins 400', Arial, sans-serif;
    --headline-font-bold: 'Styrene A 700', 'Poppins 700', Arial, sans-serif;
    --headline-font-italic: 'Styrene A 400 I', 'Poppins 400 I', Arial, sans-serif;
    --link-font: 'Styrene A 400', 'Poppins 400', Arial, sans-serif;
    --main-font: 'Poppins 300', Arial, sans-serif;
    --main-font-bold: 'Poppins 700', Arial, sans-serif;
    --main-font-italic: 'Poppins 300 I', Arial, sans-serif;
    --condensed-font: 'Poppins 200', Arial, sans-serif;
    --condensed-font-bold: 'Poppins 400', Arial, sans-serif;
    --condensed-font-italic: 'Poppins 200 I', Arial, sans-serif;

    --spc-xs-h: clamp(var(--xs2), 1vh, var(--sm));
    --spc-xs-w: clamp(var(--xs2), 1vw, var(--sm));
    --spc-xs: var(--spc-xs-h) var(--spc-xs-w);

    --spc-sm-h: clamp(var(--sm), 2vh, var(--md));
    --spc-sm-w: clamp(var(--sm), 2vw, var(--md));
    --spc-sm: var(--spc-sm-h) var(--spc-sm-w);

    --spc-md-h: clamp(var(--md), 3vh, var(--lg));
    --spc-md-w: clamp(var(--md), 3vw, var(--lg));
    --spc-md: var(--spc-md-h) var(--spc-md-w);

    --spc-lg-h: clamp(var(--lg), 4vh, var(--xl));
    --spc-lg-w: clamp(var(--lg), 4vw, var(--xl));
    --spc-lg: var(--spc-lg-h) var(--spc-lg-w);

    --spc-xl-h: clamp(var(--lg), 5vh, var(--xl3));
    --spc-xl-w: clamp(var(--lg), 5vw, var(--xl3));
    --spc-xl: var(--spc-xl-h) var(--spc-xl-w);

    --spc-xl2-h: clamp(var(--xl2), 8vh, var(--xl8));
    --spc-xl2-w: clamp(var(--xl2), 8vw, var(--xl8));
    --spc-xl2: var(--spc-xl2-h) var(--spc-xl2-w);

    --spc-xl3-h: clamp(var(--xl4), 12vh, var(--xl9));
    --spc-xl3-w: clamp(var(--xl4), 12vw, var(--xl9));
    --spc-xl3: var(--spc-xl3-h) var(--spc-xl3-w);

    --spc-xl4-h: clamp(var(--xl4), 20vh, var(--xl10));
    --spc-xl4-w: clamp(var(--xl4), 20vw, var(--xl10));
    --spc-xl4: var(--spc-xl4-h) var(--spc-xl4-w);

    --rnd-sm: var(--xs);
    --rnd-md: var(--md);
    --rnd-lg: var(--lg);

    --border-size: 2px;
    --dot: var(--border-size) dotted;
    --dash: var(--border-size) dashed;
    --solid: var(--border-size) solid;
    --border: var(--solid) var(--text-col);

    --blur: blur(8px);
    --corner-radius-sm: var(--xs);
    --corner-radius-md: calc(var(--corner-radius-sm) * 1.5);
    --corner-radius-lg: calc(var(--corner-radius-sm) * 3);

    --highlight-tight: -1px -1px 2px rgba(255, 255, 255, 0.4);
    --highlight-close: -4px -4px 6px rgba(255, 255, 255, 0.2);
    --highlight-md: -10px -8px 8px rgba(255, 255, 255, 0.2);
    --highlight-far: -19px -22px 24px rgba(255, 255, 255, 0.6);

    --highlight-full: var(--highlight-tight), var(--highlight-close), var(--highlight-md), var(--highlight-far);

    --highlight-full-inset: inset var(--highlight-tight), inset var(--highlight-tight), inset var(--highlight-close), inset var(--highlight-md);

    --highlight-close-inset: inset var(--highlight-tight), inset var(--highlight-close);

    --highlight-tight-inset: inset var(--highlight-tight);

    --highlight-dark-mode: -2px -2px 2px var(--gray-10), -4px -4px 4px var(--gray-10), -8px -8px 8px var(--gray-10);

    --shadow-tight: 2px 2px 2px rgba(0, 0, 0, 0.1);
    --shadow-close: 4px 4px 4px rgba(0, 0, 0, 0.1);
    --shadow-md: 8px 8px 8px rgba(0, 0, 0, 0.2);
    --shadow-far: 0 var(--spc-xs-h) var(--spc-lg-w) var(--black-30);

    --shadow-full: var(--shadow-tight), var(--shadow-close), var(--shadow-far);

    --shadow-full-inset: inset var(--shadow-tight), inset var(--shadow-close), inset var(--shadow-md);

    --shadow-close-inset: inset var(--shadow-tight), inset var(--shadow-close);

    --shadow-tight-inset: inset var(--shadow-tight);

    --neumorph: var(--highlight-full), var(--shadow-full);

    --neumorph-inset: var(--highlight-full-inset), var(--shadow-full-inset);

    --neumorph-close: var(--highlight-tight), var(--highlight-close), var(--shadow-tight), var(--shadow-close);

    --neumorph-md: var(--highlight-tight), var(--highlight-close), var(--highlight-md), var(--highlight-far), var(--shadow-tight), var(--shadow-close),
        var(--shadow-far);

    --neumorph-md-dk: var(--highlight-dark-mode), var(--shadow-tight), var(--shadow-close), var(--shadow-md), var(--shadow-far);

    --neumorph-close-inset: var(--highlight-close-inset), var(--shadow-close-inset);

    --neumorph-tight: var(--highlight-tight), var(--shadow-tight);

    --neumorph-tight-inset: var(--highlight-tight-inset), var(--shadow-tight-inset);

    --glow: 0px 0px 36px rgba(255, 255, 255, 0.5), 20px 0px 36px rgba(255, 255, 255, 0.5), -20px 0px 36px rgba(255, 255, 255, 0.5),
        0px 0px 36px rgba(255, 255, 255, 0.5), 40px 0px 36px rgba(255, 255, 255, 0.5), -40px 0px 36px rgba(255, 255, 255, 0.5),
        0px 0px 36px rgba(255, 255, 255, 0.5), 60px 0px 36px rgba(255, 255, 255, 0.5), -60px 0px 36px rgba(255, 255, 255, 0.5);

    --fade: all 0.1s ease-in-out;
    --fade-slow: all 0.4s ease-in-out;

    --header-ht: clamp(60px, 15vh, 90px);

    --nav-ht: var(--body-ht);
    --nav-width: 200px;
    --mobile-nav-ht: 72px;
    --content-width: 1600px;
    --body-ht: calc(100vh - var(--header-ht) * 2);

    --grid-gap-xs: var(--base);
    --grid-gap-sm: var(--lg);
    --grid-gap: var(--xl);
    --grid-gap-lg: var(--xl3);

    --outline: 1px solid var(--text-col);
}
