#onetrust-consent-sdk * {
    outline: none !important;
}

#onetrust-consent-sdk #onetrust-banner-sdk {
    background-color: var(--site-bg-70) !important;
    -webkit-backdrop-filter: var(--blur) !important;
    backdrop-filter: var(--blur) !important;
}

#onetrust-consent-sdk #onetrust-policy-title,
#onetrust-consent-sdk #onetrust-policy-text,
#onetrust-consent-sdk .ot-b-addl-desc,
#onetrust-consent-sdk .ot-dpd-desc,
#onetrust-consent-sdk .ot-dpd-title,
#onetrust-consent-sdk #onetrust-policy-text *:not(.onetrust-vendors-list-handler),
#onetrust-consent-sdk .ot-dpd-desc *:not(.onetrust-vendors-list-handler),
#onetrust-consent-sdk #onetrust-banner-sdk #banner-options *,
#onetrust-banner-sdk .ot-cat-header,
#onetrust-banner-sdk .ot-optout-signal {
    color: var(--text-col) !important;
}
