:root {
	--sidebar-width: clamp(320px, 25vw, 400px);
}

.contact-sidebar {
	position: fixed;
	right: 0;
	display: flex;
	flex-direction: column;
	height: 100vh;
	width: var(--sidebar-width);
	padding: 0 var(--spc-md);
	z-index: 11;
	justify-content: center;
	transition: all 0.25s ease-in-out;
	-webkit-backdrop-filter: var(--blur);
	backdrop-filter: var(--blur);
}

.contact-sidebar ~ main {
	margin-right: var(--sidebar-width);
}

button.open-sidebar-btn {
	--size: clamp(50px, 12vmin, 80px);
	display: none;
	position: absolute;
	/* transform: rotate(0.75turn); */
	width: var(--size);
	height: var(--size);
	left: calc(var(--size) * -1);
	padding: calc(var(--size) / 4.5);
	text-transform: uppercase;
	font-family: var(--headline-font);
	background-color: var(--accent);
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	box-shadow: none;
}

button.open-sidebar-btn:hover {
	outline: none;
	border: none;
	/* box-shadow: var(--neumorph-md-dk); */
	/* background-color: transparent; */
}

button.open-sidebar-btn .icon#x {
	width: unset;
	height: unset;
}

.is-open button.open-sidebar-btn {
	box-shadow: none;
	background-color: var(--blue-dp);
}

.contact-sidebar h2 {
	margin-bottom: 0;
	font-family: var(--condensed-font);
}

.contact-sidebar h3.h6 {
	margin-top: 0;
	font-family: var(--condensed-font);
}

.contact-sidebar h3.h5 {
	margin-bottom: 0;
	font-family: var(--condensed-font);
	line-height: 1.2em;
}

.contact-sidebar > img {
	border-radius: var(--rnd-sm);
	max-height: 300px;
	-o-object-fit: cover;
	object-fit: cover;
}

.contact-sidebar ul {
	-webkit-padding-start: var(--md);
	padding-inline-start: var(--md);
}

.contact-sidebar li {
	font-size: 0.85em;
	font-family: var(--condensed-font);
}

.contact-sidebar > div {
	margin-top: var(--spc-sm-h);
	display: grid;
	row-gap: var(--spc-xs-h);
}

.contact-sidebar > div > .btn {
	--size: var(--xl4);
	width: var(--size);
	height: var(--size);
	padding: var(--md);
}

@media screen and (max-width: 1200px) {
	.contact-sidebar {
		height: calc(100% - var(--mobile-nav-ht));
	}
}

@media screen and (max-height: 900px) {
	.contact-sidebar ul {
		display: none;
		visibility: hidden;
		width: 0;
		height: 0;
	}
}

@media screen and (max-width: 900px) {
	:root {
		--sidebar-width: 0px;
		--open-sidebar-width: clamp(240px, 50vw, 480px);
	}

	.contact-sidebar {
		transform: translateX(var(--open-sidebar-width));
		width: var(--open-sidebar-width);
		/* padding-left: var(--sidebar-width); */
	}

	.contact-sidebar.is-open {
		transform: unset;
	}

	.contact-sidebar > div > button {
		--size: var(--xl4);
		padding: var(--base);
	}

	button.open-sidebar-btn {
		display: unset;
	}

	.contact-sidebar > img {
		max-height: 30vh;
	}
}
